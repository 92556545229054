import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject, Observable, of } from 'rxjs';
import { UserData, ModifiedUser, AddUser } from '../models/user-data.model';
import { AuthService } from './auth.service';
import { endpointUrls } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  private users: UserData[] = [];
  private usersUpdated = new Subject<any>();
  private userSearchUpdated = new Subject<any>();
  successmessage: boolean;
  addSuccess = new Subject<any>();
  editSuccess = new Subject<any>();
  deleteSuccess = new Subject<any>();
  getdownloadtemplate : string


  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) {}

  getUsersUpdatedListener(): Observable<any> {
    return this.usersUpdated.asObservable();
  }

  getSearch(): Observable<any> {
    return this.userSearchUpdated.asObservable();
  }
  getSuccess(): Observable<any> {
    return this.addSuccess.asObservable();
  }

  getEditSuccess(): Observable<any> {
    return this.editSuccess.asObservable();
  }

  getDeleteSuccess(): Observable<any> {
    return this.deleteSuccess.asObservable();
  }

  getUsers(): void {
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const getAllUsersURL = endpointUrls.getAllUsers;
    const getAllUsersEndpoint = backendURL + getAllUsersURL;

    this.http.get<UserData[]>(getAllUsersEndpoint).subscribe(
      (response) => {
        this.users = response;
        this.usersUpdated.next([...this.users]);
      },
      (error) => {
        console.log('ERROR when retrieving all users', error);
      }
    );
  }

  directorySearchUsingEmail(query): void {
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const params = '?emailId=' + query;
    const search = endpointUrls.directorySearchUsingEmail + params;
    const getAllsearchUsersEndpoint = backendURL + search;

    this.http.get<any[]>(getAllsearchUsersEndpoint).subscribe(
      (response) => {
        this.users = response;
        this.userSearchUpdated.next([...this.users]);
      },
      (error) => {
        console.log('ERROR when retrieving all users', error);
      }
    );
  }

  addUser(userData: AddUser): void {
    console.log('Creating user', userData);
    // TODO add backend call to create user
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const addUserURL = endpointUrls.addUser;
    const addUserEndpoint = backendURL + addUserURL;
    this.http.post(addUserEndpoint, userData).subscribe(
      (response) => {
          this.addSuccess.next(response);
          this.getUsers();
      },
      (error) => {
        console.log('ERROR when retrieving all users', error);
      }
    );
    this.router.navigate(['/manageUsers']);
  }

  editUser(userData: ModifiedUser): void {
    console.log('Updating user', userData);
    // TODO add backend call to update user
    // const params = '?useraccessID=' + userData.userAccessId + '&status=' + userData.status + '&roleID=' + userData.roleId;
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const editUserURL = endpointUrls.editUser;
    const editUserEndpoint = backendURL + editUserURL;
    this.http.post(editUserEndpoint, userData).subscribe(
      (response) => {
        this.editSuccess.next(response);
        this.getUsers();
      },
      (error) => {
        console.log('failure when retrieving all users', error);
      }
    );
    this.router.navigate(['/manageUsers']);
  }

  deleteUser(userIdvalue): void {
    const params = '?userId=' + userIdvalue;
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const deleteUserURL = endpointUrls.deleteUser + params;
    const deleteUserEndpoint = backendURL + deleteUserURL;
    this.http.post(deleteUserEndpoint, userIdvalue).subscribe(
      (response) => {
        this.deleteSuccess.next();

        this.getUsers();
      },
      (error) => {
        console.log('failure when retrieving all users', error);
      }
    );
  }

  validateMiMsIdCaseIdentifier(id): Observable<any> {
    const param = '?MiMsIdCaseIdentifier=' + id;
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const miMsIdURL = endpointUrls.validateMiMsIdCaseIdentifier + param;
    const miMsIdEndpoint = backendURL + miMsIdURL;
    return this.http.post(miMsIdEndpoint, null);
  }

  getCountryCode(): Observable<any> {
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const getCountryURL = endpointUrls.getCountries;
    const getCountryEndpoint = backendURL + getCountryURL;
    return this.http.get(getCountryEndpoint);
  }
  
 
 downloadBulkUploadTemplate(): Observable<any> {
  const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
  const backendURL = executionEnvData.backendURL;
  const getDownloadTemplateURL = endpointUrls.downloadBulkUploadTemplate;
  const getDownloadTemplateEndpoint = backendURL + getDownloadTemplateURL;
  return this.http.get(getDownloadTemplateEndpoint,{ responseType: 'blob' as 'json' }); 
  // this.http.get(getDownloadTemplateEndpoint, {responseType: 'blob' as 'json'}).subscribe(
  //   (response) => {
  //     console.log(response, 'download');
  //     const data = {
  //       file: response,
  //       fileName: 'template',
  //       extension: '.xlsx'
  //     };
  //     this.downloadTemplate(data);
  //   },
  //   (error) => {
  //     console.log('ERROR when downloading template', error);
  //   }
  // );
}

  downloadTemplate(data: any) {  
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(data.file);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", encodeURI(url));
    dwldLink.setAttribute("download", data.fileName + data.extension);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  addBulkUser(bulkUserTemplate): void {
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const addUserURL = endpointUrls.bulkAddUser;
    const addUserEndpoint = backendURL + addUserURL;
    this.http.post(addUserEndpoint, bulkUserTemplate).subscribe(
      (response) => {
          this.addSuccess.next(response);
          this.getUsers();
      },
      (error) => {
        console.log('ERROR when retrieving all users', error);
      }
    );    
  }

  downloadErrorFile(): void {
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const getErroFileURL = endpointUrls.downloadErrorFile;
    const getErrorFileEndpoint = backendURL + getErroFileURL;

    this.http.get(getErrorFileEndpoint, {responseType: 'blob' as 'json'}).subscribe(
      (response) => {
        console.log(response, 'download');
        const data = {
          file: response,
          fileName: 'Error File',
          extension: '.xlsx'
        };
        this.downloadTemplate(data);
      },
      (error) => {
        console.log('ERROR when downloading Error File', error);
      }
    );
  }

  downloadGUMFile(): Observable<any> {
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const getGUMFileURL = endpointUrls.downloadGUMFile;
    const getGUMFileEndpoint = backendURL + getGUMFileURL;
    
    return this.http.get(getGUMFileEndpoint, {observe: 'response', responseType: 'blob'});    

    // this.http.get<UserData[]>(getGUMFileEndpoint, {responseType: 'blob' as 'json'}).subscribe(
    //   (response) => {
    //     console.log(response, 'download');
    //     const data = {
    //       file: response,
    //       fileName: 'GUM Template',
    //       extension: '.csv'
    //     };
    //     this.downloadTemplate(data);
    //   },
    //   (error) => {
    //     console.log('ERROR when downloading GUM Template File', error);
    //   }
    // );
  }

  getBulkUploadStatus(): Observable<any> {
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const getBulkUploadStatusURL = endpointUrls.getBulkUploadStatus;
    const getBulkUploadStatusEndpoint = backendURL + getBulkUploadStatusURL;
    return this.http.get(getBulkUploadStatusEndpoint);
  }
  
}
