import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { UserManagementComponent } from './main/user-management/user-management.component';
import { AuthGuard } from './services/auth.guard';
import { CreateUserComponent } from './main/user-management/create-user/create-user.component';
import { FormsContainerComponent } from './forms/forms-container/forms-container.component';
import { LoginComponent } from './main/auth/login/login.component';
import { UnauthorizedComponent } from './shared/unauthorized/unauthorized.component';
import { ErrorComponent } from './shared/error/error.component';
import { OrganizationManagementComponent } from './main/organization-management/organization-management.component';
import { AttorneyManagementComponent } from './main/attorney-management/attorney-management.component';
import { CheckStatusComponent } from './main/check-status/check-status.component';


const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'unauthorized', component: UnauthorizedComponent},
  {path: 'error', component: ErrorComponent},
  {path: '', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'manageUsers', component: UserManagementComponent, canActivate: [AuthGuard]},
  {path: 'createUser', component: CreateUserComponent, canActivate: [AuthGuard]},
  {path: 'editUser/:userId', component: CreateUserComponent, canActivate: [AuthGuard]},
  {path: 'forms', component: FormsContainerComponent},
  {path: 'manageOrganization', component: OrganizationManagementComponent, canActivate: [AuthGuard]},
  {path: 'manageAttorney', component: AttorneyManagementComponent, canActivate: [AuthGuard]},
  {path: 'checkStatus', component: CheckStatusComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
