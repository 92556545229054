import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import {
  FormFieldSpec,
  FormFieldType,
  FormInstance,
  FormService,
  FormSubSection,
} from 'src/app/services/form-service.service';

@Component({
  selector: 'form-sub-section',
  templateUrl: './form-sub-section.component.html',
  styleUrls: ['./form-sub-section.component.scss'],
})
export class FormSubSectionComponent implements OnInit {
  @Input() subSection: FormSubSection;
  @Input() formGroup: FormGroup;
  @Input() sectionIndex: number;
  @Input() parentInstance: FormInstance;

  maxDate = new Date();

  batchedControls: FormControl[];
  isDependentInstance: boolean;

  constructor(private formService: FormService) {}

  getControl(field: FormFieldSpec): AbstractControl {
    const ctrl = this.formGroup.controls[
      this.formService.getControlName(field)
    ];

    return ctrl;
  }

  showFieldErrors(field: FormFieldSpec): boolean {
    const type = field.type.toLowerCase();
    return type !== FormFieldType.label;
  }

  ngOnInit(): void {
    this.batchedControls = [];

    const tempBatches = {};

    for (const fieldSpec of this.subSection.formFieldSpecification) {
      const groupCode = 'group' + (fieldSpec.batchId || 0);
      if (!tempBatches[groupCode]) {
        tempBatches[groupCode] = [fieldSpec];
      } else {
        tempBatches[groupCode].push(fieldSpec);
      }
    }

    for (const k of Object.keys(tempBatches)) {
      const batch: FormFieldSpec[] = tempBatches[k];
      batch.sort((a, b) => a.sequenceId - b.sequenceId);
      this.batchedControls.push(tempBatches[k]);
    }

    this.isDependentInstance = this.parentInstance.isDependent;
  }

  // Note that a lot of this code block duplicates functionality in multi-step-form and both could possibly be centralized in form-service eventually.
  // The other updates toggles and date bounadries on initial load, this one does it on control change.
  //TODO: Would be nice to consolidate this and similar functionality in multi-step-form into a central place in form-service
  controlChanged(field: FormFieldSpec, value: string): void {
    //Fields can be toggled by other fields within different sub-sections if they are in the same section, but NOT cross-section
    let currentSection = this.parentInstance.formSections[
      this.parentInstance.currentSectionIndex
    ];

    for (let subSection of currentSection.formSubSection) {
      let dependentToggleFields = subSection.formFieldSpecification.filter(
        (x) => x.toggleQuestionId === field.questionId
      );

      for (const toggleField of dependentToggleFields) {
        toggleField.isToggledOn = this.formService.isMatchToggleValue(
          toggleField.toggleQuestionValue,
          value
        );
        const toggleControl = this.getControl(toggleField);
        if (!toggleControl) {
          continue;
        }
        if (!toggleField.isToggledOn && toggleControl.enabled) {
          toggleControl.disable();
        } else if (toggleField.isToggledOn && toggleControl.disabled) {
          toggleControl.enable();
        }
      }
    }

    const fieldType = field.type.toLowerCase();

    //Whether date min/max values can be set by controls from other sub-sections has not been tested (other sections will not work though)
    if (fieldType === FormFieldType.date) {
      for (const minDateField of this.subSection.formFieldSpecification.filter(
        (x) => x.minDate === field.questionId.toString()
      )) {
        minDateField.minDate_value = new Date(value);
        const minDateControl = this.getControl(minDateField);
        minDateControl.setValidators(
          this.formService.getValidators(minDateField)
        );
        minDateControl.updateValueAndValidity();
      }
      for (const maxDateField of this.subSection.formFieldSpecification.filter(
        (x) => x.maxDate === field.questionId.toString()
      )) {
        maxDateField.maxDate_value = new Date(value);
        const maxDateControl = this.getControl(maxDateField);
        maxDateControl.setValidators(
          this.formService.getValidators(maxDateField)
        );
        maxDateControl.updateValueAndValidity();
      }
    }

    // Right now only text boxes and dates can have non-required errors. (checkboxSetRequired counts as required for our purposes)
    if (fieldType === FormFieldType.text || fieldType === FormFieldType.date) {
      const control = this.getControl(field);
      const hasNonRequiredError =
        control.errors?.pattern ||
        control.errors?.minimumDate ||
        control.errors?.maximumDate;

      const errorIndex = this.parentInstance.blockingErrorQuestions.indexOf(
        field.questionId
      );
      if (hasNonRequiredError && errorIndex === -1) {
        this.parentInstance.blockingErrorQuestions.push(field.questionId);
      } else if (!hasNonRequiredError && errorIndex !== -1) {
        this.parentInstance.blockingErrorQuestions.splice(errorIndex, 1);
      }
    }
  }

  copyAddress() {
    this.formService.copyAddress();
  }
}
