<div class="a-header main">
  <div
    class="a-hfn-header-container d-flex align-items-center justify-content-between navigation-fourth-bar"
  >
    <!-- left -->
    <div class="d-flex align-items-center">
      <!-- <div class="a-pwc-logo-grid a-md"></div> -->
      <img src="../../../assets/images/logo.png" alt="Logo" class="logo-img" />
      <span class="divider a-mx-20"></span>
      <div class="font-weight-medium a-nowrap default-font-size">{{ name }}</div>
      <span class="divider a-mx-15"></span>
      <ap-tabs-group (onTabChange)="tabChange($event)" class="headertop">
        <ap-tab label="Dashboard"></ap-tab>
        <ap-tab *ngIf="isUserAdmin" label="User Management"></ap-tab>
        <ap-tab *ngIf="isUserAdmin" label="Organization Management"></ap-tab>
        <ap-tab *ngIf="isUserAdmin" label="Attorney Management"></ap-tab>
        <ap-tab *ngIf="isSuperAdmin" label="Check Status"></ap-tab>
      </ap-tabs-group>
    </div>

    <!-- right logo -->
    <div class="d-flex align-items-center a-pl-6">
      <i class="fa fa-user" aria-hidden="true"></i>
      <span class="a-mr-8 default-font-size">{{ loggedInUserName }}</span>
      <div class="a-logout show a-mr-8">
        <i
          class="fa fa-power-off"
          (click)="showMoreCustomheader('show-custom-modallogout')"
        ></i>

        <!-- <div class="dropdown-menu dropdown-menu-right a-shadow-sm border-0 a-user-setting-panel-one"
                  [ngClass]="{'show':logoutMenu}">
                  <div class="dropdown-title textcenter">
                    {{loggedInUserName}}
                  </div>
                  <div class="user-logout">
                    <button class="a-btn a-btn-secondary a-btn-lg" >
                      Sign Out
                    </button>
                  </div>
              </div>
              <div class="mask" (click)="logoutMenu = false" *ngIf="logoutMenu"></div> -->
      </div>
    </div>
  </div>
</div>
<div class="overlay" *ngIf="showoverlay"></div>
<ap-modal
  #baseModalCustom
  appModalId="show-custom-modallogout"
  style="z-index: 999999"
>
  <div
    class="modal-dialog a-modal-lg modal-dialog-centered modal-sm"
    role="document"
  >
    <div class="modal-content">
      <div class="a-modal-header">
        <div class="flex-fill d-flex align-items-center">
          <div class="a-h4">Confirmation</div>
        </div>

        <button
          aria-label="Close"
          class="icon-wrapper"
          data-dismiss="modal"
          (click)="closeModalCustomheader('show-custom-modallogout')"
        >
          <span class="appkiticon icon-close-fill a-close-btn"></span>
        </button>
      </div>
      <div class="a-modal-body pt-0">
        <div class="a-p4 a-mt-10 text-description">
          <span class="appkiticon icon-alert-outline"></span> &nbsp; Are you
          sure you want to Logout ?
        </div>
      </div>
      <div class="a-modal-footer a-border-tp">
        <div class="flex-fill text-left a-font-12 a-text-grey-medium"></div>
        <button
          aria-label="Close"
          class="a-btn a-btn-transparent a-btn-lg a-btn-gray a-mr-10"
          data-dismiss="modal"
          (click)="closeModalCustomheader('show-custom-modallogout')"
        >
          NO
        </button>
        <button
          aria-label="Close"
          class="a-btn a-btn-transparent a-btn-lg"
          data-dismiss="modal"
          (click)="signOut()"
        >
          YES
        </button>
      </div>
    </div>
  </div>
</ap-modal>
