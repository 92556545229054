import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Table } from 'primeng/table';
import { OrganizationManagementService } from '../../services/organization-management.service';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-organization-management',
  templateUrl: './organization-management.component.html',
  styleUrls: ['./organization-management.component.scss'],
})
export class OrganizationManagementComponent implements OnInit {
  showoverlay: boolean;
  xVisible: boolean;
  isLoading: boolean;
  tableData: any;
  onEdit: boolean;
  organization: string;
  organizationErrorMsg: string;
  organizationErr: boolean;
  private ngSubject: Subject<any> = new Subject<any>();
  organizationObj: any;
  org: string;
  deleteId: string;
  status: boolean;

  @ViewChild('baseModalLarge', { static: false }) baseModalLarge: any;
  @ViewChild('baseModalCustom', { static: false }) baseModalCustom: any;
  @ViewChild('globalSearchs') userInput: ElementRef;
  @ViewChild('dt1') pTableComponent: Table;

  constructor(
    private organizationManagementService: OrganizationManagementService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.getOrganization();
  }

  getOrganization(): void {
    this.isLoading = true;
    this.organizationManagementService
      .getOrganization(true)
      .pipe(takeUntil(this.ngSubject))
      .subscribe((org) => {
        console.log('RECEIVED ORG LIST', org);
        this.isLoading = false;
        this.tableData = org;
      });
  }

  ngOnDestroy(): void {
    this.ngSubject.next();
    this.ngSubject.complete();
  }

  showMoreLarge(id): void {
    this.showoverlay = true;
    this.baseModalLarge.showMore(id);
  }

  closeModalLarge(id): void {
    this.org = '';
    this.onEdit = false;
    this.organizationErr = false;
    this.organizationErrorMsg = '';
    this.organization = '';
    this.showoverlay = false;
    this.status = true;
    this.baseModalLarge.closeModal(id);
  }

  onChangeEvent(event): void {
    this.organization = event.target.value;
    this.validOrganization();
  }

  validOrganization(): void {
    if (
      (this.organization === '' ||
        this.organization === undefined ||
        this.organization === null) &&
      !this.onEdit
    ) {
      this.organizationErr = true;
      this.organizationErrorMsg = 'Please enter Organization';
    } else {
      this.organizationErr = false;
      this.organizationErrorMsg = '';
    }
  }

  onKeyup(event): void {
    if (this.userInput.nativeElement.value !== '') {
      this.xVisible = true;
    } else {
      this.xVisible = false;
    }
  }

  clearGlobalFilter(a): void {
    this.pTableComponent.reset();
    this.userInput.nativeElement.value = '';
    this.xVisible = false;
  }

  editOrganization(id, data): void {
    this.onEdit = true;
    this.organizationObj = data;
    this.organization = data.organizationName;
    if (data.status === 1) {
      this.status = true;
    } else {
      this.status = false;
    }
    this.showMoreLarge(id);
  }

  showMoreCustom(id, data): void {
    this.org = data.organizationName;
    this.deleteId = data.organizationID;
    this.showoverlay = true;
    this.baseModalCustom.showMore(id);
  }

  closeModalCustom(id): void {
    this.org = '';
    this.organization = '';
    this.showoverlay = false;
    this.baseModalCustom.closeModal(id);
  }

  addOrganization(id): void {
    if (
      (this.organization === '' ||
        this.organization === undefined ||
        this.organization === null) &&
      !this.onEdit
    ) {
      this.organizationErr = true;
      this.organizationErrorMsg = 'Please enter Organization';
    } else {
      if (!this.onEdit) {
        this.isLoading = true;
        this.organizationManagementService
          .addOrganization(this.organization)
          .pipe(takeUntil(this.ngSubject))
          .subscribe((response) => {
            console.log('RECEIVED', response);
            this.isLoading = false;
            if (response.status) {
              const msg = {
                severity: 'success',
                summary: response.message,
              };
              this.toastService.getToastMsg(msg);
              this.getOrganization();
            } else {
              const msg = {
                severity: 'error',
                summary: response.message,
              };
              this.toastService.getToastMsg(msg);
            }
          });
        this.closeModalLarge(id);
      } else {
        this.onEditOrganization(id);
      }
    }
  }

  onEditOrganization(id): void {
    if (
      this.organization === '' ||
      this.organization === undefined ||
      this.organization === null
    ) {
      this.organizationErr = true;
      this.organizationErrorMsg = 'Please enter Organization';
    } else {
      const obj = {
        organizationID: this.organizationObj.organizationID,
        organizationName: this.organization,
        status: this.status ? 1 : 0,
      };
      this.isLoading = true;
      this.organizationManagementService
        .editOrganization(obj)
        .pipe(takeUntil(this.ngSubject))
        .subscribe((response) => {
          console.log('RECEIVED EDITED', response);
          this.isLoading = false;
          if (response.status) {
            const msg = {
              severity: 'success',
              summary: 'Organization edited successfully',
            };
            this.toastService.getToastMsg(msg);
            this.getOrganization();
          }
        });
      this.closeModalLarge(id);
    }
  }

  deleteOrganization(id): void {
    this.isLoading = true;
    this.organizationManagementService
      .deleteOrganization(this.deleteId)
      .pipe(takeUntil(this.ngSubject))
      .subscribe((response) => {
        console.log('RECEIVED', response);
        this.isLoading = false;
        if (response.status) {
          const msg = {
            severity: 'success',
            summary: 'Organization deleted successfully',
          };
          this.toastService.getToastMsg(msg);
          this.getOrganization();
        } else {
          const msg = {
            severity: 'error',
            summary:
              'Unable to delete the Organization, as mapping of attorney or user is present',
          };
          this.toastService.getToastMsg(msg);
        }
      });
    this.closeModalCustom(id);
  }
}
