import { NgModule } from '@angular/core';
import {TableModule} from 'primeng/table';
import {InputTextModule} from 'primeng/inputtext';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import {ToastModule} from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import {TabViewModule} from 'primeng/tabview';
import {AccordionModule} from 'primeng/accordion';
import {DropdownModule} from 'primeng/dropdown';
import {PanelModule} from 'primeng/panel';

@NgModule({
  exports: [
    TableModule,
    InputTextModule,
    MessagesModule,
    MessageModule,
    AutoCompleteModule,
    TooltipModule,
    CalendarModule,
    ToastModule,
    TooltipModule,
    TabViewModule,
    AccordionModule,
    DropdownModule,
    PanelModule
  ]
})
export class PrimeNgModule { }
