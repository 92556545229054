<!-- DEPRECATED: This is no longer used with the reworked field error display and can probably be removed-->

<div class="a-text-danger" *ngIf="showErrorsEnabled && hasVisibleErrors"> 
    <!--Turn on for debugging-->
    <span *ngIf="false">{{ form_Control.errors | json}}</span> 
    
    <!--<span>{{ fieldSpec.questionId }}:</span>

    <span *ngIf="form_Control.errors.required && !form_Control.pristine">
       Required.
    </span>
    <span *ngIf="form_Control.errors.pattern">
        &nbsp;{{ fieldSpec.validationMessage }}
    </span>-->
</div>