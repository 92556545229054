<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="titletop">
    <h5 class="title">Check Status</h5>
    <div class="row">
        <form>
            <div class="container">
              <label>Enter Email:&nbsp;&nbsp;</label>
              <input type="text"  [(ngModel)]="emailId"
              placeholder="Enter Email" name="emailId" required>

            <label>Select:&nbsp;&nbsp;</label>
            <select name="options" (change)="onChange($event)">
              <option disabled selected value>Select</option>
              <option value="indraft">Move To Draft</option>
              <option value="regenerateform">Regenerate Forms</option>
            </select>

            <button class="a-btn a-btn-primary a-btn-lg" (click)="submit()" type="submit">
              Submit
            </button>

            </div>
          </form>
    </div>
</div>        
