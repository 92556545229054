import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {
  name = 'My Adjustment of Status';

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {}

  onLogout(): void {
    this.authService.logout('manual');
  }
}
