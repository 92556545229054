<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>

<div class="info-text a-font-14 a-mb-10 a-p-10 a-shadow-sm">
  If you choose to replace one or more of the generated forms, please make sure
  you:
  <ol class="aos-instructions">
    <li>Upload the updated forms in an editable format.</li>
    <li>
      Do <b><u>not</u></b> change the name of the file. The naming convention
      should be retained as-is.
    </li>
    <li>
      The original generated form will be overridden if you choose to upload
      updated forms on this screen.
    </li>
    <li>
      <a href="#" (click)="showInstructionsModal($event)">Please click here</a>
      for printing and mailing instructions.
    </li>
  </ol>
</div>
<div *ngFor="let item of instance; index as i">
<p-accordion>
  <p-accordionTab
    [header]="name[item].name"
    [selected]="item === 'Primary'"
  >
    <div class="a-table-wrapper">
      <div class="a-panel code-wrapper table dark tboard-table">
        <div class="tboard-table">
          <p-table [value]="generatedDocumentsList[item]">
            <ng-template pTemplate="header">
              <tr>
                <th [style]="{ width: '25%' }">File</th>
                <th [style]="{ width: '20%' }"></th>
                <th [style]="{ width: '5%' }"></th>
                <th [style]="{ width: '5%' }"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-values let-index="rowIndex">
              <tr>
                <td [style]="{ width: '25%' }">{{ values.formFileName }}</td>
                <td [style]="{ width: '20%' }">
                  <input
                    type="file"
                    #filePicker
                    (change)="
                      showMoreCustomgenerateTabs(
                        'show-custom-modal-generate-tab',
                        $event,
                        values,
                        index
                      )
                    "
                  />
                </td>
                <td [style]="{ width: '5%' }" class="text-center">
                  <i
                    class="pi pi-download"
                    aria-hidden="true"
                    (click)="downloadPdf(values)"
                  ></i>
                </td>
                <td [style]="{ width: '5%' }" class="text-center">
                  <i
                    class="pi pi-upload"
                    aria-hidden="true"
                    *ngIf="finalSubmit"
                    (click)="filePicker.click()"
                  ></i>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </p-accordionTab>
</p-accordion>
</div>
<div class="p-2 button-align">
  <button
    type="button"
    *ngIf="finalSubmit"
    class="a-btn a-btn-primary a-btn-lg a-mt-20"
    (click)="showMoreCustom('show-custom-modal-generate-tab', true)"
  >
    Submit
  </button>
</div>

<div class="overlay" *ngIf="showoverlay"></div>
<ap-modal
  #baseModalCustom
  appModalId="show-custom-modal-generate-tab"
  style="z-index: 999999"
>
  <div
    class="modal-dialog a-modal-lg modal-dialog-centered modal-sm"
    role="document"
  >
    <div class="modal-content">
      <div class="a-modal-header">
        <div class="flex-fill d-flex align-items-center">
          <div *ngIf="!isSubmit" class="a-h4">Confirmation</div>
          <div *ngIf="isSubmit" class="a-h4">Acknowledge</div>
        </div>
        <button
          aria-label="Close"
          class="icon-wrapper"
          data-dismiss="modal"
          (click)="
            closeModalCustomgenerateTabs('show-custom-modal-generate-tab')
          "
        >
          <span class="appkiticon icon-close-fill a-close-btn"></span>
        </button>
      </div>
      <div class="a-modal-body pt-0">
        <div *ngIf="!isSubmit" class="a-p4 a-mt-10 text-description">
          <span class="appkiticon icon-alert-outline"></span> &nbsp; The
          existing file will be replaced if you choose to upload a new one, do
          you wish to continue?
        </div>
        <div *ngIf="isSubmit" class="a-p4 a-mt-10 text-description">
          I confirm that I have reviewed the generated forms for data accuracy.
          If required, I have made modifications on the generated forms and I
          have uploaded the final version of the forms.
        </div>
      </div>
      <div class="a-modal-footer a-border-tp">
        <div class="flex-fill text-left a-font-12 a-text-grey-medium"></div>
        <button
          aria-label="Close"
          class="a-btn a-btn-transparent a-btn-lg a-btn-gray a-mr-10"
          data-dismiss="modal"
          (click)="
            closeModalCustomgenerateTabs('show-custom-modal-generate-tab')
          "
        >
          NO
        </button>
        <button
          *ngIf="!isSubmit"
          aria-label="Close"
          class="a-btn a-btn-transparent a-btn-lg"
          data-dismiss="modal"
          (click)="onFileSelected('show-custom-modal-generate-tab')"
        >
          YES
        </button>
        <button
          *ngIf="isSubmit"
          aria-label="Close"
          class="a-btn a-btn-transparent a-btn-lg"
          data-dismiss="modal"
          (click)="submitToPwC('show-custom-modal-generate-tab')"
        >
          YES
        </button>
      </div>
    </div>
  </div>
</ap-modal>

<ap-modal
  #modalInstructions
  appModalId="show-custom-modalInstructions"
  style="z-index: 999999"
>
  <div
    class="modal-dialog a-modal-lg modal-dialog-centered modal-lg"
    role="document"
  >
    <div class="modal-content">
      <div class="a-modal-header">
        <div class="flex-fill d-flex align-items-center">
          <div class="a-h4">Printing & Mailing Instructions</div>
        </div>
        <button
          aria-label="Close"
          class="icon-wrapper"
          data-dismiss="modal"
          (click)="closeInstructionsModal($event)"
        >
          <span class="appkiticon icon-close-fill a-close-btn"></span>
        </button>
      </div>
      <div class="a-modal-body pt-0">
        <div class="a-p4 a-font-14 a-mt-10 text-description">
          <ol class="a-pl-20">
            <li>
              Download and print out each form <b><u>(single sided)</u></b
              >, and sign and date using <b><u>blue ink</u></b
              >. Blue ink makes it clear that the original, and not a copy is
              being used for filing.
            </li>
            <li>
              If any accompanying dependents are under 14 years of age, a parent
              should sign the forms on their behalf; this means that the parent
              can either sign their own name (i.e the parent’s name) or sign as
              “[parent name] on behalf of [child’s name]”
            </li>
            <li>
              Form G-28 <b>(print 3 sets)</b>
              <ul class="a-pl-20">
                <li>Please sign and date Page 3, Part 4, Item 2a and 2b</li>
              </ul>
            </li>
            <li>
              For I-485
              <ul class="a-pl-20">
                <li>
                  Sign and date Page 16, Part 10, Item 6a and 6b
                  <b
                    >(Please do not forget to review the admissibility questions
                    on Part 8 of Form I-485)
                  </b>
                </li>
              </ul>
            </li>
            <li>
              Form I-485 Supplement J
              <ul class="a-pl-20">
                <li>
                  Sign and date Page 2, Part 3, Item 6a and 6b

                  <b
                    >(Please ensure that you insert your original hire date with
                    the US employer on the I-485 Supplement J (Page 4, Part 6,
                    Item 10). </b
                  >Please note that derivatives (dependents) will not have Form
                  I-485 Supp J.
                </li>
              </ul>
            </li>
            <li>
              Form I-131
              <ul class="a-pl-20">
                <li>Sign and date Page 5, Part 8, Item 1a and 1b.</li>
              </ul>
            </li>
            <li>
              Form I-765
              <ul class="a-pl-20">
                <li>Sign and date Page 5, Part 3, Item 7a and 7b.</li>
              </ul>
            </li>
          </ol>
          <p>
            All signed forms for each applicant should be couriered
            <b><u>via overnight service (if possible please use FedEx Express)</u></b>
            to the address indicated in your initiation email.
          </p>
        </div>
      </div>
    </div>
  </div>
</ap-modal>
