import { ViewChild, OnDestroy } from '@angular/core';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { GeneratedFormsDetailsService } from '../../services/generated-forms-details.service';
import { ToastService } from '../../services/toast.service';
import { saveAs } from 'file-saver';
import { FileUploadService } from '../../services/file-upload.service';
import { Dashboardservice } from '../../services/dashboard.service';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormService } from '../../services/form-service.service';

@Component({
  selector: 'app-generate-tabs',
  templateUrl: './generate-tabs.component.html',
  styleUrls: ['./generate-tabs.component.scss'],
})
export class GenerateTabsComponent implements OnInit, OnDestroy {
  genertedFormdata: any[];
  generatedDocumentsList: {
    [key: string]: {
      dependentType: string;
      formFileName: string;
      formFileUri: string;
    }[];
  };
  showoverlay: boolean;
  instance = [];

  @ViewChild('baseModalCustom', { static: false }) baseModalCustom: any;
  @ViewChild('modalInstructions', { static: false }) modalInstructions: any;
  isLoading: boolean;
  getGenerateFormsSubscription: Subscription;
  downloadFormsSubscription: Subscription;
  finalSubmit = false;
  userApplicationStatus: string;
  isSubmit = false;
  private ngSubject: Subject<any> = new Subject<any>();
  nameSubscription: Subscription;
  name = {};

  constructor(
    private formService: FormService,
    private generatedFormsService: GeneratedFormsDetailsService,
    private toastService: ToastService,
    private fileUploadService: FileUploadService,
    private dashboardservice: Dashboardservice,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.getGenerateFormsSubscription = this.generatedFormsService
      .generatedForms()
      .pipe(takeUntil(this.ngSubject))
      .subscribe((forms) => {
        this.isLoading = false;
        this.genertedFormdata = forms;
        this.instance = ['Primary'];
        this.genertedFormdata.forEach((item) => {
          if (!this.instance.includes(item.dependentType)) {
            this.instance.push(item.dependentType);
          }
        });
        console.log('generateddata', this.genertedFormdata);
        this.generatedDocumentsList = this.generatedFormsService.getGeneratedDocumentsList();
      });
    this.downloadFormsSubscription = this.generatedFormsService
      .downloadDependentForms()
      .pipe(takeUntil(this.ngSubject))
      .subscribe((pdfData) => {
        saveAs(pdfData.file, pdfData.fileName);
      });
    this.generatedFormsService.getgenerateForms();
    this.dashboardservice
      .getUserApplicationStatus()
      .pipe(takeUntil(this.ngSubject))
      .subscribe((status) => {
        this.userApplicationStatus = status.currentUserApplicationStatus;
        console.log('this.userApplicationStatus ', this.userApplicationStatus);
      });
    this.fileUploadService
      .getFormValid()
      .pipe(takeUntil(this.ngSubject))
      .subscribe((isValid) => {
        console.log('Attachment status', isValid);
        if (this.userApplicationStatus && this.userApplicationStatus === 'Forms Generated' && isValid) {
          this.finalSubmit = true;
        } else {
          this.finalSubmit = false;
        }
      });
      this.name = {};
    this.name = this.formService.getName();
    this.nameSubscription = this.formService
      .getNameListener()
      .pipe(takeUntil(this.ngSubject))
      .subscribe((name) => {
        this.name = name;
      });
  }

  ngOnDestroy(): void {
    this.downloadFormsSubscription.unsubscribe();
    this.ngSubject.next();
    this.ngSubject.complete();
  }

  index: any;
  values: any;
  event: any;
  showMoreCustomgenerateTabs(
    id: string,
    event: Event,
    values: any,
    index: any
  ): void {
    if ((event.target as HTMLInputElement).files.length > 0) {
      this.values = values;
      this.index = index;
      this.event = event;
      this.showoverlay = true;
      this.baseModalCustom.showMore(id);
    }
  }

  showMoreCustom(id, modal): void {
    this.isSubmit = modal;
    this.showoverlay = true;
    this.baseModalCustom.showMore(id);
  }

  closeModalCustomgenerateTabs(id: string): void {
    this.isSubmit = false;
    this.showoverlay = false;
    this.baseModalCustom.closeModal(id);
  }

  onFileSelected(id): void {
    const file = (this.event.target as HTMLInputElement).files[0];
    let fileValidation = true;
    let extension: any = file.name.split('.');
    extension = extension[extension.length - 1];
    if (extension.toLowerCase() !== 'pdf' && extension.toLowerCase()!=='docx' && extension.toLowerCase()!=='doc') {
      fileValidation = false;
    }
    if (file.size > 100000000) {
      fileValidation = false;
    }

    if (fileValidation) {
      const reader = new FileReader();
      this.isLoading = true;
      reader.onloadend = (e) => {
        const arr = new Uint8Array(e.target.result as ArrayBuffer).subarray(
          0,
          4
        );
        let header = '';
        let isValid = false;
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        if (header === '25504446' || header.toLowerCase()==='d0cf11e0'||header.toLowerCase()==='504b34') {
          isValid = true;
        }
        if (isValid) {
          // this.genertedFormdata[this.index].formFileName = file.name;
          this.fileUpload(file, this.values.dependentType);
        } else {
          const msg = {
            severity: 'error',
            summary: 'File type or size not valid',
          };
          this.toastService.getToastMsg(msg);
        }
        this.isLoading = false;
      };
      reader.readAsArrayBuffer(file);
      // reader.readAsDataURL(file);
    } else {
      const msg = {
        severity: 'error',
        summary: 'File type or size not valid',
      };
      this.toastService.getToastMsg(msg);
    }
    this.closeModalCustomgenerateTabs(id);
  }

  fileUpload(file, values): void {
    console.log('Form is valid, calling service to upload', this.values);
    this.isLoading = true;
    this.generatedFormsService
      .uploadGenerateForms(file, values, this.values.formFileName)
      .then(
        () => {
          console.log('Upload completed...');
          this.isLoading = false;
          const msg = {
            severity: 'success',
            summary: 'File uploaded successfully',
          };
          this.toastService.getToastMsg(msg);
          this.generatedFormsService.getgenerateForms();
        },
        () => {
          console.log('Error on file upload...');
          this.isLoading = false;
          const msg = {
            severity: 'error',
            summary: 'File upload failed',
          };
          this.toastService.getToastMsg(msg);
        }
      );
  }

  downloadPdf(values): void {
    const dependentValues = {
      dependentType: values.dependentType,
      formName: values.formFileName,
    };
    console.log(dependentValues);
    this.generatedFormsService.downloadForms(dependentValues);
  }

  showInstructionsModal($event) {
    // this.showoverlay = true;
    this.modalInstructions.showMore('show-custom-modalInstructions');

    return false;
  }

  closeInstructionsModal($event) {
    // this.showoverlay = false;
    this.modalInstructions.closeModal('show-custom-modalInstructions');

    return false;
  }

  submitToPwC(id): void {
    // console.log('Submit to PwC', this.isSubmit);
    this.isLoading = true;
    this.generatedFormsService.submitToPwC().subscribe(
      (response) => {
        this.isLoading = false;
        const msg = {
          severity: 'success',
          summary: 'Thank you for submitting your forms.',
        };
        this.toastService.getToastMsg(msg);
        this.router.navigate(['/']);
      },
      (error) => {
        this.isLoading = false;
      }
    );
    this.closeModalCustomgenerateTabs(id);
  }
}
