import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  OnChanges,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  FormFieldSpec,
  FormFieldType,
  FormService,
  FormSubmissionInstance,
} from 'src/app/services/form-service.service';

@Component({
  selector: 'form-field-control',
  templateUrl: './form-field-control.component.html',
  styleUrls: ['./form-field-control.component.scss'],
})
export class FormFieldControlComponent implements OnInit, OnChanges {
  @Input() controlRow: number;
  @Input() formGroup: FormGroup;
  @Input() field: FormFieldSpec;
  @Input() isHidden: boolean;
  @Input() isSubmitted: boolean;

  // minDate and maxDate come from the FormFieldSpec, but are set as properties like this because it's easier to detect changes
  @Input() minDate: Date;
  @Input() maxDate: Date;

  @Output() changeValue = new EventEmitter<string>();

  fieldName: string;
  formControl: AbstractControl;
  selectorItems = [];
  formFieldTypes;
  dateRange: string;

  errorTooltip = null;
  private ngSubject: Subject<any> = new Subject<any>();
  private fSubscription: Subscription;

  constructor(private formService: FormService) {
    this.formFieldTypes = FormFieldType;
  }

  ngOnInit(): void {
    this.fieldName = this.formService.getControlName(this.field);
    this.formControl = this.formGroup.controls[this.fieldName];

    if (this.field.type.toLowerCase() === FormFieldType.dropdown) {
      for (const option of this.field.questionOptions) {
        let item = {
          text: option.optionValue,
          value: option.subOptionId,
        };
        if (option.subOptionId.toString() == this.formControl.value?.toString())
          item['selected'] = true;
        this.selectorItems.push(item);
      }
    }
    if (this.field.type.toLowerCase() === FormFieldType.date)
      this.dateRange = '1900:' + (new Date().getFullYear() + 100);

    this.updateErrorTooltip = this.updateErrorTooltip.bind(this);

    this.updateErrorTooltip();
    this.fSubscription = this.formService
      .getFieldListener()
      .pipe(takeUntil(this.ngSubject))
      .subscribe((value) => {
        this.setValues(value);
      });
  }

  get borderClass(): string {
    if (!this.formControl.errors) return '';
    if (
      (this.formControl.errors.required ||
        this.formControl.errors.checkboxSetRequired) &&
      this.formControl.untouched
    )
      return 'aos-border-required-untouched';
    return 'aos-border-error';
  }

  //When the maxDate or minDate input changes, update the input
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maxDate || changes.minDate) {
      this.updateErrorTooltip();
    }
  }

  updateErrorTooltip(): void {
    let err = '';

    if (this.formControl) {
      const fieldType = this.field.type.toLowerCase();

      if (fieldType === FormFieldType.label) {
        return null;
      }
      if (this.formControl.errors) {
        if (this.formControl.errors.required && this.formControl.touched) {
          err += 'Required. ';
        }
        if (
          this.formControl.errors.checkboxSetRequired &&
          this.formControl.touched
        ) {
          err += 'Must select at least one. ';
        }
        if (this.formControl.errors.minimumDate) {
          err += 'Minimum date is ' + this.minDate.toDateString() + '. ';
        }
        if (this.formControl.errors.maximumDate) {
          err += 'Maximum date is ' + this.maxDate.toDateString() + '. ';
        }
        if (this.formControl.errors.pattern) {
          err += this.field.validationMessage + '. ';
        }
      }
    }

    if (err === '') {
      err = null;
    }
    if (this.errorTooltip !== err) {
      this.errorTooltip = err;
    }
  }

  // TODO: Suppress emit if there's no real change from old value?
  onChange($event): void {
    this.updateErrorTooltip();
    this.changeValue.emit(this.formControl.value);
  }

  onSelectChanged(fieldName, $event): void {
    this.formControl.setValue($event.change.value);
    this.formControl.markAsDirty();
    this.onChange($event);
  }

  onDatePickerChange($event): void {
    this.formControl.setValue($event);
    this.formControl.updateValueAndValidity();
    this.formControl.markAsDirty();

    this.onChange($event);
  }

  onKeyDown(field: FormFieldSpec, $event: KeyboardEvent): void {
    // TODO: If we ever want to suppress keys not matching a regex it could be done here
  }

  setValues(field) {
    if (this.field.questionId === 33) {
      this.selectorItems = [];
      for (const option of this.field.questionOptions) {
        let item = {
          text: option.optionValue,
          value: option.subOptionId,
        };
        if (option.subOptionId.toString() === field.toString())
          item['selected'] = true;
        this.selectorItems.push(item);
        this.formControl.setValue(Number(field));
        this.formControl.updateValueAndValidity();
        this.formControl.markAsDirty();
        this.changeValue.emit(this.formControl.value);
      }
    }
  }

  ngOnDestroy(): void {
    this.ngSubject.next();
    this.ngSubject.complete();
  }
}
