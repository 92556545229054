import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { endpointUrls } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrganizationManagementService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  getOrganization(status): Observable<any> {
    const param = '?includeInactive=' + status;
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const getOrganizationURL = endpointUrls.organization + param;
    const getOrganizationEndpoint = backendURL + getOrganizationURL;
    return this.http.get(getOrganizationEndpoint);
  }

  addOrganization(name): Observable<any> {
    const param = '?organizationName=' + name;
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const getOrganizationURL = endpointUrls.AddOrganization + param;
    const getOrganizationEndpoint = backendURL + getOrganizationURL;
    return this.http.post(getOrganizationEndpoint, null);
  }

  editOrganization(data): Observable<any> {
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const getOrganizationURL = endpointUrls.editOrganization;
    const getOrganizationEndpoint = backendURL + getOrganizationURL;
    return this.http.post(getOrganizationEndpoint, data);
  }

  getUnAllocatedOrganizations(): Observable<any> {
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const getUnAllocatedOrganizationURL =
      endpointUrls.getUnAllocatedOrganizations;
    const getUnAllocatedOrganizationEndpoint =
      backendURL + getUnAllocatedOrganizationURL;
    return this.http.get(getUnAllocatedOrganizationEndpoint);
  }

  getAttorneySpecificOrganizations(id): Observable<any> {
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const getAttorneySpecificOrganizationURL = endpointUrls.getAttorneySpecificOrganizations + id;
    const getAttorneySpecificOrganizationEndpoint = backendURL + getAttorneySpecificOrganizationURL;
    return this.http.get(getAttorneySpecificOrganizationEndpoint);
  }

  deleteOrganization(id): Observable<any> {
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const deleteOrganizationURL = endpointUrls.deleteOrganization + id;
    const deleteOrganizationEndpoint = backendURL + deleteOrganizationURL;
    return this.http.post(deleteOrganizationEndpoint, null);
  }
}
