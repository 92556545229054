import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { endpointUrls } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class Dashboardservice {

  constructor(private http: HttpClient, private authService: AuthService) {}

  getUserApplicationStatus(): Observable<any> {
    console.log('Calling getUserApplicationStatus');
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const applicationStatusUrl = endpointUrls.applicationStatus;
    const applicationstatsendpoint = backendURL + applicationStatusUrl;
    return this.http.get(applicationstatsendpoint);
  }

}
