import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {        
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                console.log('ERROR INTERCEPTOR CAUGHT ERROR', error);
                if (error && error.status && error.status === 401) {
                    this.router.navigate([ '/unauthorized' ]);
                } else {                    
                  this.router.navigate([ '/error' ]);
                }
                return throwError(error);
            })
        );
    }
}
