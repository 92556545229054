import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  FormSection,
  FormService,
  FormSubmission,
} from 'src/app/services/form-service.service';
import { Dashboardservice } from '../../services/dashboard.service';
import { FileUploadService } from '../../services/file-upload.service';

@Component({
  selector: 'forms-container',
  templateUrl: './forms-container.component.html',
  styleUrls: ['./forms-container.component.scss'],
})
export class FormsContainerComponent implements OnInit, OnDestroy {
  generatetabs = false;
  attachmentTab = false;
  private ngSubject: Subject<any> = new Subject<any>();
  isDraft = false;
  constructor(
    private formService: FormService,
    public dashboardservice: Dashboardservice,
    public fileUploadService: FileUploadService
  ) {}

  selectedTabIndex: number;

  questionnaire: FormSection[];
  initialData: FormSubmission;
  initialDataQueried = false;
  instance = [];

  isLoading = false;
  isSubmitted = false;
  isCompleted = false;

  formsHeader = 'Form';

  ngOnInit(): void {
    this.isLoading = true;
    forkJoin([
      this.formService.getFormSubmission(),
      this.formService.getQuestions(),
    ])
      .pipe(takeUntil(this.ngSubject))
      .subscribe((response: [FormSubmission, FormSection[]]) => {
        console.log(response[0]);
        console.log(response[1]);
        this.isLoading = false;

        this.initialDataQueried = true;
        this.initialData = response[0];

        this.questionnaire = response[1];
        this.isSubmitted = this.initialData?.isFinalSubmission;

        if (this.isSubmitted) {
          this.formsHeader = 'Form (Submitted)';
        }

        this.selectedTabIndex = 0;
      });

    this.dashboardservice
      .getUserApplicationStatus()
      .pipe(takeUntil(this.ngSubject))
      .subscribe(
        (data) => {
          if (data) {
            if (
              data.currentUserApplicationStatus === 'Forms Generated' ||
              data.currentUserApplicationStatus === 'Completed'
            ) {
              this.generatetabs = true;
            }
            if (
              data.currentUserApplicationStatus === 'New' ||
              data.currentUserApplicationStatus === 'In Draft'
            ) {
              this.attachmentTab = true;
              this.isDraft = true;
            }
            if (data.currentUserApplicationStatus === 'Completed') {
              this.isCompleted = true;
            }
            this.isLoading = false;
          }
        },
        (error) => {
          console.log('Error when fetching application status', error);
          this.isLoading = false;
        }
      );
    this.fileUploadService
      .getTabChange()
      .pipe(takeUntil(this.ngSubject))
      .subscribe(() => {
        this.selectedTabIndex = 0;
      });
  }

  getInstance(event): void {
    this.instance = [];
    event.forEach((item, index) => {
      this.instance.push(item.name);
    });
  }

  formSubmitted(): void {
    this.selectedTabIndex = 1;
    this.isSubmitted = true;
  }

  proceedToAttachments(): void {
    this.selectedTabIndex = 1;
  }
  ngOnDestroy(): void {
    this.ngSubject.next();
    this.ngSubject.complete();
  }
}
