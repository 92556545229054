import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { UserData, ModifiedUser, AddUser } from '../../models/user-data.model';
import { UserManagementService } from '../../services/user-management.service';
import { ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { ViewEncapsulation } from '@angular/core';
import { Table } from 'primeng/table';
import { ElementRef } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { takeUntil } from 'rxjs/operators';
import { OrganizationManagementService } from '../../services/organization-management.service';
import { FileUpload } from "primeng/fileupload/primeng-fileupload";
import { saveAs } from 'file-saver';
import { error } from '@angular/compiler/src/util';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserManagementComponent implements OnInit, OnDestroy {
  isLoading = false;
  tableData: UserData[] = [];
  usersUpdateSubscription: Subscription;
  addUserSubscription: Subscription;
  page = 1;
  pageSize = 12;
  tableSearch: string;
  emailId: string;
  options = 'internal';
  selectedMember;
  currentSearchVal;
  usersearch: Subscription;
  user: UserData;
  userNames: any[];
  NameResults: any[];
  onEdit: boolean;
  role = 'user';
  checked: boolean;
  firstName: string;
  lastName: string;
  territory: string;
  organization: string;
  assigneeId: string;
  clientId: string;
  engagement: string;
  status = true;
  modifieUser: ModifiedUser;
  response: string;
  userAdd: AddUser;
  userAccessId: number;
  admin: boolean;
  userRole = true;
  public tableSorting = true;
  internal: boolean = true;
  external: boolean;
  searchErr: boolean;
  regexpName = new RegExp('^[a-zA-Z\\-\\s]{1,28}$');
  regexpEmail = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
  regexpAssigne = new RegExp('^(([a-zA-Z]{2})\-?([0-9]{1,100}))$');
  validationSucces: boolean;
  firstNameErr: boolean;
  lastNameErr: boolean;
  emailErr: boolean;
  tenantsErr: boolean;
  statusErr: boolean;
  assigneErr: boolean;
  clientErr: boolean;
  engmntErr: boolean;
  searchUsers: any;
  fNameErrorMsg: string;
  lNameErrorMsg: string;
  mailErrorMsg: string;
  assigneErrorMsg: string;
  clientErrorMsg: string;
  engmntErrorMsg: string;
  validUser = true;
  disable: boolean;
  dupCheck: boolean;
  addSuccess: Subscription;
  editSuccess: Subscription;
  deleteSuccess: Subscription;
  myarray:any[];
  responses:any[];
  numRegex = new RegExp('^[0-9]+$');
  stringRegex = new RegExp('^[a-zA-Z0-9]+$');
  stringRegex1 = new RegExp('^[a-zA-Z0-9-]+$');
  caseIdRegex = new RegExp('^(CAS\-([a-zA-Z0-9-])+)$');
  regexAtDigit = new RegExp('([A-Za-z]+([0-9]+))');
  digitCheck = new RegExp('.*\\d+.*');
  noMatchFound = false;
  deleteindex: any;
  fName: string;
  lName: string;
  mimsid: string;
  mimsCaseIdentifierErr: boolean = false;
  caseIdentifierErrorMsg: string;
  validId: boolean;
  territoryErrorMsg: string;
  territoryErr: boolean;
  organizationErrorMsg: string;
  organizationErr: boolean;
  countryCodes = [];
  countryCode: any;
  unAllocatedOrganization = [];
  organizationCode: any;
  orgID: string;

  @ViewChild('baseModalLarge', { static: false }) baseModalLarge: any;
  @ViewChild('baseModalCustom', { static: false }) baseModalCustom: any;
  @ViewChild('globalSearch') userInput: ElementRef;
  @ViewChild('OrgTable2') pTableComponent: Table;
  searchInput: string;
  xVisible: boolean;
  showoverlay: boolean;
  private ngSubject: Subject<any> = new Subject<any>();

  @ViewChild('bulkUploadModalLarge', { static: false }) bulkUploadModalLarge: any;
  @ViewChild('localFileSelector')
  localFile!: FileUpload;
  uploadFiles: any;
  fileError = false;
  filesData:FormData = new FormData();
  bulkUploadStatus: any = { "message": " ", "status": true };
  showBulkUploadNotificationMsg = false;
  showErrorFile = false;
  showProgressBar = false;
  concatString: any;
  constructor(
    private userManagementService: UserManagementService,
    private cdr: ChangeDetectorRef,
    private toastService: ToastService,
    private organizationManagementService: OrganizationManagementService
  ) {}

  ngOnInit(): void {    
    this.isLoading = true;
    this.usersUpdateSubscription = this.userManagementService
      .getUsersUpdatedListener()
      .pipe(takeUntil(this.ngSubject))
      .subscribe((users) => {
        console.log('RECEIVED USERS IN LISTENER...', users);
        this.isLoading = false;
        this.tableData = users;
      });
    this.userManagementService.getUsers();
    this.userManagementService.getCountryCode().subscribe(
      (response) => {
        this.countryCodes = response;
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
    this.getOrg();
    this.getBulkUploadStatus();
    
    setInterval(() => {
      this.getBulkUploadStatus();
    }, 10000);

    // this.isLoading = true;    
    // this.userManagementService.downloadGUMFile().subscribe((response) => {  
    //   console.log(response, 'narms')
    //   // {responseType: 'blob' as 'json'}          
    //   this.showGUMTemplate = true;      
    // },
    // (error) => {
    //   console.log('ERROR when downloading GUM Template File', error);
    //   if (error.status) {
    //     this.showGUMTemplate = false;
    //   }
    // });
    // this.isLoading = false;
  };

  downloadTemplateFile(data: any) {        
    let blob = new Blob(['\ufeff' + data], { type: 'text/csv;charset=utf-8;' });

    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(data.file);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", encodeURI(url));
    dwldLink.setAttribute("download", data.fileName + data.extension);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  getBulkUploadStatus() {
    this.userManagementService.getBulkUploadStatus().subscribe((response) => {
      this.bulkUploadStatus = { "message": response.message, "status": response.status }; 
      this.showErrorFile = false;
      this.showBulkUploadNotificationMsg = false;
      this.showProgressBar = false;
      if (this.bulkUploadStatus.status && this.bulkUploadStatus.message.toUpperCase().includes('DOWNLOAD THE ERROR FILE')) {
        this.showErrorFile = true;
        this.showBulkUploadNotificationMsg = true;
        this.showProgressBar = false;
        
      } else if (!this.bulkUploadStatus.status && this.bulkUploadStatus.message.toUpperCase().includes('STILL IN PROGRESS')) {
        this.showErrorFile = false;
        this.showBulkUploadNotificationMsg = true;
        this.showProgressBar = true;
      } else if (!this.bulkUploadStatus.status && this.bulkUploadStatus.message.toUpperCase().includes('CONTACT HELP DESK')) {
        this.showErrorFile = false;
        this.showBulkUploadNotificationMsg = true;
        this.showProgressBar = false;
      } 

      if (this.bulkUploadStatus.status) {
        this.userManagementService.getUsers();
      }
    });
  }

  showMoreCustom(id: string, value): void {
    this.showoverlay = true;
    this.fName = value.firstName;
    this.lName = value.lastName;
    this.deleteindex = value.userAccessId;
    console.log(this.deleteindex);
    this.baseModalCustom.showMore(id);
  }

  closeModalCustom(id: string): void {
    this.showoverlay = false;
    this.baseModalCustom.closeModal(id);
  }

  getOrg(): void {
    this.isLoading = true;
    this.organizationManagementService
      .getOrganization(false)
      .pipe(takeUntil(this.ngSubject))
      .subscribe((response) => {
        console.log('RECEIVED...', response);
        this.isLoading = false;
        this.unAllocatedOrganization = response;
      });
  }

  showMoreLarge(id: string): void {
    this.showoverlay = true;
    this.searchInput = '';
    this.searchErr = false;
    this.noMatchFound = false;
    this.userNames = [];
    this.NameResults = [];
    this.dupCheck = false;
    if (!this.onEdit) {
      this.getOrg();
      this.firstName = '';
      this.lastName = '';
      this.emailId = '';
      this.organization = '';
      this.territory = '';
      this.countryCode = '';
      this.organizationCode = '';
      this.mimsid = '';
      this.assigneeId = '';
      this.clientId = '';
      this.engagement = '';
      this.externalUser('External');
      this.roleChange('user');
    }
    this.cdr.detectChanges();
    this.baseModalLarge.showMore(id);
  }

  closeModalLarge(id: string): void {
    this.showoverlay = false;
    this.onEdit = false;
    this.baseModalLarge.closeModal(id);
    this.resetFields();
    this.clearErr();
    this.searchUsers = '';
  }

  searchclear(value): void {
    console.log('value', value);
    alert();
  }

  closeModalLargeOk(id: string): void {
    // if (
    //   (this.searchUsers === '' ||
    //     this.searchUsers === null ||
    //     this.searchUsers === undefined) &&
    //   this.internal &&
    //   !this.onEdit
    // ) 
    if (
      this.internal &&
      !this.onEdit
    ) 
    {
      this.showoverlay = true;
      this.searchErr = false;

    }
    if (this.onEdit) {
      this.addAfterValid(id);
    } else {
      this.validateUserDetails();
      if(this.validateUserDetails() && !this.searchErr){
        this.addAfterValid(id);
      }
      // this.mimsCaseIdentifierValidation(id);
    }
    this.onEdit = false;
  }

  resetFields(): void {
    this.status = true;
    this.user = {
      userAccessId: 0,
      firstName: '',
      lastName: '',
      emailId: '',
      organizationId: '',
      territory: '',
      role: '',
      status: '',
      userType: '',
      mimsid: '',
      assigneeId: '',
      clientId: '',
      engagement: ''
    };
    this.countryCode = '';
    this.organizationCode = '';
  }

  modalDetermine(): void {
    console.log('click ok');
  }

  modalClose(): void {
    console.log('click close');
  }

  getEditOrg(id): void {
    this.isLoading = true;
    this.organizationManagementService
      .getAttorneySpecificOrganizations('?userId=' + id.userAccessId)
      .pipe(takeUntil(this.ngSubject))
      .subscribe((org) => {
        this.isLoading = false;
        this.unAllocatedOrganization = org;
        this.setOrganization(id);
        this.showMoreLarge('show-large-modal');
      });
  }

  editUser(item): void {
    this.getEditOrg(item);
    this.onEdit = true;
    this.user = item;
    this.countryCode = item.territory;
    this.role = item.role;
    this.roleChange(this.role);
    if (this.user.status === 'Active') {
      this.status = true;
    } else {
      this.status = false;
    }
    this.externalUser(item.userType);
    this.userAccessId = item.userAccessId;
    this.mimsid = item.mimsid;
    //ashwini
    if(item.assigneeId != null && item.assigneeId != undefined && item.assigneeId != ''){
      this.assigneeId = item.assigneeId;
    } else{
      this.assigneeId = ' ';
    }
    //ashwini
    if(item.clientId != null && item.clientId != undefined && item.clientId != ''){
      this.clientId = item.clientId;
    } else{
      this.clientId = ' ';
    }
    if(item.engagement != null && item.engagement != undefined && item.engagement != ''){
      this.engagement = item.engagement;
    } else{
      this.engagement = ' ';
    }

  }

  setOrganization(data): void {
    this.unAllocatedOrganization.forEach((id) => {
      if (id.organizationID === data.organizationId) {
        this.organizationCode = id;
      }
    });
  }

  externalUser(option): void {
    if (!this.onEdit) {
      this.searchInput = '';
      this.userNames = [];
      this.NameResults = [];
      this.firstName = '';
      this.lastName = '';
      this.emailId = '';
      this.organization = '';
      this.territory = '';
      this.searchUsers = '';
      this.mimsid = '';
      this.countryCode = '';
      this.organizationCode = '';
      this.assigneeId = '';
      this.clientId = '';
      this.engagement = '';
      this.options = 'external';
    }
    if (option === 'Internal') {
      this.internal = true;
      this.external = false;

      this.clearErr();
    } else {
      this.clearErr();
      this.role = 'user';
      this.roleChange('user');
      this.searchErr = false;
      this.noMatchFound = false;
      this.internal = false;
      this.external = true;
    }
  }

  addUser(): void {
    let state: string;
    let type: string;
    let roleId: number;
    if (this.status) {
      state = 'Active';
    } else {
      state = 'Disabled';
    }
    if (this.userRole) {
      roleId = 2;
    } else {
      roleId = 1;
    }
    if (this.internal) {
      type = 'Internal';
    } else {
      type = 'External';
    }
    if (this.onEdit) {
      this.modifieUser = {
        userAccessId: this.userAccessId,
        status: state,
        roleId,
      };
      this.isLoading = true;
      this.userManagementService.editUser(this.modifieUser);
    } else {
      this.validateInternalUser();
      this.userAdd = {
        firstName: this.firstName,
        lastName: this.lastName,
        emailId: this.emailId,
        organizationId: this.orgID,
        territory: this.territory,
        roleId,
        status: state,
        userType: type,
        mimsid: this.mimsid,
        assigneeId: this.assigneeId,
        clientId: this.clientId,
        engagement: this.engagement
      };
      this.duplicateCheck(this.userAdd);
      if (!this.dupCheck && this.validUser) {
        this.isLoading = true;
        this.userManagementService.addUser(this.userAdd);
      }
    }
    this.onEdit = false;
    this.addSuccess = this.userManagementService
      .getSuccess()
      .pipe(takeUntil(this.ngSubject))
      .subscribe((response) => {
        if (!this.dupCheck && response.status) {
          const msg = {
            severity: 'success',
            summary: 'User added successfully',
          };
          this.toastService.getToastMsg(msg);
          this.isLoading = false;
        } else {
          const msg = {
            severity: 'error',
            summary: 'Unable to add user',
          };
          this.toastService.getToastMsg(msg);
          this.isLoading = false;
        }
      });
    this.editSuccess = this.userManagementService
      .getEditSuccess()
      .pipe(takeUntil(this.ngSubject))
      .subscribe((response) => {
        this.isLoading = false;        
        if (response.status) {
          const msg = {
            severity: 'success',
            summary: 'User updated successfully',
          };
          this.toastService.getToastMsg(msg);
        } else {
          const msg = {
            severity: 'error',
            summary: 'Unable update user successfully',
          };
          this.toastService.getToastMsg(msg);
        }
      });
  }

  roleChange(role): void {
    if (role === 'Admin') {
      this.admin = true;
      this.userRole = false;
    } else {
      this.userRole = true;
      this.admin = false;
    }
  }

  searchuser(event): void {
    this.noMatchFound = false;
    this.usersearch = this.userManagementService
      .getSearch()
      .pipe(takeUntil(this.ngSubject))
      .subscribe((users) => {
        this.isLoading = false;
        this.userNames = [];
        this.NameResults = [];

        if (users && users.length > 0) {
          users.forEach((element) => {
            this.userNames.push({
              firstname: element.givenName,
              lastName: element.surname,
              email: element.userPrincipalName,
            });
            this.NameResults.push({
              name: element.displayName + ' (' + element.userPrincipalName + ')',
              value: element,
            });
          });
        } else {
          this.noMatchFound = true;
        }
      });
    this.userManagementService.directorySearchUsingEmail(event.query);
  }

  populateFields(event): void {
    this.searchErr = false;
    this.firstName = '';
    this.lastName = '';
    this.emailId = '';
    const selectedUser = event.value;
    this.searchUsers = selectedUser;
    this.firstName = selectedUser.givenName;
    this.lastName = selectedUser.surname;
    this.emailId = selectedUser.userPrincipalName;
    this.noMatchFound = false;
  }

  searchError(): void {
    this.searchErr = false;
  }

  clearErr(): void {
    this.firstNameErr = false;
    this.lastNameErr = false;
    this.emailErr = false;
    this.tenantsErr = false;
    this.statusErr = false;
    this.mimsCaseIdentifierErr = false;
    this.territoryErr = false;
    this.organizationErr = false;
    this.assigneErr = false;
    this.engmntErr = false;
    this.clientErr = false;
    this.fNameErrorMsg = '';
    this.lNameErrorMsg = '';
    this.mailErrorMsg = '';
    this.caseIdentifierErrorMsg = '';
    this.territoryErrorMsg = '';
    this.organizationErrorMsg = '';
    this.assigneErrorMsg = '';
    this.clientErrorMsg = '';
    this.engmntErrorMsg = '';
  }

  validateUserDetails(): boolean {
    //if (!this.onEdit && this.external) 
    if (!this.onEdit && (this.external || this.internal)) 
    {
      this.firstNameValidation();
      this.lastNameValidation();
      this.emailValidation();
      this.territoryValidation();
      this.organizationValidation();
      this.assigneValidation();
      this.clientValidation();
      this.engmntValidation();
      this.mimsCaseIdentifierValidation();
      if (
        !this.firstNameErr &&
        !this.lastNameErr &&
        !this.emailErr &&
        !this.mimsCaseIdentifierErr &&
        !this.organizationErr &&
        !this.territoryErr &&
        !this.assigneErr &&
        !this.clientErr &&
        !this.engmntErr
      ) {
        return true;
      }
    } else {
      return true;
    }
  }

  onChangeEvent(event, type): void {
    if (type === 'fName') {
      this.firstName = event.target.value;
      this.firstNameValidation();
    } else if (type === 'lName') {
      this.lastName = event.target.value;
      this.lastNameValidation();
    } else if (type === 'email') {
      this.emailId = event.target.value;
      this.emailValidation();
    } else if (type === 'assigneeId') {
      this.assigneeId = event.target.value;
      this.assigneValidation();     
    } else if (type === 'clientId') {
      this.clientId = event.target.value;
      this.clientValidation();     
    } else if (type === 'engagement') {
      this.engagement = event.target.value;
      this.engmntValidation();     
    } else if (type === 'organization') {
      if (this.organizationCode && this.organizationCode.organizationName) {
        this.organization = this.organizationCode.organizationName;
        this.orgID = this.organizationCode.organizationID;
      } else {
        this.organization = '';
      }
      this.organizationValidation();
    } else if (type === 'territory') {
      if (this.countryCode && this.countryCode.countryCode) {
        this.territory = this.countryCode.countryCode;
      } else {
        this.territory = '';
      }
      this.territoryValidation();
    } else {
      this.mimsid = event.target.value;
      this.validationMiMs();
      this.mimsCaseIdentifierValidation();
    }
  }

  deleteUser(id: string): void {
    this.showoverlay = false;
    this.isLoading = true;
    console.log('sdfsdf', this.deleteindex);
    this.userManagementService.deleteUser(this.deleteindex);
    this.closeModalCustom(id);

    this.deleteSuccess = this.userManagementService
      .getDeleteSuccess()
      .pipe(takeUntil(this.ngSubject))
      .subscribe((users) => {
        this.isLoading = false;
        const msg = {
          severity: 'success',
          summary: 'User deleted successfully',
        };
        this.toastService.getToastMsg(msg);
      });
  }

  searchClear(value): void {
    console.log('clear', value);
    this.tableSearch = '';
  }

  pagination(pageNo, pageSize, array): any[] {
    const offset = (pageNo - 1) * pageSize;
    return offset + pageSize >= array.length
      ? array.slice(offset, array.length)
      : array.slice(offset, offset + pageSize);
  }
  clear(): void {
    this.tableSearch = '';
    this.userManagementService.getUsers();
  }

  clearsearchmodal(): void {
    this.searchInput = '';
    this.userNames = [];
    this.NameResults = [];
    this.firstName = '';
    this.lastName = '';
    this.emailId = '';
    this.noMatchFound = false;
    this.searchUsers = '';
  }
  // Global Search Functionalities Start
  onKeyup(event): void {
    if (this.userInput.nativeElement.value !== '') {
      this.xVisible = true;
    } else {
      this.xVisible = false;
    }
  }
  clearGlobalFilter(a): void {
    this.pTableComponent.reset();
    this.userInput.nativeElement.value = '';
    this.xVisible = false;
  }
  // Global Search Functionalities End

  firstNameValidation(): void {
    if (
      this.firstName === '' ||
      this.firstName === null ||
      this.firstName === undefined
    ) {
      this.firstNameErr = true;
      this.fNameErrorMsg = 'Please enter First Name';
    } else {
      if (!this.regexpName.test(this.firstName)) {
        this.firstNameErr = true;
        this.fNameErrorMsg = 'Enter Valid First Name';
        this.validUser = false;
      } else {
        this.firstNameErr = false;
        this.fNameErrorMsg = '';
        this.validUser = true;
      }
    }
  }

  lastNameValidation(): void {
    if (
      this.lastName === '' ||
      this.lastName === null ||
      this.lastName === undefined
    ) {
      this.lastNameErr = true;
      this.lNameErrorMsg = 'Please enter Last Name';
    } else {
      if (!this.regexpName.test(this.lastName)) {
        this.lastNameErr = true;
        this.lNameErrorMsg = 'Enter Valid Last Name';
        this.validUser = false;
      } else {
        this.lastNameErr = false;
        this.lNameErrorMsg = '';
        this.validUser = true;
      }
    }
  }

  emailValidation(): void {
    if (
      this.emailId === '' ||
      this.emailId === null ||
      this.emailId === undefined
    ) {
      this.emailErr = true;
      this.mailErrorMsg = 'Please enter Email';
    } 
    else if(this.external == true && this.emailId.toLowerCase().endsWith("@vialto.com") ){
      this.emailErr = true;
      this.mailErrorMsg = 'Vialto user cannot be added as External User and can only be added as Internal!';
      this.validUser = false;
    } 
    else if(this.external == false && !this.emailId.toLowerCase().endsWith("@vialto.com")){
      this.emailErr = true;
      // this.mailErrorMsg = 'PwC user cannot be added as Internal User and can only be added as External!';
      this.mailErrorMsg = 'Any user except Vialto user, should be added as as External User!';
      this.validUser = false;
    }
    else {
      if (!this.regexpEmail.test(this.emailId)) {
        this.emailErr = true;
        this.mailErrorMsg = 'Enter Valid Email';
        this.validUser = false;
      } else {
        this.emailErr = false;
        this.mailErrorMsg = '';
        this.validUser = true;
      }
    }
  }

  mimsCaseIdentifierValidation(): void {
    // if (this.external || this.internal) {
    //   if (
    //     this.mimsid === '' ||
    //     this.mimsid === null ||
    //     this.mimsid === undefined
    //   ) {
    //     this.mimsCaseIdentifierErr = true;
    //     this.caseIdentifierErrorMsg = 'Please enter MIMs case identifier';
    //     this.validUser = false;
    //   } else {
    //     this.miMsIdValidation(id);
    //   }
    // } else {
    //   if (
    //     this.mimsid === '' ||
    //     this.mimsid === null ||
    //     this.mimsid === undefined
    //   ) {
    //     this.validUser = true;
    //     this.addAfterValid(id);
    //   } else {
    //     this.miMsIdValidation(id);
    //   }
    // }
    if (
      this.mimsid === '' ||
      this.mimsid === null ||
      this.mimsid === undefined
    ) {
      this.mimsCaseIdentifierErr = true;
      this.caseIdentifierErrorMsg = 'Please enter MIMs case identifier';
    } else if(this.mimsid){
      if(this.mimsid.includes("-")){
        this.myarray = this.mimsid.split('-');
        console.log(this.myarray);
        this.concatString = '';
        for (let i = 0; i < this.myarray.length; i++) {
          this.concatString = (this.concatString + this.myarray[i]);
        }
        console.log(this.concatString, this.digitCheck.test(this.concatString));
        this.responses = [];
        this.myarray.forEach(Element => {
          this.responses.push(this.stringRegex.test(Element));
        }); 
        console.log(this.responses);
        if(this.digitCheck.test(this.concatString) && !this.responses.includes(false)){ 
        this.mimsCaseIdentifierErr = false;
         this.validUser =true;
         this.caseIdentifierErrorMsg = ""
         console.log("Valid Case Id2"); 
        } else {
          this.mimsCaseIdentifierErr = true;
          this.caseIdentifierErrorMsg= 'Please Enter Valid Case ID';
          this.validUser = false;
        }
        } else if(this.numRegex.test(this.mimsid)){
           this.mimsCaseIdentifierErr = false;
           this.validUser =true;
           this.caseIdentifierErrorMsg = '';
       } else {
       this.mimsCaseIdentifierErr = true;
       this.validUser = false;
       this.caseIdentifierErrorMsg = "Please Enter Valid Case ID";
      }
    }
    else {
      this.mimsCaseIdentifierErr = true;
      this.validUser = false;
      this.caseIdentifierErrorMsg = "Please Enter Valid Case ID";
      }
  }

  duplicateCheck(userAdd): void {
    const id = [];
    this.tableData.forEach((user) => {
      id.push(user.emailId);
      if (id.includes(userAdd.emailId)) {
        const msg = {
          severity: 'error',
          summary: 'User already exist',
        };
        this.toastService.getToastMsg(msg);
        this.dupCheck = true;
        this.isLoading = false;
      } else {
        this.dupCheck = false;
      }
    });
  }

  miMsIdValidation(id): void {
    this.isLoading = true;
    this.userManagementService
      .validateMiMsIdCaseIdentifier(this.mimsid)
      .pipe(takeUntil(this.ngSubject))
      .subscribe((response) => {
        this.validId = response;
        this.isLoading = false;
        if (!this.validId) {
          const msg = {
            severity: 'error',
            summary: 'Please enter valid Case ID',
          };
          this.toastService.getToastMsg(msg);
        } else {
          this.mimsCaseIdentifierErr = false;
          this.caseIdentifierErrorMsg = '';
          this.validUser = true;
          this.addAfterValid(id);
        }
        console.log('id', response);
      });
  }

  territoryValidation(): void {
    if (this.external || this.internal) {
      if (
        this.territory === '' ||
        this.territory === null ||
        this.territory === undefined
      ) {
        this.territoryErr = true;
        this.territoryErrorMsg = 'Please enter Territory';
      } else {
        this.territoryErr = false;
        this.territoryErrorMsg = '';
        this.validUser = true;
      }
    } else {
      this.validUser = true;
    }
  }

  organizationValidation(): void {
    if (this.external || this.internal) {
      if (
        this.organization === '' ||
        this.organization === null ||
        this.organization === undefined
      ) {
        this.organizationErr = true;
        this.organizationErrorMsg = 'Please enter Organization';
      } else {
        this.organizationErr = false;
        this.organizationErrorMsg = '';
        this.validUser = true;
      }
    } else {
      this.validUser = true;
    }
  }

  assigneValidation(): void {
    if (
      this.assigneeId === '' ||
      this.assigneeId === null ||
      this.assigneeId === undefined
    ) {
      this.assigneErr = true;
      this.assigneErrorMsg = 'Please enter Assigne ID';
    } else {
      if (this.regexpAssigne.test(this.assigneeId) || this.numRegex.test(this.assigneeId)) {
        this.assigneErr = false;
        this.assigneErrorMsg = '';
        this.validUser = true;
      } else {
        this.assigneErr = true;
        this.assigneErrorMsg = 'Please enter Valid Assignee ID';
        this.validUser = false;       
      }
    }
  }
  clientValidation(): void {
    if (
      this.clientId === '' ||
      this.clientId === null ||
      this.clientId === undefined
    ) {
      this.clientErr = true;
      this.clientErrorMsg = 'Please enter Client ID';
    } else {
      if (this.numRegex.test(this.clientId)) {
        this.clientErr = false;
        this.clientErrorMsg = '';
        this.validUser = true;
      } else {
        this.clientErr = true;
        this.clientErrorMsg = 'Please enter Valid Client ID';
        this.validUser = false;       
      }
    }
  }
  engmntValidation(): void {
    if (
      this.engagement === '' ||
      this.engagement === null ||
      this.engagement === undefined
    ) {
      this.engmntErr = true;
      this.engmntErrorMsg = 'Please enter Engagement ID';
    } else {
      if (this.numRegex.test(this.engagement)) {
        this.engmntErr = false;
        this.engmntErrorMsg = '';
        this.validUser = true;
      } else {
        this.engmntErr = true;
        this.engmntErrorMsg = 'Please enter Valid Engagement ID';
        this.validUser = false;       
      }
    }
  }
  validateInternalUser(): void {
    if (
      (this.mimsid === '' ||
        this.mimsid === null ||
        this.mimsid === undefined) &&
      this.internal
    ) {
      this.validUser = true;
    }
  }

  ngOnDestroy(): void {
    this.ngSubject.next();
    this.ngSubject.complete();
  }

  addAfterValid(id): void {
    if (this.validateUserDetails() && !this.searchErr) {
      this.showoverlay = false;
      this.isLoading = true;
      this.addUser();

      if (!this.dupCheck) {
        this.baseModalLarge.closeModalOk(id);
      }
      this.resetFields();
      this.clearErr();
      this.searchUsers = '';
    }
  }

  validationMiMs(): void {
    if (
      this.mimsid === '' ||
      this.mimsid === null ||
      this.mimsid === undefined
    ) {
      this.mimsCaseIdentifierErr = true;
      this.caseIdentifierErrorMsg = 'Please enter MIMs case identifier';
      this.validUser = false;
    } else {
      this.mimsCaseIdentifierErr = false;
      this.caseIdentifierErrorMsg = '';
      this.validUser = true;
    }
  }

  showBulkUpload(id: string): void {
    if (!this.bulkUploadStatus.status) {
      return;
    } else {
      this.baseModalLarge.showMore(id);
    }
    this.showoverlay = true;
    this.fileError = false;
    this.filesData = new FormData(); 
    this.uploadFiles = [];
    if (this.localFile !== undefined) {
      this.localFile.clear();
    }    
    this.showBulkUploadNotificationMsg = false;    
  }

  uploadCSV() {
    this.localFile.upload();
    this.uploadFiles = this.localFile._files;
    this.fileError = false;   
    if (this.uploadFiles !== undefined && this.uploadFiles.length > 0) {
      for (let i = 0; i < this.uploadFiles.length; i++) {       
        this.filesData.append('file' + i, this.uploadFiles[i], this.uploadFiles[i].name);
      }   
    } else {
      this.fileError = true;
    }
    if(!this.fileError) {
      this.userManagementService.addBulkUser(this.filesData);
    }
    this.closeModalLarge('show-bulk-modal');       

    this.addSuccess = this.userManagementService
    .getSuccess()
    .pipe(takeUntil(this.ngSubject))
    .subscribe((response) => {
      if (response.status) {
        const msg = {
          severity: 'success',
          summary: 'Users detail submitted successfully',
        };
        this.toastService.getToastMsg(msg);
        this.getBulkUploadStatus();
      } else {
        const msg = {
          severity: 'error',
          summary: 'Unable to submit users detail',
        };
        this.toastService.getToastMsg(msg);
        // this.isLoading = false;
      }
    });
  }

  // downloadTemplate() {
  //   this.isLoading = true;
  //   this.userManagementService.downloadBulkUploadTemplate();
  //   this.isLoading = false;
  // }
  
  //sameer
  downloadTemplate() {
    this.isLoading = true;
    this.userManagementService.downloadBulkUploadTemplate().subscribe(
      (data) => {
        this.isLoading = false;
        saveAs(data, 'template.xlsx');
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }
  //sameer
 
  downloadErrorFile() {    
    this.isLoading = true;
    this.userManagementService.downloadErrorFile();
    this.isLoading = false;
  }

  downloadGUMTemplate() {    
    this.isLoading = true;    
    this.userManagementService.downloadGUMFile().subscribe((response) => {  
      if (response.headers.get('Content-Type').includes('text/plain')) {
        const msg = {
          severity: 'info',
          summary: 'No records are found for GUM template.',
        };
        this.toastService.getToastMsg(msg);        
      } else if (response.headers.get('Content-Type').includes('application/octet-stream')) {        
        const fileName= 'GUMTemplate.csv';
        const blob = response.body;
        saveAs(blob, fileName);
      } else if (response.headers.get('Content-Type').includes('application/zip')) {        
        const fileName= 'GUMTemplate.zip';
        const blob = response.body;
        saveAs(blob, fileName);
      }      
    },
    (error) => {
      console.log('ERROR when downloading GUM Template File', error);
    });
    this.isLoading = false;
  }
  
}
