import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  name = 'My Adjustment of Status';
  loggedInUserName: string;
  isUserAdmin = false;
  isSuperAdmin = false;
  private authenticationSubscription: Subscription;

  dropdownMenuActive = '';
  showDropdownMenu = false;
  logoutMenu = false;
  @ViewChild('baseModalCustom', { static: false }) baseModalCustom: any;
  showoverlay: boolean;
  constructor(private router: Router, private authService: AuthService) {}

  showLogoutMenu(): void {
    this.logoutMenu = !this.logoutMenu;
  }

  activeLogoutMenu(): void {
    this.logoutMenu = false;
  }

  signOut(): void {
    this.showoverlay = false;
    this.logoutMenu = false;
    this.authService.logout('manual');
  }

  ngOnInit(): void {
    const dom = document.body;
    dom.setAttribute(
      'data-theme-logo',
      getComputedStyle(document.documentElement)
        .getPropertyValue('--theme-color-logo')
        .trim()
    );

    this.loggedInUserName = '';

    this.isUserAdmin = false;
    this.isSuperAdmin = false;
    let userData = this.authService.getLoggedInUserData();
    console.log('all userdata:', userData);
    console.log('userData :', userData.emailId);
    if (userData) {
      this.isUserAdmin = userData.userRole === 'Admin' ? true : false;
      this.loggedInUserName = userData.userName;
    }
    if (userData.emailId.toLowerCase() == 'liam.j.auld@vialto.com' || userData.emailId.toLowerCase() == 'testuser01@vialto.com') {
       this.isSuperAdmin = true; 
       this.loggedInUserName = userData.userName;
    }
    this.authenticationSubscription = this.authService
      .getAuthStatusListener()
      .subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          userData = this.authService.getLoggedInUserData();
          console.log('userData updated :', userData.emailId);
          if (userData) {
            this.isUserAdmin = userData.userRole === 'Admin' ? true : false;
            this.loggedInUserName = userData.userName;
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.authenticationSubscription.unsubscribe();
  }

  tabChange(event: any): void {
    console.log(event);
    if (event.index === 0) {
      this.router.navigate(['/']);
    }
    if (event.index === 1) {
      this.router.navigate(['/manageUsers']);
    }
    if (event.index === 2) {
      this.router.navigate(['/manageOrganization']);
    }
    if (event.index === 3) {
      this.router.navigate(['/manageAttorney']);
    }
    if (event.index === 4) {
      this.router.navigate(['/checkStatus']);
    }
  }

  showMoreCustomheader(id: string): void {
    this.showoverlay = true;
    this.baseModalCustom.showMore(id);
  }

  closeModalCustomheader(id: string): void {
    this.showoverlay = false;
    this.baseModalCustom.closeModal(id);
  }
}
