<div id="testthis">
  <div
    class="a-panel a-mt-20 aos-sub-section"
    [hidden]="subSection.subSectionStatus === 'Disabled'"
    [formGroup]="formGroup"
  >
    <div class="a-panel-header" *ngIf="subSection.subSectionName">
      {{ subSection.subSectionName }}
    </div>

    <div class="aos-sub-section-body">
      <ap-button
        *ngIf="
          subSection.subSectionName === 'Information About You - US Address' &&
          parentInstance.name !== 'Primary'
        "
        class="copy-button"
        btnText="Copy From Primary"
        (onBtnClick)="copyAddress()"
        [config]="{ isPrimary: 'true' }"
      ></ap-button>

      <span class="row" *ngFor="let batch of batchedControls; let i = index">
        <div *ngFor="let field of batch; let i = index">
          <div class="a-p-10" *ngIf="field.isToggledOn && !field.isHidden">
            <ng-container>
              <form-field-control
                [field]="field"
                [formGroup]="formGroup"
                [isHidden]="!field.isToggledOn"
                (changeValue)="controlChanged(field, $event)"
                [minDate]="field.minDate_value"
                [maxDate]="field.maxDate_value"
              ></form-field-control>
            </ng-container>
          </div>
        </div>
      </span>
    </div>
  </div>
</div>
