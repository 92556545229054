import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UserManagementService } from '../../../services/user-management.service';
import { UserData } from '../../../models/user-data.model';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {

  private mode = 'create';
  private userId: string;
  userData: UserData;
  isLoading = false;
  userForm: FormGroup;

  constructor(public userManagementService: UserManagementService, public route: ActivatedRoute) { }

  ngOnInit(): void {

    // this.userForm = new FormGroup({
    //   firstName: new FormControl(null, {
    //     validators: [Validators.required]
    //   }),
    //   lastName: new FormControl(null, {
    //     validators: [Validators.required]
    //   }),
    //   emailId: new FormControl(null, {
    //     validators: [Validators.required, Validators.email]
    //   }),
    //   password: new FormControl(null, {
    //     validators: [Validators.required]
    //   }),
    //   organization: new FormControl(null, {
    //     validators: [Validators.required]
    //   }),
    //   territory: new FormControl(null, {
    //     validators: [Validators.required]
    //   })
    // });

    // this.route.paramMap.subscribe((paramMap: ParamMap) => {
    //   if (paramMap.has('userId')) {
    //     this.mode = 'edit';
    //     this.userId = paramMap.get('userId');
    //     this.isLoading = true;
    //     this.userManagementService.getUser(this.userId)
    //       .subscribe(data => {
    //         this.isLoading = false;
    //         this.userData = {
    //           userAccessId: data.userAccessId,
    //           firstName: data.firstName,
    //           lastName: data.lastName,
    //           emailId: data.emailId,
    //           organization: data.organization,
    //           territory: data.territory,
    //           role: data.role,
    //           status: data.status,
    //           creator: data.creator
    //         };
    //         this.userForm.setValue({
    //           firstName: this.userData.firstName,
    //           lastName: this.userData.lastName,
    //           email: this.userData.emailId,
    //           password: '',
    //           organisation: this.userData.organization,
    //           territory: this.userData.territory,
    //           role: this.userData.role,
    //           status: this.userData.status
    //         });
    //       });
    //   } else {
    //     this.mode = 'create';
    //     this.userId = null;
    //   }
    // });

  }

  // onSaveUser(): void {
  //   console.log('Save user clicked');
  //   if (this.userForm.invalid) {
  //     console.log('Form invalid');
  //     return;
  //   }

  //   this.isLoading = true;
  //   if (this.mode === 'create') {
  //     this.userManagementService.addUser({
  //       userAccessId: null,
  //       firstName: this.userForm.value.firstName,
  //       lastName: this.userForm.value.lastName,
  //       emailId: this.userForm.value.email,
  //       organization: this.userForm.value.organisation,
  //       role: this.userForm.value.role,
  //       status: this.userForm.value.status,
  //       territory: this.userForm.value.territory
  //     });
  //   } else {
  //     this.userManagementService.editUser({
  //       userAccessId: this.userId,
  //       firstName: this.userForm.value.firstName,
  //       lastName: this.userForm.value.lastName,
  //       emailId: this.userForm.value.email,
  //       organization: this.userForm.value.organisation,
  //       role: this.userForm.value.role,
  //       status: this.userForm.value.status,
  //       territory: this.userForm.value.territory
  //     });
  //   }
  //   this.userForm.reset();
  // }

}
