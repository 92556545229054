import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormService } from '../../services/form-service.service';
import { FileUploadService } from '../../services/file-upload.service';
import { FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { ToastService } from '../../services/toast.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnDestroy {
  selectedfile: string;
  currentInput: string;
  public floatingConfig = {
    isFloating: true,
  };
  isLoading = false;
  documentsList = {};
  documentsForm: FormGroup;
  inValid = true;
  isSubmit = false;
  isDelete = false;
  dependentType = '';
  document = {
    documentName: '',
    documentTitle: '',
    isMandatory: '',
    translation: '',
    documentTypeID: '',
    fileName: '',
    isOnServer: '',
  };
  isFileSelected: boolean;

  private documentsFormGroupSubscription: Subscription;
  private instancesSubscription: Subscription;
  private ngSubject: Subject<any> = new Subject<any>();
  @ViewChild('baseModalCustom', { static: false }) baseModalCustom: any;
  @ViewChild('fileModalCustom', { static: false }) fileModalCustom: any;

  instances = [];
  accordion = false;
  showoverlay: boolean;
  @Input() isCompleted;
  @Input() isDraft;
  isOnServer = false;
  fileEvent: Event;
  instanceName: string;
  docSelected: any;
  nameSubscription: Subscription;
  name = {};

  constructor(
    private formService: FormService,
    private fileUploadService: FileUploadService,
    private toastService: ToastService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.instances = [];
    const formInstancesArray = [...this.formService.getInstances()];
    formInstancesArray.forEach((inst) => {
      this.instances.push(inst.name);
    });
    this.instancesSubscription = this.formService
      .getInstanceListener()
      .pipe(takeUntil(this.ngSubject))
      .subscribe((instanceArray) => {
        this.instances = [];
        const currentInstanceArray = [...instanceArray];
        currentInstanceArray.forEach((inst) => {
          this.instances.push(inst.name);
        });
        this.fileUploadService.toFormGroup(this.instances);
      });
    this.name = {};
    this.name = this.formService.getName();
    this.nameSubscription = this.formService
      .getNameListener()
      .pipe(takeUntil(this.ngSubject))
      .subscribe((name) => {
        this.name = name;
      });
    this.isLoading = true;
    this.documentsFormGroupSubscription = this.fileUploadService
      .getDocumentsFormGroupListener()
      .pipe(takeUntil(this.ngSubject))
      .subscribe((formGroup) => {
        this.documentsForm = formGroup;
        this.documentsList = this.fileUploadService.getDocumentsList(
          this.instances
        );
        if (!this.documentsForm.invalid) {
          this.isSubmit = true;
          this.fileUploadService.validForm(true);
        } else {
          this.isSubmit = false;
          this.fileUploadService.validForm(false);
        }
        this.isLoading = false;
      });
    this.fileUploadService.toFormGroup(this.instances);
  }

  onFileSelected(
    event: Event,
    instanceName: string,
    document: any,
    id?: string
  ): void {
    let fileValidation = true;
    const fileType = [
      'jpg',
      'jpeg',
      'png',
      'gif',
      'tiff',
      'doc',
      'docx',
      'pdf',
    ];
    const file = (event.target as HTMLInputElement).files[0];
    let extension: any = file.name.split('.');
    extension = extension[extension.length - 1];
    if (fileType.indexOf(extension.toLowerCase()) === -1) {
      fileValidation = false;
    }
    if (file.size > 100000000) {
      fileValidation = false;
    }
    if (document.fileName) {
      document.isOnServer = false;
    }
    if (fileValidation) {
      const reader = new FileReader();

      this.isLoading = true;
      reader.onloadend = (e) => {
        const arr = new Uint8Array(e.target.result as ArrayBuffer).subarray(
          0,
          4
        );
        let header = '';
        let isValid = false;
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        console.log(header);
        switch (header) {
          case '25504446':
            isValid = true; // PDF
            break;
          case '504b34':
            isValid = true; // DOCX
            break;
          case '49492a0':
            isValid = true; // TIFF
            break;
          case 'd0cf11e0':
            isValid = true; // DOC, XLS, PPT
            break;
          case '89504e47':
            isValid = true; // PNG
            break;
          case 'ffd8ffe0':
          case 'ffd8ffe1':
          case 'ffd8ffe2':
          case 'ffd8ffe3':
          case 'ffd8ffe8':
            isValid = true; // JPEG
            break;
          case '47494638':
            isValid = true; // GIF
            break;
          default:
            isValid = false; // Or you can use the blob.type as fallback
            break;
        }
        console.log('FILE VALID', isValid);

        if (isValid) {
          document.fileName = file.name; // Setting the uploaded file name to display on table

          const documentName = document.documentName;
          // this.documentsForm.patchValue({instanceName: {documentName: file}});
          (this.documentsForm.controls[instanceName] as FormGroup).controls[
            documentName
          ].patchValue(file);
          this.documentsForm
            .get(instanceName + '.' + documentName)
            .updateValueAndValidity();
        } else {
          const msg = {
            severity: 'error',
            summary: 'File type or size not valid',
          };
          this.toastService.getToastMsg(msg);
        }

        this.isLoading = false;
      };
      // reader.readAsDataURL(file);
      reader.readAsArrayBuffer(file);
      // if (!this.documentsForm.invalid) {
      //   this.inValid = false;
      // }
      this.inValid = false;
      this.isSubmit = false;
    } else {
      const msg = {
        severity: 'error',
        summary: 'File type or size not valid',
      };
      this.toastService.getToastMsg(msg);
    }
    if (id) {
      this.closeFileModal(id);
    }
  }

  public findInvalidControlsRecursive(formToInvestigate: FormGroup): string[] {
    const invalidControls: string[] = [];
    const recursiveFunc = (form: FormGroup) => {
      Object.keys(form.controls).forEach((field) => {
        const control = form.get(field);
        if (control.invalid) {
          invalidControls.push(field);
        }
        if (control instanceof FormGroup) {
          recursiveFunc(control);
        }
      });
    };
    recursiveFunc(formToInvestigate);
    return invalidControls;
  }

  fileUpload(): void {
    // if (this.documentsForm.invalid) {
    //   this.inValid = true;
    //   console.log('Form is invalid');
    //   console.log(
    //     'Invalid controls',
    //     this.findInvalidControlsRecursive(this.documentsForm)
    //   );
    //   return;
    // }
    // this.inValid = false;

    this.isLoading = true;
    this.fileUploadService.fileUpload(this.instances, this.documentsForm).then(
      () => {
        this.isLoading = false;
        this.inValid = true;
        if (!this.documentsForm.invalid) {
          this.isSubmit = true;
          this.fileUploadService.validForm(true);
        } else {
          this.fileUploadService.validForm(false);
          this.isSubmit = false;
        }
        this.fileUploadService.toFormGroup(this.instances);
        const msg = {
          severity: 'success',
          summary: 'File(s) uploaded successfully',
        };
        this.toastService.getToastMsg(msg);
      },
      () => {
        this.isLoading = false;
        const msg = {
          severity: 'error',
          summary: 'File upload failed',
        };
        this.toastService.getToastMsg(msg);
      }
    );
  }

  deleteDoc(id): void {
    if (this.document.isOnServer) {
      const instance = [];
      this.isLoading = true;
      const data = {
        dependentType: this.dependentType,
        documentTypeId: this.document.documentTypeID,
      };

      this.fileUploadService
        .deleteAttachments(data)
        .pipe(takeUntil(this.ngSubject))
        .subscribe(
          (response) => {
            this.isLoading = false;
            const msg = {
              severity: 'success',
              summary: 'File deleted successfully',
            };
            this.toastService.getToastMsg(msg);
            instance.push(this.dependentType);
            this.fileUploadService.toFormGroup(instance);
          },
          (error) => {
            this.isLoading = false;
          }
        );
    } else {
      console.log('Data');
      (this.documentsForm.controls[this.dependentType] as FormGroup).controls[
        this.document.documentName
      ].patchValue(null);
      this.documentsList[this.dependentType].forEach((doc) => {
        if (doc.documentName === this.document.documentName) {
          doc.fileName = '';
        }
      });
    }
    if (!this.documentsForm.invalid) {
      this.fileUploadService.validForm(true);
    } else {
      this.fileUploadService.validForm(false);
    }
    this.closeModalCustom(id);
  }

  onSelect(event): void {
    this.selectedfile = event.target.files[0].name;
  }

  closeModalCustom(id: string): void {
    this.isDelete = false;
    this.showoverlay = false;
    this.baseModalCustom.closeModal(id);
  }

  submitDoc(): void {
    this.fileUpload();
  }

  showMoreCustom(id: string): void {
    this.showoverlay = true;
    this.baseModalCustom.showMore(id);
  }

  submitForm(id): void {
    this.isLoading = true;
    this.formService
      .submitFormInstances()
      .pipe(takeUntil(this.ngSubject))
      .subscribe(
        (response) => {
          this.isLoading = false;
          const msg = {
            severity: 'success',
            summary: 'Form submitted successfully',
          };
          this.toastService.getToastMsg(msg);
          this.router.navigate(['/']);
        },
        (error) => {
          this.isLoading = false;
        }
      );
    this.fileUploadService.validForm(true);
    this.closeModalCustom(id);
  }

  formsTab(): void {
    this.fileUploadService.tabChange();
  }

  showModal(id, item, document): void {
    if (document.fileName) {
      this.isDelete = true;
      this.dependentType = item;
      this.document = document;
      this.showoverlay = true;
      this.baseModalCustom.showMore(id);
    }
  }
  ngOnDestroy(): void {
    this.documentsFormGroupSubscription.unsubscribe();
    this.instancesSubscription.unsubscribe();
    this.ngSubject.next();
    this.ngSubject.complete();
  }

  showModalForFile(id, event, item, document): void {
    this.fileEvent = event;
    this.instanceName = item;
    this.docSelected = document;
    if (document.fileName && document.isOnServer) {
      this.showoverlay = true;
      this.isFileSelected = true;
      this.fileModalCustom.showMore(id);
    } else {
      this.isFileSelected = false;
      this.onFileSelected(event, item, document);
    }
  }

  closeFileModal(id): void {
    this.isFileSelected = false;
    this.showoverlay = false;
    this.fileModalCustom.closeModal(id);
  }
}
