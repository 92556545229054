import { OnDestroy, ViewChild } from '@angular/core';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import { Router } from '@angular/router';
import { of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  FormAnswer,
  FormFieldSpec,
  FormFieldType,
  FormInstance,
  FormSection,
  FormService,
  FormSubmission,
  FormSubmissionInstance,
  FormSubSection,
  TODAY,
} from '../services/form-service.service';

@Component({
  selector: 'multi-step-form',
  templateUrl: './multi-step-form.component.html',
  styleUrls: ['./multi-step-form.component.css'],
})
export class MultiStepFormComponent implements OnInit, OnDestroy {
  constructor(
    private formService: FormService,
    private formBuilder: FormBuilder,
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) {}

  @Input() questionSpecs: FormSection[];
  @Input() initialData: FormSubmission;

  @Output() instance = new EventEmitter<any>();
  @Output() onSubmit = new EventEmitter<any>();
  @Output() onProceedToAttachments = new EventEmitter<any>();

  @ViewChild('baseModalCustom', { static: false }) baseModalCustom: any;
  private ngSubject: Subject<any> = new Subject<any>();
  preSubmittedAnswers: FormSubmissionInstance[];
  formInstances: Array<FormInstance>;
  currentInstance: FormInstance;
  instanceForDeletion: FormInstance;

  nextDependentNumber: number;

  isLoading = false;
  isSubmitted = false;
  proceedToAttachments: boolean;

  get currentFormGroup(): FormGroup {
    return this.currentInstance.sectionFormGroups[
      this.currentInstance.formSections[
        this.currentInstance.currentSectionIndex
      ].sectionId
    ];
  }

  get currentSectionId(): number {
    return this.currentInstance.formSections[
      this.currentInstance.currentSectionIndex
    ].sectionId;
  }

  ngOnInit(): void {
    if (!this.initialData) {
      this.preSubmittedAnswers = [];
      this.currentInstance = this.buildFormInstance(
        'Primary',
        this.questionSpecs
      );
      this.formInstances = [this.currentInstance];
      this.nextDependentNumber = 1;
      this.formService.setInstances(this.formInstances);
      this.getNames(this.formInstances);
    } else {
      this.isSubmitted = this.initialData.isFinalSubmission;
      const response = this.initialData.questionnaireResponse;
      const primaryArray = response.filter(
        (instance) => instance.instanceName === 'Primary'
      );
      const dependentsArray = response.filter(
        (instance) => instance.instanceName !== 'Primary'
      );
      const dependentsArraySorted = dependentsArray.sort((a, b) =>
        a.instanceName.localeCompare(b.instanceName)
      );
      let instanceArray = [];
      instanceArray.push(primaryArray[0]);
      dependentsArraySorted.forEach((obj) => {
        instanceArray.push(obj);
      });
      this.preSubmittedAnswers = instanceArray;
      this.nextDependentNumber =
        this.initialData.questionnaireResponse.length - 1;
      this.formInstances = [];
      for (const submittedInstance of this.preSubmittedAnswers) {
        const isDependent =
          this.preSubmittedAnswers.indexOf(submittedInstance) !== 0;
        let inst = this.buildFormInstance(
          submittedInstance.instanceName,
          this.questionSpecs,
          isDependent,
          submittedInstance.listOfAnswers,
          submittedInstance.lastSelectedSection,
          submittedInstance.lastCompletedSection
        );
        this.formInstances.push(inst);
        this.formService.setInstances(this.formInstances);
        this.getNames(this.formInstances);
      }
      this.currentInstance =
        this.formInstances.find(
          (inst) =>
            inst.name ===
            this.initialData.questionnaireResponse[0].selectedInstanceName
        ) || this.formInstances[0];

      this.updateControlSettings();

      // We had some problems with dependent instance naming having duplicate or out of sequence numbers if an instance was deleted.
      // The simplest solution is to have the name be continually incremented, but to display the index so it will always show 1, 2, 3...
      // even if the names may be "Dependent 4, Dependent 6, Depenent 9"
      this.nextDependentNumber = 1;
      if (this.formInstances.length > 1) {
        const lastInstanceName = this.formInstances[
          this.formInstances.length - 1
        ].name;
        this.nextDependentNumber = parseInt(
          lastInstanceName.substring(lastInstanceName.indexOf(' ')),
          10
        );
        this.nextDependentNumber++;
      }
    }
  }

  getNames(formInstances): void {
    let nameList = {};
    for (let i = 0; i < this.formInstances.length; i++) {
      if (
        formInstances[i].sectionFormGroups[1].value &&
        formInstances[i].sectionFormGroups[1].value.control2
      ) {
        nameList[formInstances[i].name] = {
          name:
            formInstances[i].sectionFormGroups[1].value.control2 +
            ' ' +
            formInstances[i].sectionFormGroups[1].value.control1,
        };
      } else {
        nameList[formInstances[i].name] = {
          name: '',
        };
      }
    }
    this.formService.setName(nameList);
  }

  // for debugging only - used to get a list of form errors
  errorCheck(): void {
    const keys = Object.keys(this.currentFormGroup.controls);
    const errorList = {};

    for (const k of keys) {
      if (this.currentFormGroup.controls[k].errors) {
        errorList[k] = this.currentFormGroup.controls[k].errors;
      }
    }

    console.log(errorList);
  }

  getControl(field: FormFieldSpec, controls): any {
    const controlName = this.formService.getControlName(field);
    return controls[controlName];
  }

  //TODO: Would be nice to consolidate this and similar functionality in buildFormGroup and form-sub-section change event into a central place in form-service
  buildFormGroup(
    section: FormSection,
    preFilledAnswers: FormAnswer[]
  ): FormGroup {
    const controlsForBuilder = {};

    for (const [index, subSection] of section.formSubSection.entries()) {
      for (const fieldSpec of subSection.formFieldSpecification) {
        const fieldType = fieldSpec.type.toLowerCase();
        const controlName = this.formService.getControlName(fieldSpec);
        const validators = this.formService.getValidators(fieldSpec);

        const answer = preFilledAnswers.find(
          (q) => q.questionId === fieldSpec.questionId
        );

        // A checkboxSet is a special case which will have its own nested formGroup containing the controls
        if (fieldType === FormFieldType.checkboxSet) {
          controlsForBuilder[controlName] = this.getCheckboxGroup(
            fieldSpec,
            answer,
            validators
          );
        } else if (fieldType !== FormFieldType.label) {
          let value: any = fieldSpec.defaultValue || '';
          if (answer) {
            value =
              fieldType === FormFieldType.radio ||
              fieldType === FormFieldType.dropdown
                ? answer.optionId.toString()
                : answer.responseTextValue;
          }
          if (fieldType === FormFieldType.date) {
            if (!!value) {
              value = new Date(value);
            } // asumes that the data is a valid date string
            if (fieldSpec.minDate?.toLowerCase() === TODAY) {
              fieldSpec.minDate_value = new Date();
            }
            if (fieldSpec.maxDate?.toLowerCase() === TODAY) {
              fieldSpec.maxDate_value = new Date();
            }
          }
          if (fieldType === FormFieldType.checkbox) {
            if (value && typeof value === 'string' && value.toLowerCase() === 'false') {
              value = false;
            } else {
              value = Boolean(value);
            }
          }
          if (fieldType === FormFieldType.radio && value?.toString() === '0') {
            value = '';
          }

          const control = new FormControl(value, validators);
          if (this.isSubmitted || fieldSpec.isHidden) {
            control.disable();
          }
          controlsForBuilder[controlName] = control;
        }
      }

      // Note that a lot of this code block duplicates functionality in form-sub-section and both could possibly be centralized in form-service eventually.
      // This updates toggles and date bounadries on initial load, the other does it on control change.

      // Important: currently fields can only be toggled by other fields within same sub-section.
      // Loop through these dependencies after everything else is done to make sure you don't miss anything out of order
      for (const fieldSpec of subSection.formFieldSpecification) {
        this.setFieldToggle(fieldSpec, controlsForBuilder, subSection);

        if (fieldSpec.minDate || fieldSpec.maxDate) {
          const currentControl: AbstractControl = this.getControl(
            fieldSpec,
            controlsForBuilder
          );

          this.setMinMaxDates(fieldSpec, controlsForBuilder);

          currentControl.setValidators(
            this.formService.getValidators(fieldSpec)
          );
          currentControl.updateValueAndValidity();
        }

        if (
          (fieldSpec.alwaysDisabled || !fieldSpec.isToggledOn) &&
          fieldSpec.type.toLowerCase() !== FormFieldType.label
        ) {
          this.getControl(fieldSpec, controlsForBuilder).disable();
        }
      }
    }

    return new FormGroup(controlsForBuilder);
  }

  setMinMaxDates(field: FormFieldSpec, controls): void {
    if (field?.minDate && field.minDate?.toLowerCase() !== TODAY) {
      const dateControl =
        controls[
          this.formService.getControlNameById(parseInt(field.minDate, 10))
        ];
      field.minDate_value = dateControl.value;
    }
    if (field?.maxDate && field.maxDate?.toLowerCase() !== TODAY) {
      const dateControl =
        controls[
          this.formService.getControlNameById(parseInt(field.maxDate, 10))
        ];
      field.maxDate_value = dateControl.value;
    }
  }

  setFieldToggle(
    field: FormFieldSpec,
    controls,
    subSection: FormSubSection
  ): void {
    if (field.toggleQuestionId) {
      const controlName = this.formService.getControlNameById(
        field.toggleQuestionId
      );
      const control = controls[controlName];

      const toggleField = subSection.formFieldSpecification.find(
        (x) => x.questionId === field.toggleQuestionId
      );
      field.isToggledOn = this.formService.isMatchToggleValue(
        field.toggleQuestionValue,
        control.value
      );
    } else {
      field.isToggledOn = true;
    }
  }

  private getCheckboxGroup(
    fieldSpec: FormFieldSpec,
    answer: FormAnswer,
    validators: ValidatorFn[]
  ): FormGroup {
    const subgroupControls = {};

    const splitAnswer = answer ? answer.responseTextValue.split(',') : [];

    for (const option of fieldSpec.questionOptions) {
      const value = splitAnswer.indexOf(option.subOptionId.toString()) !== -1;
      const subControl = new FormControl(value);
      if (this.isSubmitted) {
        subControl.disable();
      }
      subgroupControls[this.formService.getOptionName(option)] = subControl;
    }

    return new FormGroup(subgroupControls, validators);
  }

  advanceSection(forward = true): void {
    const oldSectionIndex = this.currentInstance.currentSectionIndex;
    const newSectionIndex = forward ? oldSectionIndex + 1 : oldSectionIndex - 1;
    const oldSectionCompletion = this.currentInstance.sectionCompletion[
      this.currentSectionId
    ];

    // boundary/validation check, probably not needed but just in case
    if (
      newSectionIndex <= -1 ||
      newSectionIndex >= this.currentInstance.formSections.length ||
      this.currentFormGroup.invalid
    ) {
      return;
    }

    if (forward) {
      this.currentInstance.sectionCompletion[this.currentSectionId] = true;
    }
    this.currentInstance.currentSectionIndex = newSectionIndex;

    this.isLoading = true;

    const observe = !this.isSubmitted
      ? this.formService.saveDraft(
          this.formInstances,
          this.currentInstance.name
        )
      : of(null);
    observe.pipe(takeUntil(this.ngSubject)).subscribe(
      () => {
        this.isLoading = false;
        window.scroll({ top: 0, behavior: 'smooth' });
      },
      (err) => {
        this.currentInstance.currentSectionIndex = oldSectionIndex;
        this.currentInstance.sectionCompletion[
          this.currentSectionId
        ] = oldSectionCompletion;
      }
    );
    this.getNames(this.formInstances);
  }

  isLastSectionLastInstance(): boolean {
    return (
      this.currentInstance ===
        this.formInstances[this.formInstances.length - 1] &&
      this.currentInstance.currentSectionIndex ===
        this.currentInstance.formSections.length - 1
    );
  }

  createNewFormInstance(): void {
    const newInstance = this.buildFormInstance(
      'Dependent ' + this.nextDependentNumber,
      this.questionSpecs,
      true
    );
    this.formInstances.push(newInstance);
    this.currentInstance.sectionCompletion[this.currentSectionId] = true;
    this.currentInstance = newInstance;
    this.formService.setInstances(this.formInstances);
    this.getNames(this.formInstances);
  }

  buildFormInstance(
    name,
    pages,
    isDependent = false,
    answers: FormAnswer[] = null,
    selectedSectionId: number = null,
    lastCompletedSection: number = null
  ): FormInstance {
    const instance = new FormInstance();
    instance.name = name;
    instance.formSections = pages;
    instance.currentSectionIndex = 0;
    instance.isDependent = isDependent;
    instance.sectionCompletion = {};
    instance.sectionFormGroups = {};

    if (!answers) {
      answers = [];
    }

    let completedSectionPassed =
      (lastCompletedSection !== 0 && !lastCompletedSection) || false;
    let selectedIndex: number = null;
    for (const section of instance.formSections) {
      const sectionAnswers = [];

      instance.sectionFormGroups[section.sectionId] = this.buildFormGroup(
        section,
        answers
      );
      instance.sectionCompletion[section.sectionId] = false;

      if (!completedSectionPassed) {
        instance.sectionCompletion[section.sectionId] = true;
      }
      if (section.sectionId === lastCompletedSection) {
        completedSectionPassed = true;
      }
      if (section.sectionId === selectedSectionId) {
        selectedIndex = instance.formSections.indexOf(section);
      }
    }

    if (selectedIndex != null) {
      instance.currentSectionIndex = selectedIndex;
    }
    this.nextDependentNumber++;

    return instance;
  }

  setFormInstance(instance: FormInstance): void {
    if (instance === this.currentInstance) {
      return;
    }
    this.isLoading = true;
    this.formService
      .saveDraft(this.formInstances, instance.name)
      .pipe(takeUntil(this.ngSubject))
      .subscribe(() => {
        this.currentInstance = instance;

        this.updateControlSettings();

        this.isLoading = false;
      });
  }

  //TODO: Would be nice to consolidate this and similar functionality in buildFormGroup and form-sub-section change event into a central place in form-service
  updateControlSettings() {
    let instance = this.currentInstance;

    for (let section of instance.formSections) {
      let formGroup = instance.sectionFormGroups[section.sectionId];
      for (let subSection of section.formSubSection) {
        for (let field of subSection.formFieldSpecification) {
          this.setFieldToggle(
            field,
            instance.sectionFormGroups[section.sectionId].controls,
            subSection
          );
          this.setMinMaxDates(
            field,
            instance.sectionFormGroups[section.sectionId].controls
          );

          if (field.minDate || field.maxDate) {
            let control = this.getControl(field, formGroup.controls);
            control.setValidators(this.formService.getValidators(field));
            control.updateValueAndValidity();
          }
        }
      }
    }
  }

  deleteInstance(instance): void | undefined {
    const index = this.formInstances.indexOf(instance);
    if (index === 0) {
      // primary instance, shouldn't be able to get here anyway
      return;
    }
    this.currentInstance = this.formInstances[index - 1];
    this.formInstances.splice(index, 1);
    this.formService.setInstances(this.formInstances);
    this.getNames(this.formInstances);
    this.isLoading = true;
    this.formService
      .saveDraft(this.formInstances, this.currentInstance.name)
      .pipe(takeUntil(this.ngSubject))
      .subscribe(() => {
        this.isLoading = false;
      });
  }

  saveDraft(): void {
    this.isLoading = true;
    this.formService
      .saveDraft(this.formInstances, this.currentInstance.name)
      .pipe(takeUntil(this.ngSubject))
      .subscribe((x) => {
        this.isLoading = false;
        this.currentFormGroup.markAsPristine();
      });
  }

  discardDraft(id): void {
    this.isLoading = true;
    this.formService
      .discardDraft()
      .pipe(takeUntil(this.ngSubject))
      .subscribe((response) => {
        this.isLoading = false;
        this.router.navigate(['/']);
      });
    this.closeModalCustom(id);
  }

  disableForm(): void {
    for (const instance of this.formInstances) {
      for (const section of instance.formSections) {
        const formGroup = instance.sectionFormGroups[section.sectionId];

        for (const subSection of section.formSubSection) {
          for (const field of subSection.formFieldSpecification) {
            if (field.type.toLowerCase() === 'label') {
              continue;
            }

            //A checkboxSet control is actually its own formGroup, so need to hit the controls in it
            const control = this.getControl(field, formGroup.controls);
            if (field.type.toLowerCase() === 'checkboxset') {
              let boxGroup = control as FormGroup;
              for (const subKey of Object.keys(boxGroup)) {
                boxGroup.controls[subKey].disable();
              }
            } else {
              control.disable();
            }
          }
        }
      }
    }
  }

  submitForm(): void {
    this.isLoading = true;
    this.formService
      .submitForm(this.formInstances, this.currentInstance.name)
      .pipe(takeUntil(this.ngSubject))
      .subscribe(() => {
        this.isLoading = false;
        this.isSubmitted = true;
        this.disableForm();
        this.onSubmit.emit();
      });
  }

  onSaveAndProceedToAttachments(id: string): void {
    this.isLoading = true;
    this.formService
      .saveDraft(this.formInstances, this.currentInstance.name)
      .pipe(takeUntil(this.ngSubject))
      .subscribe((x) => {
        this.isLoading = false;
        this.currentFormGroup.markAsPristine();
        this.formService.setFormInstancesForSubmission(this.formInstances);
        this.onProceedToAttachments.emit();
      });
    this.getNames(this.formInstances);
    this.baseModalCustom.closeModal(id);
  }

  showDiscardConfirmation(id: string, modal: boolean): void {
    this.proceedToAttachments = modal;
    this.baseModalCustom.showMore(id);
  }

  closeModalCustom(id: string): void {
    this.baseModalCustom.closeModal(id);
  }

  ngOnDestroy(): void {
    this.ngSubject.next();
    this.ngSubject.complete();
  }
}
