import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { AttorneyManagementService } from '../../services/attorney-management.service';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { Table } from 'primeng/table';
import { OrganizationManagementService } from '../../services/organization-management.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-attorney-management',
  templateUrl: './attorney-management.component.html',
  styleUrls: ['./attorney-management.component.scss'],
})
export class AttorneyManagementComponent implements OnInit, OnDestroy {
  showoverlay: boolean;
  isLoading: boolean;
  tableData: any;
  private ngSubject: Subject<any> = new Subject<any>();
  xVisible: boolean;
  onEdit: boolean;
  familyName: string;
  familyNameErrorMsg: string;
  familyNameError: boolean;
  givenName: string;
  givenNameErrorMsg: string;
  givenNameError: boolean;
  middleName: string;
  middleNameErrorMsg: string;
  middleNameError: boolean;
  streetNumberAndName: string;
  streetNumberAndNameErrorMsg: string;
  streetNumberAndNameError: boolean;
  suiteNumber: string;
  suiteNumberErrorMsg: string;
  suiteNumberError: boolean;
  province: string;
  provinceErrorMsg: string;
  provinceError: boolean;
  postalCode: string;
  postalCodeErrorMsg: string;
  postalCodeError: boolean;
  country: string;
  countryErrorMsg: string;
  countryError: boolean;
  daytimeTelephoneNumber: string;
  daytimeTelephoneNumberErrorMsg: string;
  daytimeTelephoneNumberError: boolean;
  emailAddress: string;
  emailAddressErrorMsg: string;
  emailAddressError: boolean;
  faxNumber: string;
  faxNumberErrorMsg: string;
  faxNumberError: boolean;
  licensingAuthority: string;
  licensingAuthorityErrorMsg: string;
  licensingAuthorityError: boolean;
  barNumber: string;
  barNumberErrorMsg: string;
  barNumberError: boolean;
  lawFirmName = 'PwC Law LLP';
  lawFirmNameErrorMsg: string;
  lawFirmNameError: boolean;
  unAllocatedOrganization = [];
  organizationCode: any;
  organizationErrorMsg: string;
  organizationErr: boolean;
  organization: string;
  orgID: any;
  allOrg = [];
  attorneyId: string;
  cityorTown: string;
  cityorTownErrorMsg: string;
  cityorTownError: boolean;
  regexpName = new RegExp('^[a-zA-Z\\-\\s]{1,28}$');
  regexpEmail = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
  regexMName = new RegExp('^[A-Za-z]*$');
  numLetter = new RegExp('^[A-Za-z0-9]*$');
  letterSpace = new RegExp('^[a-zA-Z ]*$');
  numLetterSpace = new RegExp('^[a-zA-Z0-9 ]*$');
  letterNumSpaceslash = new RegExp('^[a-zA-Z0-9 /]*$');
  number = new RegExp('^[0-9]{10}$');
  letterNumSpacesHypen = new RegExp('^[a-zA-Z -]*$');
  deleteId: string;
  name: string;
  status = true;

  @ViewChild('baseModalLargest', { static: false }) baseModalLargest: any;
  @ViewChild('baseModalCustom', { static: false }) baseModalCustom: any;
  @ViewChild('globalSearch') userInput: ElementRef;
  @ViewChild('OrgTable2') pTableComponent: Table;

  constructor(
    private attorneyManagementService: AttorneyManagementService,
    private organizationManagementService: OrganizationManagementService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.getAttorney();
    this.isLoading = true;
    this.organizationManagementService
      .getOrganization(true)
      .pipe(takeUntil(this.ngSubject))
      .subscribe((org) => {
        console.log('RECEIVED ORG LIST', org);
        this.isLoading = false;
        this.allOrg = org;
      });
  }

  getAttorney(): void {
    this.isLoading = true;
    this.attorneyManagementService
      .getAttorney(false)
      .pipe(takeUntil(this.ngSubject))
      .subscribe((org) => {
        console.log('RECEIVED ORG LIST', org);
        this.isLoading = false;
        this.tableData = org;
      });
  }

  ngOnDestroy(): void {
    this.ngSubject.next();
    this.ngSubject.complete();
  }

  showMoreLarge(id): void {
    this.getOrg();
    this.showoverlay = true;
    this.baseModalLargest.showMore(id);
  }

  closeModalCustom(id): void {
    this.deleteId = '';
    this.name = '';
    this.showoverlay = false;
    this.baseModalCustom.closeModal(id);
  }

  showModalCustom(id, data): void {
    this.name = data.givenName + ' ' + data.familyName;
    this.deleteId = data.attorneyID;
    this.showoverlay = true;
    this.baseModalCustom.showMore(id);
  }

  getOrg(): void {
    this.isLoading = true;
    this.organizationManagementService
      .getUnAllocatedOrganizations()
      .pipe(takeUntil(this.ngSubject))
      .subscribe((response) => {
        console.log('RECEIVED...', response);
        this.isLoading = false;
        this.unAllocatedOrganization = response;
      });
  }

  onKeyup(event): void {
    if (this.userInput.nativeElement.value !== '') {
      this.xVisible = true;
    } else {
      this.xVisible = false;
    }
  }

  clearGlobalFilter(a): void {
    this.pTableComponent.reset();
    this.userInput.nativeElement.value = '';
    this.xVisible = false;
  }

  editAttorney(id, data): void {
    this.isLoading = true;
    this.organizationManagementService
      .getAttorneySpecificOrganizations('?attorneyId=' + data.attorneyID)
      .pipe(takeUntil(this.ngSubject))
      .subscribe((org) => {
        console.log('RECEIVED', org);
        this.isLoading = false;
        this.unAllocatedOrganization = org;
      });
    this.onEdit = true;
    this.showoverlay = true;
    this.baseModalLargest.showMore(id);
    this.setFields(data);
    this.setEditObj(data);
  }

  deleteAttorney(id): void {
    this.isLoading = true;
    this.attorneyManagementService
      .deleteAttorney(this.deleteId)
      .pipe(takeUntil(this.ngSubject))
      .subscribe((response) => {
        this.isLoading = false;
        if (response.status) {
          const msg = {
            severity: 'success',
            summary: 'Attorney deleted successfully',
          };
          this.toastService.getToastMsg(msg);
          this.getAttorney();
        }
      });
    this.closeModalCustom(id);
  }

  closeModalLarge(id): void {
    this.clearFields();
    this.showoverlay = false;
    this.baseModalLargest.closeModal(id);
  }

  setFields(data): void {
    this.familyName = data.familyName;
    this.givenName = data.givenName;
    this.middleName = data.middleName;
    this.streetNumberAndName = data.streetNumberAndName;
    this.suiteNumber = data.suiteNumber;
    this.province = data.province;
    this.cityorTown = data.cityorTown;
    this.postalCode = data.postalCode;
    this.country = data.country;
    this.daytimeTelephoneNumber = data.daytimeTelephoneNumber;
    this.emailAddress = data.emailAddress;
    this.faxNumber = data.faxNumber;
    this.licensingAuthority = data.licensingAuthority;
    this.barNumber = data.barNumber;
    this.lawFirmName = data.lawFirmName;
    if (data.status === 1) {
      this.status = true;
    } else {
      this.status = false;
    }
    this.setOrganization(data);
  }

  setOrganization(data): void {
    this.allOrg.forEach((id) => {
      if (id.organizationID === data.organizationId) {
        this.organization = id.organizationName;
        this.organizationCode = id;
      }
    });
  }

  getOrganizationId(): void {
    if (this.organizationCode) {
      this.allOrg.forEach((x) => {
        if (x.organizationName === this.organizationCode.organizationName) {
          this.orgID = x.organizationID;
        }
      });
    }
  }

  setEditObj(data?) {
    this.getOrganizationId();
    if (data) {
      this.attorneyId = data.attorneyID;
    }
    const obj = {
      attorneyID: data ? data.attorneyID : '',
      barNumber: this.barNumber,
      country: this.country,
      daytimeTelephoneNumber: this.daytimeTelephoneNumber,
      emailAddress: this.emailAddress,
      familyName: this.familyName,
      faxNumber: this.faxNumber,
      givenName: this.givenName,
      status: this.status ? 1 : 0,
      lawFirmName: this.lawFirmName,
      licensingAuthority: this.licensingAuthority,
      middleName: this.middleName,
      organizationId: this.orgID,
      postalCode: this.postalCode,
      province: this.province,
      cityorTown: this.cityorTown,
      streetNumberAndName: this.streetNumberAndName,
      suiteNumber: this.suiteNumber,
    };
    console.log(obj);
    return obj;
  }

  addAttorney(id): void {
    if (!this.onEdit) {
      this.getOrganizationId();
      this.valid();
      const obj = {
        attorneyID: 0,
        barNumber: this.barNumber,
        country: this.country,
        daytimeTelephoneNumber: this.daytimeTelephoneNumber,
        emailAddress: this.emailAddress,
        familyName: this.familyName,
        faxNumber: this.faxNumber,
        givenName: this.givenName,
        status: this.status ? 1 : 0,
        lawFirmName: this.lawFirmName,
        licensingAuthority: this.licensingAuthority,
        middleName: this.middleName,
        organizationId: this.orgID,
        postalCode: this.postalCode,
        province: this.province,
        cityorTown: this.cityorTown,
        streetNumberAndName: this.streetNumberAndName,
        suiteNumber: this.suiteNumber,
      };
      console.log(obj);
      if (this.validation()) {
        this.isLoading = true;
        this.attorneyManagementService
          .addAttorney(obj)
          .pipe(takeUntil(this.ngSubject))
          .subscribe((response) => {
            this.isLoading = false;
            if (response.status) {
              const msg = {
                severity: 'success',
                summary: 'Attorney added successfully',
              };
              this.toastService.getToastMsg(msg);
              this.getAttorney();
            } else {
              const msg = {
                severity: 'error',
                summary: response.message,
              };
              this.toastService.getToastMsg(msg);
            }
          });
        this.closeModalLarge(id);
      }
    } else {
      const obj = this.setEditObj();
      obj.attorneyID = this.attorneyId;
      console.log(obj);
      this.isLoading = true;
      this.attorneyManagementService
        .editAttorney(obj)
        .pipe(takeUntil(this.ngSubject))
        .subscribe((response) => {
          this.isLoading = false;
          if (response.status) {
            const msg = {
              severity: 'success',
              summary: 'Attorney edited successfully',
            };
            this.toastService.getToastMsg(msg);
            this.getAttorney();
          } else {
            const msg = {
              severity: 'error',
              summary: response.message,
            };
            this.toastService.getToastMsg(msg);
          }
        });
      this.closeModalLarge(id);
    }
  }

  onChangeEvent(event, type): void {
    if (type === 'familyName') {
      this.familyName = event.target.value;
      this.familyNameValidation();
    } else if (type === 'givenName') {
      this.givenName = event.target.value;
      this.givenNameValidation();
    } else if (type === 'middleName') {
      this.middleName = event.target.value;
      this.middleNameValidation();
    } else if (type === 'streetNumberAndName') {
      this.streetNumberAndName = event.target.value;
      this.streetNumberAndNameValidation();
    } else if (type === 'suiteNumber') {
      this.suiteNumber = event.target.value;
      this.suiteNumberValidation();
    } else if (type === 'province') {
      this.province = event.target.value;
      this.provinceValidation();
    } else if (type === 'city') {
      this.cityorTown = event.target.value;
      this.cityorTownValidation();
    } else if (type === 'postalCode') {
      this.postalCode = event.target.value;
      this.postalCodeValidation();
    } else if (type === 'country') {
      this.country = event.target.value;
      this.countryValidation();
    } else if (type === 'daytimeTelephoneNumber') {
      this.daytimeTelephoneNumber = event.target.value;
      this.daytimeTelephoneNumberValidation();
    } else if (type === 'emailAddress') {
      this.emailAddress = event.target.value;
      this.emailAddressValidation();
    } else if (type === 'faxNumber') {
      this.faxNumber = event.target.value;
      this.faxNumberValidation();
    } else if (type === 'licensingAuthority') {
      this.licensingAuthority = event.target.value;
      this.licensingAuthorityValidation();
    } else if (type === 'barNumber') {
      this.barNumber = event.target.value;
      this.barNumberValidation();
    } else if (type === 'lawFirmName') {
      this.lawFirmName = event.target.value;
      this.lawFirmNameValidation();
    } else {
      if (this.organizationCode && this.organizationCode.organizationName) {
        this.organization = this.organizationCode.organizationName;
      } else {
        this.organization = '';
      }
      this.organizationValidation();
    }
  }

  validation(): boolean {
    if (
      !this.familyNameError &&
      !this.givenNameError &&
      !this.streetNumberAndNameError &&
      !this.suiteNumberError &&
      !this.provinceError &&
      !this.postalCodeError &&
      !this.countryError &&
      !this.daytimeTelephoneNumberError &&
      !this.emailAddressError &&
      !this.faxNumberError &&
      !this.licensingAuthorityError &&
      !this.barNumberError &&
      !this.lawFirmNameError &&
      !this.organizationErr
    ) {
      return true;
    } else {
      return false;
    }
  }

  valid(): void {
    this.familyNameValidation();
    this.givenNameValidation();
    this.middleNameValidation();
    this.streetNumberAndNameValidation();
    this.cityorTownValidation();
    this.suiteNumberValidation();
    this.provinceValidation();
    this.postalCodeValidation();
    this.countryValidation();
    this.daytimeTelephoneNumberValidation();
    this.emailAddressValidation();
    this.faxNumberValidation();
    this.licensingAuthorityValidation();
    this.barNumberValidation();
    this.lawFirmNameValidation();
    this.organizationValidation();
  }

  clearFields(): void {
    this.familyName = '';
    this.familyNameErrorMsg = '';
    this.familyNameError = false;
    this.givenName = '';
    this.givenNameErrorMsg = '';
    this.givenNameError = false;
    this.middleName = '';
    this.middleNameErrorMsg = '';
    this.middleNameError = false;
    this.streetNumberAndName = '';
    this.streetNumberAndNameErrorMsg = '';
    this.streetNumberAndNameError = false;
    this.suiteNumber = '';
    this.suiteNumberErrorMsg = '';
    this.suiteNumberError = false;
    this.province = '';
    this.provinceErrorMsg = '';
    this.provinceError = false;
    this.cityorTown = '';
    this.cityorTownErrorMsg = '';
    this.cityorTownError = false;
    this.postalCode = '';
    this.postalCodeErrorMsg = '';
    this.postalCodeError = false;
    this.country = '';
    this.countryErrorMsg = '';
    this.countryError = false;
    this.daytimeTelephoneNumber = '';
    this.daytimeTelephoneNumberErrorMsg = '';
    this.daytimeTelephoneNumberError = false;
    this.emailAddress = '';
    this.emailAddressErrorMsg = '';
    this.emailAddressError = false;
    this.faxNumber = '';
    this.faxNumberErrorMsg = '';
    this.faxNumberError = false;
    this.licensingAuthority = '';
    this.licensingAuthorityErrorMsg = '';
    this.licensingAuthorityError = false;
    this.barNumber = '';
    this.barNumberErrorMsg = '';
    this.barNumberError = false;
    this.lawFirmName = 'PwC Law LLP';
    this.lawFirmNameErrorMsg = '';
    this.lawFirmNameError = false;
    this.organizationCode = '';
    this.organizationErrorMsg = '';
    this.organizationErr = false;
    this.organization = '';
    this.onEdit = false;
    this.attorneyId = '';
    this.status = true;
    this.getOrg();
  }

  familyNameValidation(): void {
    if (
      this.familyName === '' ||
      this.familyName === null ||
      this.familyName === undefined
    ) {
      this.familyNameError = true;
      this.familyNameErrorMsg = 'Please Enter Family Name';
    } else {
      if (!this.regexpName.test(this.familyName)) {
        this.familyNameError = true;
        this.familyNameErrorMsg = 'Enter Valid Family Name';
      } else {
        this.familyNameError = false;
        this.familyNameErrorMsg = '';
      }
    }
  }

  givenNameValidation(): void {
    if (
      this.givenName === '' ||
      this.givenName === null ||
      this.givenName === undefined
    ) {
      this.givenNameError = true;
      this.givenNameErrorMsg = 'Please Enter Given Name';
    } else {
      if (!this.regexpName.test(this.givenName)) {
        this.givenNameError = true;
        this.givenNameErrorMsg = 'Enter Valid Given Name';
      } else {
        this.givenNameError = false;
        this.givenNameErrorMsg = '';
      }
    }
  }

  middleNameValidation(): void {
    if (!this.regexMName.test(this.middleName)) {
      this.middleNameError = true;
      this.middleNameErrorMsg = 'Enter Valid Middle Name';
    } else {
      this.middleNameError = false;
      this.middleNameErrorMsg = '';
    }
  }

  streetNumberAndNameValidation(): void {
    if (
      this.streetNumberAndName === '' ||
      this.streetNumberAndName === null ||
      this.streetNumberAndName === undefined
    ) {
      this.streetNumberAndNameError = true;
      this.streetNumberAndNameErrorMsg = 'Please Enter Street Number and Name';
    } else {
      if (!this.letterNumSpaceslash.test(this.streetNumberAndName)) {
        this.streetNumberAndNameError = true;
        this.streetNumberAndNameErrorMsg = 'Enter Valid Street Number and Name';
      } else {
        this.streetNumberAndNameError = false;
        this.streetNumberAndNameErrorMsg = '';
      }
    }
  }

  suiteNumberValidation(): void {
    if (
      this.suiteNumber === '' ||
      this.suiteNumber === null ||
      this.suiteNumber === undefined
    ) {
      this.suiteNumberError = true;
      this.suiteNumberErrorMsg = 'Please Enter Suite Number';
    } else {
      if (!this.numLetter.test(this.suiteNumber)) {
        this.suiteNumberError = true;
        this.suiteNumberErrorMsg = 'Enter Valid Suite Number';
      } else {
        this.suiteNumberError = false;
        this.suiteNumberErrorMsg = '';
      }
    }
  }

  provinceValidation(): void {
    if (
      this.province === '' ||
      this.province === null ||
      this.province === undefined
    ) {
      this.provinceError = true;
      this.provinceErrorMsg = 'Please Enter Province';
    } else {
      if (!this.letterSpace.test(this.province)) {
        this.provinceError = true;
        this.provinceErrorMsg = 'Enter Valid Province';
      } else {
        this.provinceError = false;
        this.provinceErrorMsg = '';
      }
    }
  }

  cityorTownValidation(): void {
    if (
      this.cityorTown === '' ||
      this.cityorTown === null ||
      this.cityorTown === undefined
    ) {
      this.cityorTownError = true;
      this.cityorTownErrorMsg = 'Please Enter City or Town';
    } else {
      if (!this.letterSpace.test(this.cityorTown)) {
        this.cityorTownError = true;
        this.cityorTownErrorMsg = 'Enter Valid City or Name';
      } else {
        this.cityorTownError = false;
        this.cityorTownErrorMsg = '';
      }
    }
  }

  postalCodeValidation(): void {
    if (
      this.postalCode === '' ||
      this.postalCode === null ||
      this.postalCode === undefined
    ) {
      this.postalCodeError = true;
      this.postalCodeErrorMsg = 'Please Enter Postal Code';
    } else {
      if (!this.numLetterSpace.test(this.postalCode)) {
        this.postalCodeError = true;
        this.postalCodeErrorMsg = 'Enter Valid Postal Code';
      } else {
        this.postalCodeError = false;
        this.postalCodeErrorMsg = '';
      }
    }
  }

  countryValidation(): void {
    if (
      this.country === '' ||
      this.country === null ||
      this.country === undefined
    ) {
      this.countryError = true;
      this.countryErrorMsg = 'Please Enter Country';
    } else {
      if (!this.letterSpace.test(this.country)) {
        this.countryError = true;
        this.countryErrorMsg = 'Enter Valid Country';
      } else {
        this.countryError = false;
        this.countryErrorMsg = '';
      }
    }
  }

  daytimeTelephoneNumberValidation(): void {
    if (
      this.daytimeTelephoneNumber === '' ||
      this.daytimeTelephoneNumber === null ||
      this.daytimeTelephoneNumber === undefined
    ) {
      this.daytimeTelephoneNumberError = true;
      this.daytimeTelephoneNumberErrorMsg =
        'Please Enter Daytime Telephone Number	';
    } else {
      if (!this.number.test(this.daytimeTelephoneNumber)) {
        this.daytimeTelephoneNumberError = true;
        this.daytimeTelephoneNumberErrorMsg =
          'Enter Valid Daytime Telephone Number';
      } else {
        this.daytimeTelephoneNumberError = false;
        this.daytimeTelephoneNumberErrorMsg = '';
      }
    }
  }

  emailAddressValidation(): void {
    if (
      this.emailAddress === '' ||
      this.emailAddress === null ||
      this.emailAddress === undefined
    ) {
      this.emailAddressError = true;
      this.emailAddressErrorMsg = 'Please Enter Email Address';
    } else {
      if (!this.regexpEmail.test(this.emailAddress)) {
        this.emailAddressError = true;
        this.emailAddressErrorMsg = 'Enter Valid Email Address';
      } else {
        this.emailAddressError = false;
        this.emailAddressErrorMsg = '';
      }
    }
  }

  faxNumberValidation(): void {
    if (
        this.faxNumber !== undefined &&
        this.faxNumber !== null &&
        this.faxNumber !== '' &&
        !this.number.test(this.faxNumber)
    ) {
        this.faxNumberError = true;
        this.faxNumberErrorMsg = 'Enter Valid Fax number';
      } else {
        this.faxNumberError = false;
        this.faxNumberErrorMsg = '';
      }
  }

  licensingAuthorityValidation(): void {
    if (
      this.licensingAuthority === '' ||
      this.licensingAuthority === null ||
      this.licensingAuthority === undefined
    ) {
      this.licensingAuthorityError = true;
      this.licensingAuthorityErrorMsg = 'Please Enter Licensing Authority';
    } else {
      if (!this.letterNumSpacesHypen.test(this.licensingAuthority)) {
        this.licensingAuthorityError = true;
        this.licensingAuthorityErrorMsg = 'Enter Valid Licensing Authority';
      } else {
        this.licensingAuthorityError = false;
        this.licensingAuthorityErrorMsg = '';
      }
    }
  }
  barNumberValidation(): void {
    if (
      this.barNumber === '' ||
      this.barNumber === null ||
      this.barNumber === undefined
    ) {
      this.barNumberError = true;
      this.barNumberErrorMsg = 'Please Enter Bar Number';
    } else {
      if (!this.numLetter.test(this.barNumber)) {
        this.barNumberError = true;
        this.barNumberErrorMsg = 'Enter Valid Bar Number';
      } else {
        this.barNumberError = false;
        this.barNumberErrorMsg = '';
      }
    }
  }

  lawFirmNameValidation(): void {
    if (
      this.lawFirmName === '' ||
      this.lawFirmName === null ||
      this.lawFirmName === undefined
    ) {
      this.lawFirmNameError = true;
      this.lawFirmNameErrorMsg = 'Please Enter Name of Law Firm';
    } else {
      if (!this.letterSpace.test(this.lawFirmName)) {
        this.lawFirmNameError = true;
        this.lawFirmNameErrorMsg = 'Enter Valid Name of Law Firm';
      } else {
        this.lawFirmNameError = false;
        this.lawFirmNameErrorMsg = '';
      }
    }
  }

  organizationValidation(): void {
    if (
      this.organizationCode === '' ||
      this.organizationCode === null ||
      this.organizationCode === undefined
    ) {
      this.organizationErr = true;
      this.organizationErrorMsg = 'Please Enter Organization';
    } else {
      this.organizationErr = false;
      this.organizationErrorMsg = '';
    }
  }
}
