import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

// This only accepts the Date object specifically for now, not strings
// An invalid date would be a problem, but we are not allowing manually entered dates for now so it should be fine
export function minimumDateValidator(minDate: Date): ValidatorFn {
    return function validate(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }

        return control.value.getTime() >= minDate.getTime()
            ? null
            : {
                  minimumDate: minDate
              };
    };
}

export function maximumDateValidator(maxDate: Date): ValidatorFn {
    return function validate(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }

        return control.value.getTime() <= maxDate.getTime()
            ? null
            : {
                  maximumDate: maxDate
              };
    };
}
