import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { endpointUrls } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

@Injectable({
  providedIn: 'root',
})
export class GeneratedFormsDetailsService {
  private generateForms: any[];
  private generatedDocumentsList: {
    [key: string]: {
      dependentType: string;
      formFileName: string;
      formFileUri: string;
    }[];
  };
  private generateFormsUpdated = new Subject<any>();
  private downloadFormsPdf = new Subject<any>();
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router
  ) {}

  generatedForms(): Observable<any> {
    return this.generateFormsUpdated.asObservable();
  }

  downloadDependentForms(): Observable<any> {
    return this.downloadFormsPdf.asObservable();
  }

  getGeneratedDocumentsList(): {
    [key: string]: {
      dependentType: string;
      formFileName: string;
      formFileUri: string;
    }[];
  } {
    return this.generatedDocumentsList;
  }

  private groupDocumentsByProperty(
    generatedDocsList,
    property
  ): {
    [key: string]: {
      dependentType: string;
      formFileName: string;
      formFileUri: string;
    }[];
  } {
    const groupedDocumentList = {};
    for (let i = 0; i < generatedDocsList.length; i++) {
      const key = generatedDocsList[i][property];
      if (!groupedDocumentList[key]) {
        groupedDocumentList[key] = [];
      }
      groupedDocumentList[key].push(generatedDocsList[i]);
    }
    return groupedDocumentList;
  }

  getgenerateForms(): void {
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const GetgeneratedFormsURL = endpointUrls.GetGeneratedFormsDetails;
    const GetgeneratedFormsEndpoint = backendURL + GetgeneratedFormsURL;

    this.http.get<any[]>(GetgeneratedFormsEndpoint).subscribe(
      (response) => {
        this.generateForms = response;
        this.generatedDocumentsList = this.groupDocumentsByProperty(
          [...this.generateForms],
          'dependentType'
        );
        console.log('this.generatedDocumentsList', this.generatedDocumentsList);
        this.generateFormsUpdated.next([...this.generateForms]);
      },
      (error) => {
        this.generateFormsUpdated.next([]);
      }
    );
  }

  uploadGenerateForms(file, values, fileName): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
      const backendURL = executionEnvData.backendURL;
      let params = '';
      let fileUploadURL = '';
      let fileUploadEndpoint = '';

      const uploadCalls = [];

      const fileUploadData = new FormData();
      params = '?familydependentName=' + values;
      fileUploadURL = endpointUrls.generateFormupload + params;
      fileUploadEndpoint = backendURL + fileUploadURL;

      fileUploadData.append(values, file, fileName);
      uploadCalls.push(this.http.post(fileUploadEndpoint, fileUploadData));

      console.log('Starting upload....');
      forkJoin(uploadCalls).subscribe(
        (response) => {
          console.log('All files uploaded successfully');

          resolve();
        },
        (error) => {
          console.log('Upload failed', error);
          reject();
        }
      );
    });
    return promise;
  }

  downloadForms(dependent): void {
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;

    const DownloadFormsURL = endpointUrls.DownloadFormspdf;
    const DownlaodFormsEndpoint = backendURL + DownloadFormsURL;
    this.http
      .post<any[]>(DownlaodFormsEndpoint, dependent, {
        responseType: 'blob' as 'json',
      })
      .subscribe(
        (response) => {
          const data = {
            file: response,
            fileName: dependent.formName,
          };
          this.downloadFormsPdf.next(data);
        },
        (error) => {
          console.log('ERROR when retrieving all users', error);
        }
      );
  }

  submitToPwC(): Observable<any> {
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const submitURL = endpointUrls.submitToPwC;
    const submitEndpoint = backendURL + submitURL;
    return this.http.post(submitEndpoint, null);
  }
}
