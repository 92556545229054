import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject, Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { endpointUrls } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckStatusService {

  addSuccess = new Subject<any>();
  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) { }

  getSuccess(): Observable<any> {
    return this.addSuccess.asObservable();
  }

  sendStatus(emailId , status): void {
    console.log(emailId, status);
    const params='?emailId='+emailId+'&status='+status;
    //const params = '?emailId=' + emailId;
    //const params1 = '?status=' + status;
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const updateStatusURL = endpointUrls.statusChange ;
    //+ params + params1;
    const updateStatusEndpoint = backendURL + updateStatusURL + params;
    this.http.post(updateStatusEndpoint , null ).subscribe(
      (response) => {
        console.log(response);
          this.addSuccess.next(response);
      },
      (error) => {
        console.log('ERROR To Send Status', error);
      }
    );
    // this.router.navigate(['/manageUsers']);
  }
}
