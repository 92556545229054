<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="a-panel main-panel">
  <p-tabView [(activeIndex)]="selectedTabIndex">
    <p-tabPanel [header]="!isSubmitted ? 'Form' : 'Form (Submitted)'">
      <multi-step-form
        [initialData]="initialData"
        [questionSpecs]="questionnaire"
        (instance)="getInstance($event)"
        (onSubmit)="formSubmitted()"
        (onProceedToAttachments)="proceedToAttachments()"
        *ngIf="questionnaire && initialDataQueried"
        class="dform-container"
      ></multi-step-form>
    </p-tabPanel>
    <p-tabPanel header="Attachments" [disabled]="attachmentTab">
      <app-file-upload [isCompleted]="isCompleted" [isDraft]="isDraft"></app-file-upload>
    </p-tabPanel>
    <p-tabPanel header="Generated Forms" *ngIf="generatetabs">
      <app-generate-tabs > </app-generate-tabs>
    </p-tabPanel>
  </p-tabView>
</div>
