import { Injectable } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    private toast = new Subject<any>();

    constructor() {}

    toastMsg(): Observable<any> {
        return this.toast.asObservable();
    }

    getToastMsg(msg): void {
        this.toast.next(msg);
    }
}
