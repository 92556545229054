<div *ngIf="isLoading" class="overlay">
  <app-loader></app-loader>
</div>

<div id="myHeader">
  <h3>
    Questionnaire
    <span *ngIf="isSubmitted"> (Submitted)</span>
    <ap-button
      *ngIf="!isSubmitted"
      class="a-ml-20"
      btnText="Save Draft"
      [isDisabled]="
        currentInstance.blockingErrorQuestions.length > 0 ||
        currentFormGroup.pristine
      "
      (onBtnClick)="saveDraft()"
      [config]="{ isPrimary: 'true' }"
    >
    </ap-button>
    <ap-button
      *ngIf="!isSubmitted"
      class="a-ml-20"
      btnText="Discard Draft"
      (onBtnClick)="showDiscardConfirmation('show-custom-modal-draft', false)"
      [config]="{ isSecondary: 'true' }"
    ></ap-button>
  </h3>
</div>

<div class="row a-mt-20">
  <div class="col-3">
    <form-navigation
      [isSubmitted]="isSubmitted"
      [formInstances]="formInstances"
      [currentInstance]="currentInstance"
      [(currentSectionIndex)]="currentInstance.currentSectionIndex"
      (deleteInstance)="deleteInstance($event)"
      (setFormInstance)="setFormInstance($event)"
    >
    </form-navigation>
  </div>

  <div class="col-9">
    <div class="a-panel">
      <div class="a-panel-header">
        <h4>
          {{
            currentInstance.formSections[currentInstance.currentSectionIndex]
              .sectionId
          }}
          -
          {{
            currentInstance.formSections[currentInstance.currentSectionIndex]
              .sectionName
          }}
        </h4>
      </div>

      <form [formGroup]="currentFormGroup">
        <!--Uncomment for debugging purposes only - to get a list of form errors -->
        <!--<button (click)="errorCheck()">Error Check</button>-->
        <div class="d-flex justify-content-between a-mt-20 align-items-center">
          <ap-button
            class="a-mr-10"
            btnText="Previous"
            *ngIf="currentInstance.currentSectionIndex != 0"
            (onBtnClick)="advanceSection(false)"
            [config]="{ isPrimary: 'true' }"
            [isDisabled]="currentFormGroup.invalid"
          ></ap-button>
          <ap-button
            class="a-mr-10"
            [btnText]="isSubmitted ? 'Next' : 'Save & Next'"
            *ngIf="
              currentInstance.currentSectionIndex !=
              currentInstance.formSections.length - 1
            "
            [isDisabled]="currentFormGroup.invalid"
            (onBtnClick)="advanceSection(true)"
            [config]="{ isPrimary: 'true' }"
          ></ap-button>
        </div>

        <div class="text-left">
          <form-sub-section
            *ngFor="
              let subSection of currentInstance.formSections[
                currentInstance.currentSectionIndex
              ].formSubSection;
              let i = index
            "
            [subSection]="subSection"
            [sectionIndex]="i"
            [formGroup]="currentFormGroup"
            [parentInstance]="currentInstance"
          >
          </form-sub-section>
        </div>
        <div class="d-flex justify-content-between a-mt-20 align-items-center">
          <ap-button
            class="a-mr-10"
            btnText="Previous"
            *ngIf="currentInstance.currentSectionIndex != 0"
            (onBtnClick)="advanceSection(false)"
            [config]="{ isPrimary: 'true' }"
            [isDisabled]="currentFormGroup.invalid"
          ></ap-button>
          <ap-button
            class="a-mr-10"
            [btnText]="isSubmitted ? 'Next' : 'Save & Next'"
            *ngIf="
              currentInstance.currentSectionIndex !=
              currentInstance.formSections.length - 1
            "
            [isDisabled]="currentFormGroup.invalid"
            [config]="{ isPrimary: 'true' }"
            (onBtnClick)="advanceSection(true)"
          ></ap-button>
        </div>

        <div class="d-flex flex-column align">
          <div class="p-2">
            <ap-button
            tooltipPosition="top"
            pTooltip="Click only after completing questionnaires for all dependents that will be applying with you"
            btnText="No Additional Dependents - Proceed to Attachments"
            *ngIf="isLastSectionLastInstance() && !isSubmitted"
            (onBtnClick)="showDiscardConfirmation('show-custom-modal-draft', true)"
            [config]="{ isPrimary: 'true' }"
            [isDisabled]="currentFormGroup.invalid"
            >
          </ap-button>
          </div>
          <div class="p-2">
            <ap-button
              btnText="Add Additional Dependents - Proceed to Dependent Questionnaire"
              *ngIf="isLastSectionLastInstance() && !isSubmitted"
              [config]="{ isPrimary: 'true' }"
              (onBtnClick)="createNewFormInstance()"
              [isDisabled]="currentFormGroup.invalid"
            >
            </ap-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<ap-modal
  #baseModalCustom
  appModalId="show-custom-modal-draft"
  style="z-index: 999999"
>
  <div
    class="modal-dialog a-modal-lg modal-dialog-centered modal-sm"
    role="document"
  >
    <div class="modal-content">
      <div class="a-modal-header">
        <div class="flex-fill d-flex align-items-center">
          <div *ngIf="!proceedToAttachments" class="a-h4">Confirmation</div>
          <div *ngIf="proceedToAttachments" class="a-h4">WARNING</div>
        </div>

        <button
          aria-label="Close"
          class="icon-wrapper"
          data-dismiss="modal"
          (click)="closeModalCustom('show-custom-modal-draft')"
        >
          <span class="appkiticon icon-close-fill a-close-btn"></span>
        </button>
      </div>
      <div class="a-modal-body pt-0">
        <div
          *ngIf="!proceedToAttachments"
          class="a-p4 a-mt-10 text-description"
        >
          <span class="appkiticon icon-alert-outline"></span> &nbsp; Are you
          sure you want to discard the draft ?
        </div>
        <div *ngIf="proceedToAttachments" class="a-p4 a-mt-10 text-description">
          <span class="appkiticon icon-alert-outline"></span> &nbsp; If you have
          derivatives (ie, spouse, children, etc), you must complete a
          questionnaire for each of them by clicking on the
          <b>Add Additional Dependents - Proceed to Dependent Questionnaire</b> button.<br /><br />
          Have you completed questionnaires for each derivative applicant
          applying with you?
        </div>
      </div>
      <div class="a-modal-footer a-border-tp">
        <div class="flex-fill text-left a-font-12 a-text-grey-medium"></div>
        <button
          aria-label="Close"
          class="a-btn a-btn-transparent a-btn-lg a-btn-gray a-mr-10"
          data-dismiss="modal"
          (click)="closeModalCustom('show-custom-modal-draft')"
        >
          NO
        </button>
        <button
          *ngIf="!proceedToAttachments"
          aria-label="Close"
          class="a-btn a-btn-transparent a-btn-lg"
          data-dismiss="modal"
          (click)="discardDraft('show-custom-modal-draft')"
        >
          YES
        </button>
        <button
          *ngIf="proceedToAttachments"
          aria-label="Close"
          class="a-btn a-btn-transparent a-btn-lg"
          data-dismiss="modal"
          (click)="onSaveAndProceedToAttachments('show-custom-modal-draft')"
        >
          YES
        </button>
      </div>
    </div>
  </div>
</ap-modal>
