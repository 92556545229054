import { NgModule } from '@angular/core';

import { FooterModule } from 'appkit-angular/footer';
import { TabModule } from 'appkit-angular/tab';
import { FormFieldModule } from 'appkit-angular/form-field';
import { ButtonModule } from 'appkit-angular/button';
import { ResponsiveTabModule } from 'appkit-angular/responsive-tab';
import { PanelsCardsModule } from 'appkit-angular/panels-cards';
import { TableModule } from 'appkit-angular/table';
import { SearchModule } from 'appkit-angular/search';
import { PaginationsModule } from 'appkit-angular/paginations';
import { SelectorModule } from 'appkit-angular/selector';
import { CheckboxModule } from 'appkit-angular/checkbox';
import { DatePickerModule } from 'appkit-angular/date-picker';
import { LoadingModule } from 'appkit-angular/loading';
import { ModalModule } from 'appkit-angular/modal';
import { ListModule } from 'appkit-angular/list';
import { ToggleModule } from 'appkit-angular/toggle';
import { BadgeModule } from 'appkit-angular/badge';
import { RadiobuttonModule } from 'appkit-angular/radiobutton';

// Created this module only to separate out the appkit modules into a separate file
// to make app.module.ts file appear clean
@NgModule({
  exports: [
    ToggleModule,
    ListModule,
    FooterModule,
    TabModule,
    FormFieldModule,
    ButtonModule,
    ResponsiveTabModule,
    PanelsCardsModule,
    SelectorModule,
    TableModule,
    SearchModule,
    PaginationsModule,
    CheckboxModule,
    DatePickerModule,
    ModalModule,
    LoadingModule,
    BadgeModule,
    RadiobuttonModule
  ]
})
export class AppkitModule { }
