import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public footerContent =
    '© 2020 PwC Law LLP. All rights reserved. A law firm affiliated with PricewaterhouseCoopers LLP, an Ontario limited liability partnership.';
  public supportContent =
    'For any IT related issues, please contact ca_vialto_aos_support@vialto.com. For any legal questions or questions about the status of your case, please follow the instructions in your initiation email.';
  constructor() {}

  ngOnInit(): void {}
}
