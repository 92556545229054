import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { interval, Subscription, timer } from 'rxjs';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AuthService } from './services/auth.service';
import { timeoutSetting, environment } from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'webapp-aos';
  isUserAuthenticated = false;
  private authenticationSubscription: Subscription;
  private refreshTokenSubscription: Subscription;
  private validSessionSubscription: Subscription;

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  showoverlay: boolean;
  isLoading: boolean;
  intervalId = 0;
  seconds = 15;
  executionEnvironment ='local';
  oldURL='';

  @ViewChild('childModal', { static: false }) childModal: any;
  @ViewChild('baseModalLogoutCustom', { static: false }) baseModalLogoutCustom: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private idle: Idle,
    private keepalive: Keepalive
  ) {
    if (environment.local.authConfig.redirectURL.includes(window.location.origin) ||environment.local.oldURL.includes(window.location.origin)) {
      this.executionEnvironment = 'local';
    } else if (
      environment.dev.authConfig.redirectURL.includes(window.location.origin)||environment.dev.oldURL.includes(window.location.origin)
    ) {
      this.executionEnvironment = 'dev';
    } else if (
       environment.qa.authConfig.redirectURL.includes(window.location.origin)||environment.qa.oldURL.includes(window.location.origin)
    ) {
      this.executionEnvironment = 'qa';
    } else if (
     environment.stage.authConfig.redirectURL.includes(window.location.origin)||environment.stage.oldURL.includes(window.location.origin)
    ) {
      this.executionEnvironment = 'stage';
    } else if (
     environment.prod.authConfig.redirectURL.includes(window.location.origin)|| environment.prod.oldURL.includes(window.location.origin)
    ) {
      this.executionEnvironment = 'prod';
    }
    this.oldURL=environment[this.executionEnvironment].oldURL;
    
    idle.setIdle(timeoutSetting.warnAfterIdleTime); // sets an idle timeout
    idle.setTimeout(timeoutSetting.logoutAfterWarnTime); // sets a timeout period after user goes inactive
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // sets the default interrupts, in this case, things like clicks, scrolls, touches.

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      console.log(this.idleState);
      this.reset();
      this.onStay('modal-timeout');
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      this.onLogout('modal-timeout');
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      console.log(this.idleState);
      this.showChildModal('modal-timeout');
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      const minutes = parseInt((countdown / 60).toString(), 10);
      const seconds = parseInt((countdown % 60).toString(), 10);
      const minutesDisplay = minutes < 10 ? '0' + minutes : minutes;
      const secondsDisplay = seconds < 10 ? '0' + seconds : seconds;
      const timeDisplay = minutesDisplay + ':' + secondsDisplay;
      this.idleState =
        'You will be logged out automatically in ' + timeDisplay + ' minutes!';
      console.log(this.idleState);
    });

    keepalive.interval(15); // sets the ping interval to 15 seconds
    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
  }

  reset(): void {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  showChildModal(id: string): void {
    this.childModal.showMore(id);
  }

  hideChildModal(id: string): void {
    this.childModal.closeModal(id);
  }

  onStay(id: string): void {
    this.hideChildModal(id);
    this.reset();
  }

  onLogout(id: string): void {
    this.hideChildModal(id);
    this.authService.logout('timeout');
  }

  ngOnInit(): void {
    console.log("execution environment: ",this.executionEnvironment);
    console.log("window location origin :",window.location.origin);
    console.log("oldURL :",this.oldURL);
    if(window.location.origin.includes(this.oldURL)){
     // window.history.replaceState(null, null, environment[this.executionEnvironment].authConfig.redirectURL);
      //console.log("after replace state");
      //window.history.pushState(null, '', environment[this.executionEnvironment].authConfig.redirectURL); 
     // console.log("after push state"); 
     console.log("Redirecting to new Url");
      window.location.href=environment[this.executionEnvironment].authConfig.redirectURL;
      
    }
    this.isUserAuthenticated = this.authService.getIsAuthenticated();
    console.log('Is Authenticated', this.isUserAuthenticated);
    this.authenticationSubscription = this.authService
      .getAuthStatusListener()
      .subscribe((isAuthenticated) => {
        console.log('Is Authenticated Listener', isAuthenticated);
        this.isUserAuthenticated = isAuthenticated;
        if (this.isUserAuthenticated) {
          console.log('Logged in, watching for inactivity...');
          this.idle.watch();
          this.timedOut = false;

          console.log('Authenticated. Starting refresh token timer...');
          const expiresAt = Date.parse(localStorage.getItem('expiresAt'));
          const currentTime = new Date().getTime();
          const refreshInMillis = expiresAt - 5 * 60000 - currentTime;
          if (refreshInMillis > 0) {
            console.log(
              'Refresh token after minutes',
              refreshInMillis / (1000 * 60)
            );
            this.refreshTokenSubscription = timer(
              refreshInMillis,
              refreshInMillis
            ).subscribe((result) => {
              console.log('Refreshing token...');
              this.authService.refreshToken();
            });
          } else {
            console.log('Token already expired. Logging out');
            this.authService.logout('timeout');
          }
          const source = interval(10000);
          this.validSessionSubscription = source.subscribe(() =>
            this.authService.validSession().subscribe(
              (response) => {
                if (!response) {
                  this.countDown();
                  this.showoverlay = true;
                  console.log('valid');
                  this.showBaseModal('show-custom-logout-modal');
                }
              },
              (error) => {
                console.log('Invalid');
              }
            )
          );
        } else {
          console.log('Not logged in, not watching for inactivity...');
          this.idle.stop();
          this.refreshTokenSubscription.unsubscribe();
        }
      });

    // Check if session exists
    if (
      sessionStorage.getItem('idam') === null ||
      sessionStorage.getItem('idam') === undefined
    ) {
      if (window.location.hash.startsWith('#access_token')) { // IdAM
        const params = window.location.hash.slice(1);
        const idam = JSON.parse(
          '{"' +
            decodeURI(params)
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}'
        );
        sessionStorage.setItem('idam', JSON.stringify(idam));
        const token = JSON.parse(sessionStorage.getItem('idam')).id_token;
        sessionStorage.setItem('id_token', token);
        this.authService.authenticate();
      } else if (window.location.href.includes('code')) { // OpenAM
        const urlParams = new URLSearchParams(window.location.search);
        const authCode = urlParams.get('code');
        if (authCode) {
          this.authService
            .exchangeCodeWithToken(authCode)
            .subscribe((response) => {
              if (response) {
                sessionStorage.setItem('idam', JSON.stringify(response));
                const token = JSON.parse(sessionStorage.getItem('idam'))
                  .id_token;
                sessionStorage.setItem('id_token', token);
                this.authService.authenticate();
              } else {
                this.router.navigate(['/login']);
              }
            });
        } else {
          this.router.navigate(['/login']);
        }
      } else {
        this.router.navigate(['/login']);
      }
    } else {
      this.authService.authenticate();
    }
  }

  clearTimer(): void {
    clearInterval(this.intervalId);
  }

  countDown(): void {
    this.clearTimer();
    this.intervalId = window.setInterval(() => {
      this.seconds -= 1;
      if (this.seconds === 0) {
        this.isLoading = true;
        this.showoverlay = false;
        this.hideBaseModal('show-custom-logout-modal');
        this.authService.closeSession();
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    this.authenticationSubscription.unsubscribe();
    this.refreshTokenSubscription.unsubscribe();
    this.validSessionSubscription.unsubscribe();
  }

  showBaseModal(id: string): void {
    this.baseModalLogoutCustom.showMore(id);
  }

  hideBaseModal(id: string): void {
    this.baseModalLogoutCustom.closeModal(id);
  }
}
