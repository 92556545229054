import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { flatMap, takeUntil } from 'rxjs/operators';
import { endpointUrls } from 'src/environments/environment';
import { AosHttpService } from './aos-http-service.service';
import { checkboxSetRequiredValidator } from '../checkboxSetRequiredValidator';
import { minimumDateValidator, maximumDateValidator } from '../dateValidators';
import { FieldErrorsComponent } from '../forms/field-errors/field-errors.component';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private instanceListener = new Subject<any>();
  private fieldChange = new Subject<any>();
  private formInstances = [];
  private formInstancesForSubmission = [];
  name = [];
  nameListener = new Subject<any>();
  constructor(private httpService: AosHttpService) {}

  getQuestions(): Observable<any> {
    return this.httpService.get(endpointUrls.getQuestionnaires);
  }

  submitForm(
    formInstances: FormInstance[],
    selectedInstance = null
  ): Observable<any> {
    const submission = this.readFormSubmissionData(
      formInstances,
      selectedInstance,
      true
    );
    return this.httpService.post(endpointUrls.submitResponse, submission);
  }

  saveDraft(
    formInstances: FormInstance[],
    selectedInstance = null
  ): Observable<any> {
    const submission = this.readFormSubmissionData(
      formInstances,
      selectedInstance,
      false
    );
    return this.httpService.post(endpointUrls.saveResponse, submission);
  }

  //Flatten form data from sections and groups into a simple list for the server
  private readFormSubmissionData(
    formInstances: FormInstance[],
    selectedInstance: string,
    isFinal: boolean
  ): FormSubmission {
    const submission: FormSubmission = {
      questionnaireResponse: [],
      isFinalSubmission: isFinal,
    };

    for (const instance of formInstances) {
      const submissionInstance: FormSubmissionInstance = {
        instanceName: instance.name,
        // This will be the same for every instance. Ideally it would be in FormSubmission but time is limited and this still works
        selectedInstanceName: selectedInstance,
        listOfAnswers: [],
        lastSelectedSection: null,
        lastCompletedSection: null,
      };

      if (!isFinal) {
        const completedSections = Object.keys(
          instance.sectionCompletion
        ).filter((x) => instance.sectionCompletion[x]);
        if (completedSections.length > 0) {
          submissionInstance.lastCompletedSection = parseInt(
            completedSections[completedSections.length - 1],
            10
          );
        }

        submissionInstance.lastSelectedSection =
          instance.formSections[instance.currentSectionIndex].sectionId;
      }

      for (const section of instance.formSections) {
        for (const subSection of section.formSubSection) {
          for (const field of subSection.formFieldSpecification) {
            const control =
              instance.sectionFormGroups[section.sectionId].controls[
                this.getControlName(field)
              ];
            const answer = this.readFieldAnswer(field, control);
            submissionInstance.listOfAnswers.push(answer);
          }
        }
      }

      submission.questionnaireResponse.push(submissionInstance);
    }

    return submission;
  }

  //format value from control to submission-ready
  private readFieldAnswer(
    field: FormFieldSpec,
    control: AbstractControl
  ): FormAnswer {
    const fieldType = field.type.toLowerCase();
    const answer: FormAnswer = new FormAnswer();
    answer.questionId = field.questionId;
    answer.optionId = 0;
    answer.responseTextValue = '';

    if (
      fieldType === FormFieldType.radio ||
      fieldType === FormFieldType.dropdown
    ) {
      answer.optionId = !!control.value ? parseInt(control.value, 10) : 0;
    } else if (fieldType === FormFieldType.checkboxSet) {
      const selectedOptionIds: number[] = [];
      for (const option of field.questionOptions) {
        if (
          (control as FormGroup).controls[this.getOptionName(option)].value ===
          true
        ) {
          selectedOptionIds.push(option.subOptionId);
        }
      }
      answer.responseTextValue = selectedOptionIds.join(',');
    } else if (fieldType !== FormFieldType.label) {
      answer.responseTextValue = control.value;
    }

    return answer;
  }

  discardDraft(): Observable<any> {
    return this.httpService.post(endpointUrls.deleteDraftResponse, null);
  }

  getFormSubmission(): Observable<any> {
    return this.httpService.get(endpointUrls.getSubmittedResponse);
  }

  getControlName(field: FormFieldSpec): string {
    return 'control' + field.questionId;
  }

  getControlNameById(questionId: number): string {
    return 'control' + questionId;
  }

  getOptionName(option: FormQuestionOption): string {
    return 'option' + option.subOptionId;
  }

  getValidators(fieldSpec: FormFieldSpec): ValidatorFn[] {
    const validators = [];
    const fieldType = fieldSpec.type.toLowerCase();

    if (fieldSpec.isRequired) {
      let reqValidator =
        fieldType === FormFieldType.checkboxSet
          ? checkboxSetRequiredValidator()
          : Validators.required;

      validators.push(reqValidator);
    }
    if (fieldSpec.regularExpression && fieldType === FormFieldType.text) {
      let regEx = fieldSpec.regularExpression;
      //Remove / / from beginning and end if needed
      if (regEx.charAt(0) === '/') {
        regEx = regEx.substring(1);
      }
      if (regEx.charAt(regEx.length - 1) === '/') {
        regEx = regEx.substring(0, regEx.length - 1);
      }
      validators.push(Validators.pattern(regEx));
    }
    if (fieldType === FormFieldType.date) {
      if (fieldSpec.minDate_value) {
        validators.push(minimumDateValidator(fieldSpec.minDate_value));
      }
      if (fieldSpec.maxDate_value) {
        validators.push(maximumDateValidator(fieldSpec.maxDate_value));
      }
    }

    return validators;
  }

  getInstanceListener(): Observable<any> {
    return this.instanceListener.asObservable();
  }

  setInstances(formInstances: FormInstance[]): void {
    this.formInstances = [...formInstances];
    this.instanceListener.next(this.formInstances);
  }

  getInstances(): FormInstance[] {
    return this.formInstances;
  }

  setName(name): void {
    this.name = name;
    this.nameListener.next(this.name);
  }

  getName() {
    return this.name;
  }

  getNameListener(): Observable<any> {
    return this.nameListener.asObservable();
  }

  setFormInstancesForSubmission(formInstances: FormInstance[]): void {
    this.formInstancesForSubmission = formInstances;
  }

  submitFormInstances(): Observable<any> {
    // TODO: Would be nice to remember currently selected instance on
    // this form submission, but that would take some additional data wiring
    const submission = this.readFormSubmissionData(
      this.formInstancesForSubmission,
      this.formInstancesForSubmission[0].name,
      true
    );
    return this.httpService.post(endpointUrls.submitResponse, submission);
  }

  //Check if controlValue equals controlValue or is contained in controlValue as a comma-separated list
  isMatchToggleValue(toggleValue: string, controlValue): boolean {
    let strControlVal = controlValue.toString();
    if (!strControlVal) return false;
    let splitToggleVal = toggleValue.split(',');

    return splitToggleVal.indexOf(strControlVal) != -1;
  }

  copyAddress() {
    let control29: any;
    let control30: any;
    let control31: any;
    let control32: any;
    let control33: any;
    let control34: any;
    const instances = this.getInstances();
    for (const instance of instances) {
      if (instance.name === 'Primary') {
        for (const section of instance.formSections) {
          const formGroup = instance.sectionFormGroups[section.sectionId];
          if (
            formGroup.controls['control29'] &&
            formGroup.controls['control29'].value
          ) {
            control29 = formGroup.controls['control29'].value;
          }
          if (
            formGroup.controls['control30'] &&
            formGroup.controls['control30'].value
          ) {
            control30 = formGroup.controls['control30'].value;
          }
          if (
            formGroup.controls['control31'] &&
            formGroup.controls['control31'].value
          ) {
            control31 = formGroup.controls['control31'].value;
          }
          if (
            formGroup.controls['control32'] &&
            formGroup.controls['control32'].value
          ) {
            control32 = formGroup.controls['control32'].value;
          }
          if (
            formGroup.controls['control33'] &&
            formGroup.controls['control33'].value
          ) {
            control33 = formGroup.controls['control33'].value;
          }
          if (
            formGroup.controls['control34'] &&
            formGroup.controls['control34'].value
          ) {
            control34 = formGroup.controls['control34'].value;
          }
        }
      } else {
        for (const section of instance.formSections) {
          const formGroup = instance.sectionFormGroups[section.sectionId];
          if (formGroup.controls['control29']) {
            formGroup.controls['control29'].setValue(control29);
          }
          if (formGroup.controls['control30']) {
            formGroup.controls['control30'].setValue(control30);
          }
          if (formGroup.controls['control31']) {
            formGroup.controls['control31'].setValue(control31);
          }
          if (formGroup.controls['control32']) {
            formGroup.controls['control32'].setValue(control32);
          }
          if (formGroup.controls['control34']) {
            formGroup.controls['control34'].setValue(control34);
          }
          let flag: boolean;
          for (const subSection of section.formSubSection) {
            for (const value of subSection.formFieldSpecification) {
              if (formGroup.controls['control33'] && value.questionId === 33) {
                flag = true;
                this.setField(control33);
              }
              if (flag) {
                break;
              }
            }
            if (flag) {
              break;
            }
          }
        }
      }
    }
    this.setInstances(instances);
  }

  getFieldListener(): Observable<any> {
    return this.fieldChange.asObservable();
  }

  setField(field520): void {
    this.fieldChange.next(field520);
  }
}

export class FormSubmission {
  questionnaireResponse: FormSubmissionInstance[];
  isFinalSubmission: boolean;
}

export class FormSubmissionInstance {
  instanceName: string;
  /* Note: ideally this would be in FormSubmission. It will be the same for every FormSubmissionInstance
      There was a miscommunication with server late in the sprint and it was most efficient to go ahead with duplicate
     data across the instances. */
  selectedInstanceName: string;
  listOfAnswers: FormAnswer[];
  lastSelectedSection: number = null;
  lastCompletedSection: number = null;
}

export class FormAnswer {
  questionId: number;
  optionId: number;
  responseTextValue: string;
}

export class FormSection {
  sectionId: number;
  sectionName: string;
  formSubSection: FormSubSection[];
}

export class FormInstance {
  name: string;
  formSections: Array<FormSection>;
  currentSectionIndex: number;
  isDependent: boolean;
  blockingErrorQuestions: number[] = [];

  sectionCompletion: Record<number, boolean>;
  sectionFormGroups: Record<number, FormGroup>;
}

export class FormSubSection {
  subSectionName: string;
  formFieldSpecification: Array<FormFieldSpec>;
  subSectionId: number;
  subSectionStatus: string;
}

export class FormControlBatch {
  fields: FormFieldSpec[];
  batchId: number;
  isHidden: boolean;
}

// all lowercase
export enum FormFieldType {
  label = 'label',
  checkbox = 'checkbox',
  text = 'textbox',
  radio = 'radiobutton',
  date = 'datepicker',
  dropdown = 'dropdown',
  checkboxSet = 'checkboxset',
}

export class FormFieldSpec {
  questionId: number;
  name: string;
  questionText: string;
  placeholder: string;
  type: string;
  defaultValue: any;
  questionOptions: Array<FormQuestionOption>;
  regularExpression: string;
  validationMessage: string;
  isRequired: boolean;
  disabled: boolean;
  toggleQuestionId: number;
  toggleQuestionValue: string;

  minimumReferenceFieldName: string;

  alwaysDisabled: boolean;

  isHidden: boolean;

  batchId: number;
  sequenceId: number;

  // Server settings - either empty, "today", or the ID of the date control to set it from
  minDate: string;
  maxDate: string;

  // Actual date values
  minDate_value: Date;
  maxDate_value: Date;

  // client only
  isToggledOn = true;

  constructor() {}
}

export class FormQuestionOption {
  optionId: number;
  subOptionId: number;
  optionValue: string;
  optionName: string;
}

export const TODAY = 'today';
