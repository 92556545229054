import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  FormInstance,
  FormSection,
  FormService,
} from 'src/app/services/form-service.service';
import { of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'form-navigation',
  templateUrl: './form-navigation.component.html',
  styleUrls: ['./form-navigation.component.scss'],
})
export class FormNavigationComponent implements OnInit, OnDestroy {
  @Input() currentSectionIndex: number;
  @Input() formInstances: Array<FormInstance>;
  @Input() currentInstance: FormInstance;
  @Input() isSubmitted: boolean;

  @Output() setFormInstance = new EventEmitter<FormInstance>();
  @Output() deleteInstance = new EventEmitter<FormInstance>();
  @Output() currentSectionIndexChange = new EventEmitter<number>();

  @ViewChild('modalConfirmDelete', { static: false }) modalConfirmDelete: any;

  instanceForDeletion: FormInstance;
  isLoading = false;
  private ngSubject: Subject<any> = new Subject<any>();

  constructor(private formService: FormService) {}

  ngOnInit(): void {}

  instanceClicked(instance, $event): void {
    // Exit out if they clicked the delete button (a-btn-negative),
    // that has its own event and should not follow the standard instance click.
    const isDeleteButton = false;
    const cssKeys = Object.keys($event.target.classList);
    if (cssKeys.find((x) => $event.target.classList[x] === 'a-btn-negative')) {
      return;
    }

    if (!this.isSubmitted && this.currentFormGroup.invalid) {
      return;
    }
    this.setFormInstance.emit(instance);
  }

  collapseInstance(instance, event) {
    if (event.collapsed) {
      this.setFormInstance.emit(instance);
    }
  }

  get currentFormGroup(): FormGroup {
    return this.currentInstance.sectionFormGroups[
      this.currentInstance.formSections[this.currentSectionIndex].sectionId
    ];
  }

  confirmInstanceDelete(instance): void {
    this.instanceForDeletion = instance;

    this.modalConfirmDelete.showMore('modal-confirm-delete');
  }

  instanceDeleteClose(ok: boolean): void {
    if (ok) {
      this.modalConfirmDelete.closeModalOk('modal-confirm-delete');

      this.deleteInstance.emit(this.instanceForDeletion);
      this.instanceForDeletion = null;
    } else {
      this.modalConfirmDelete.closeModal('modal-confirm-delete');
    }
  }

  setSection(section: FormSection): void {
    //Switching between sections from nav is only allowed when the destination section is complete and the current section is valid.
    if (
      !this.isSubmitted &&
      (!this.currentInstance.sectionCompletion[section.sectionId] ||
        this.currentFormGroup.invalid)
    ) {
      return;
    }

    const oldSectionIndex = this.currentSectionIndex;
    this.currentSectionIndex = this.currentInstance.formSections.indexOf(
      section
    );
    this.currentInstance.currentSectionIndex = this.currentSectionIndex;

    this.isLoading = true;

    const observe = !this.isSubmitted
      ? this.formService.saveDraft(
          this.formInstances,
          this.currentInstance.name
        )
      : of(null);
    observe.pipe(takeUntil(this.ngSubject)).subscribe(
      () => {
        this.isLoading = false;
        this.currentSectionIndexChange.emit(this.currentSectionIndex);
      },
      (err) => {
        this.currentSectionIndex = oldSectionIndex;
        this.currentInstance.currentSectionIndex = oldSectionIndex;
      }
    );
  }

  getSectionCss(instance: FormInstance, section: FormSection): any {
    const sectionComplete = instance.sectionCompletion[section.sectionId];

    return {
      'aos-link':
        (sectionComplete && !this.currentFormGroup.invalid) || this.isSubmitted,
      'a-progress-done': sectionComplete,
      'aos-section-valid':
        section === instance.formSections[this.currentSectionIndex] &&
        !this.currentFormGroup.invalid,
      'aos-section-invalid':
        section === instance.formSections[this.currentSectionIndex] &&
        this.currentFormGroup.invalid,
    };
  }

  ngOnDestroy(): void {
    this.ngSubject.next();
    this.ngSubject.complete();
  }
}
