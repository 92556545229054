import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: [ './toast.component.scss' ],
    providers: [ MessageService ]
})
export class ToastComponent implements OnInit, OnDestroy {
    toastSubscription: Subscription;
    constructor(private toastService: ToastService, private messageService: MessageService) {}

    ngOnInit(): void {
        this.toastSubscription = this.toastService.toastMsg().subscribe((msg) => {
            this.messageService.add({
                key: 'toast',
                severity: msg.severity,
                summary: msg.summary
            });
        });
    }

    ngOnDestroy(): void {
        this.toastSubscription.unsubscribe();
    }
}
