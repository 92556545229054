import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function checkboxSetRequiredValidator(): ValidatorFn {
    return function validate(formGroup: FormGroup): ValidationErrors | null {
        let checked = 0;

        const checkboxControlKeys = Object.keys(formGroup.controls);
        for (const key of checkboxControlKeys) {
            if (formGroup.controls[key].value.toString() === "true") {
                checked++;
            }
        }

        if (checked === 0) {
            return { checkboxSetRequired: true };
        }

        return null;
    };
}
