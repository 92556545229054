import { Component, OnInit } from '@angular/core';
import { CheckStatusService } from '../../services/check-status.service'
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { ToastService } from '../../services/toast.service';


@Component({
  selector: 'app-check-status',
  templateUrl: './check-status.component.html',
  styleUrls: ['./check-status.component.scss']
})
export class CheckStatusComponent implements OnInit {
  emailId : string;
  status : string;
  addSuccess: Subscription;
  isLoading = false;
  private ngSubject: Subject<any> = new Subject<any>();

  constructor(
    private checkStatusService : CheckStatusService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
  }

  onChange(value: any) {
    this.status = value.target.value;
   }

   submit(): void {
    console.log(this.emailId, this.status)
    this.checkStatusService.sendStatus(this.emailId ,this.status);
    this.addSuccess = this.checkStatusService
    .getSuccess()
    .pipe(takeUntil(this.ngSubject))
    .subscribe((response) => {
      console.log(response);
      if (response) {
        const msg = {
          severity: 'success',
          summary: 'Status Updated successfully',
        };
        this.toastService.getToastMsg(msg);
        this.isLoading = false;
      } else {
        const msg = {
          severity: 'error',
          summary: 'Unable Update Status',
        };
        this.toastService.getToastMsg(msg);
        this.isLoading = false;
      }
    });
  }
 

}
