<div [ngSwitch]="field.type.toLowerCase()" [formGroup]="formGroup" [hidden]="isHidden" class="aos-control-container"
        [pTooltip]="errorTooltip" tooltipPosition="top">
    <div *ngSwitchCase="formFieldTypes.text">
        <label class="a-form-label" [for]="fieldName" id="iptlabel1" [class.aos-text-error]="!!errorTooltip" tooltipStyleClass="info-txt" pTooltip="{{field.name}}" tooltipPosition="top">
            {{ field.questionText }}
            <field-required-marker [field]="field"></field-required-marker>
        </label>
        <input class="a-text-input" [id]="fieldName" [formControlName]="fieldName" [type]="'text'" [placeholder]="field.placeholder" (input)="onChange($event)"
            [ngClass]="borderClass" [disabled]="field.disabled || isHidden" (keydown)="onKeyDown(field, $event)"/>
    </div>
    <div *ngSwitchCase="formFieldTypes.radio" [class.aos-border-required-untouched]="formControl.errors?.required">
        <label class="a-form-label a-mb-10" [class.aos-text-error]="!!errorTooltip">
            {{ field.questionText }}
            <field-required-marker [field]="field"></field-required-marker>
        </label>
        <label *ngFor="let option of field.questionOptions; let i = index;" class="a-radio a-ml-20">
            <input type="radio" [value]="option.subOptionId.toString()" [formControlName]="fieldName" [disabled]="field.disabled" (change)="onChange($event)">
            <span class="a-radio-mark">
                <span class="a-radio-inner"></span>
            </span>
            <span class="a-radio-text">{{ option.optionValue }}</span>
        </label>
    </div>
    <div *ngSwitchCase="formFieldTypes.checkbox">
        <label class="a-checkbox" [class.aos-text-error]="!!errorTooltip">
            <input type="checkbox" [formControlName]="fieldName" [disabled]="field.disabled" (change)="onChange($event)">
            <span class="a-checkbox-mark aos-form-checkbox">
                <span class="appkiticon icon-check-mark-fill"></span>
            </span>
            <span class="a-checkbox-text">
                {{ field.questionText }}
                <field-required-marker [field]="field"></field-required-marker>
            </span>
        </label>
    </div>
    <div *ngSwitchCase="formFieldTypes.checkboxSet" >
        <div [formGroup]="formControl" [ngClass]="borderClass">
            <label class="a-form-label a-mb-10" [class.aos-text-danger]="!!errorTooltip">
                {{ field.questionText }}
                <field-required-marker [field]="field"></field-required-marker>
            </label>
            <label class="a-checkbox a-ml-20" *ngFor="let box of field.questionOptions; let i = index">
                <input type="checkbox" [formControlName]="'option' + box.subOptionId" [disabled]="field.disabled">
                <span class="a-checkbox-mark aos-form-checkbox">
                    <span class="appkiticon icon-check-mark-fill"></span>
                </span>
                <span class="a-checkbox-text">{{ box.optionValue }} </span>
            </label>
        </div>
    </div>
    <div *ngSwitchCase="formFieldTypes.label">
        <div class="a-mt-10">{{ field.questionText }}</div>
    </div>
    <div *ngSwitchCase="formFieldTypes.date">
        <label class="a-form-label" [for]="fieldName"  [class.aos-text-error]="!!errorTooltip">
            {{ field.questionText }}
            <field-required-marker [field]="field"></field-required-marker>
        </label>
        <div class="p-field p-col-12">
            <p-calendar styleClass="aos-form-calendar" [inputStyleClass]="borderClass" [formControlName]="fieldName" [showIcon]="true" (onSelect)="onDatePickerChange($event)" (onInput)="onDatePickerChange($event)"
                [minDate]="minDate" [maxDate]="maxDate" [readonlyInput]="true" [yearNavigator]="true" [yearRange]="dateRange"></p-calendar>
        </div>
    </div>
    <div *ngSwitchCase="formFieldTypes.dropdown" class="aos-form-dropdown">
        <label class="a-form-label" [for]="fieldName" [class.aos-text-error]="!!errorTooltip">
            {{ field.questionText }}
            <field-required-marker [field]="field"></field-required-marker>
        </label>
        <ap-selector [ngClass]="borderClass" [items]="[{data: selectorItems}]" [disabled]="formControl.disabled" [id]="fieldName" [type]="'SingleSelector'" (onSelectedChange)="onSelectChanged(fieldName, $event)"  style="width:200px" [searchMethod]="'inline'"></ap-selector>
    </div>
</div>
