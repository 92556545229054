<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>

<div class="titletop" *ngIf="!isLoading">
  <h5 class="title">Organization Management</h5>
  <div class="row">
    <div class="col-12 gray-container table-content">
      <p-table
        #dt1
        [value]="tableData"
        [paginator]="true"
        [rows]="10"
        [scrollable]="true"
        scrollHeight="320px"
        [resizableColumns]="true"
        [autoLayout]="true"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['organizationName']"
        ><ng-template pTemplate="caption">
          <div class="table-header">
            <div class="row">
              <div class="col-6">
                <button
                  class="a-btn a-btn-primary a-btn-lg"
                  data-target="#exampleModalSix"
                  data-toggle="modal"
                  (click)="showMoreLarge('show-large-modal-organization')"
                >
                  Add Organization
                </button>
              </div>

              <div class="col-6 d-flex justify-content-end">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input
                    type="text"
                    #globalSearchs
                    pInputText
                    size="25"
                    placeholder="Search"
                    (keyup)="onKeyup($event)"
                    (input)="dt1.filterGlobal($event.target.value, 'contains')"
                  />
                  <i
                    class="fa fa-times-circle cleariconprimeng"
                    *ngIf="xVisible"
                    aria-hidden="true"
                    (click)="clearGlobalFilter($event.target.value)"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="organizationName" [style]="{ width: '20%' }">
              Organization <p-sortIcon field="organizationName"></p-sortIcon>
            </th>
            <th pSortableColumn="status" [style]="{ width: '20%' }">
              Status <p-sortIcon field="status"></p-sortIcon>
            </th>
            <th [style]="{ width: '2%' }"></th>
            <th [style]="{ width: '2%' }"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-tableData>
          <tr>
            <td [style]="{ width: '20%' }">{{ tableData.organizationName }}</td>
            <td [style]="{ width: '20%' }">{{ tableData.status === 1 ? 'Active' : 'Inactive' }}</td>

            <td [style]="{ width: '2%' }">
              <i
                class="pi pi-pencil buttonspacing"
                aria-hidden="true"
                (click)="
                  editOrganization('show-large-modal-organization', tableData)
                "
              ></i>
            </td>
            <td [style]="{ width: '2%' }">
              <i
                class="pi pi-trash buttonspacing"
                aria-hidden="true"
                (click)="
                  showMoreCustom('show-custom-modal-delete-org', tableData)
                "
              ></i>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<div class="text-center a-p-10">
  <div class="overlay" *ngIf="showoverlay"></div>
  <ap-modal
    #baseModalLarge
    appModalId="show-large-modal-organization"
    style="z-index: 999999"
  >
    <div class="modal-dialog a-modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="a-modal-header">
          <div class="flex-fill">
            <span *ngIf="!onEdit" class="a-h4">Add Organization</span>
            <span *ngIf="onEdit" class="a-h4">Edit Organization</span>
          </div>
          <button
            aria-label="Close"
            class="icon-wrapper"
            data-dismiss="modal"
            (click)="closeModalLarge('show-large-modal-organization')"
          >
            <span class="appkiticon icon-close-fill a-close-btn"></span>
          </button>
        </div>
        <div class="a-modal-body">
          <div class="a-form-label a-mt-20">Organization</div>
          <input
            [ngStyle]="{
              'background-color': 'white'
            }"
            aria-label="val1"
            [(ngModel)]="organization"
            (change)="onChangeEvent($event)"
            class="width-310 a-text-input"
            [ngClass]="{ red: organizationErrorMsg }"
            [value]="org ? org : ''"
          />
          <div *ngIf="organizationErr" class="a-form-error a-mt-5 error-msg">
            {{ organizationErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
          </div>

          <div *ngIf="onEdit" class="a-pt-20">
            <span class="a-mr-10" [ngStyle]="{ 'font-size': '0.85rem' }"
              >Status</span
            >
            <!-- <label class="statusSlider a-toggle a-toggle-text" tabindex="7">
              <input
                name="orgStatus"
                [checked]="status"
                type="checkbox"
                [(ngModel)]="status"
              />
              <div class="a-toggle-mark">
                <span class="on">Active&nbsp;&nbsp; </span>
                <span class="off">Disabled</span>
                <span class="switch"></span>
              </div>
            </label> -->
            <label class="statusSlider a-toggle a-toggle-text a-m-5" tabindex="7">
              <input type="checkbox"
              name="orgStatus"
                [checked]="status"
                [(ngModel)]="status"
              #statusToggle>
              <div class="a-toggle-mark">
                  <span class="on"></span>
                  <span class="off"></span>
                  <span class="switch"></span>
              </div>
              <span *ngIf="statusToggle.checked" class="a-m-10 status-active">Active</span>
              <span *ngIf="!statusToggle.checked" class="a-m-10 status-disabled">Disabled</span>
          </label>
          </div>
        </div>
        <div class="a-modal-footer">
          <div class="flex-fill text-right a-font-12 a-text-grey-medium">
            <button
              aria-label="Close"
              class="a-btn a-btn-transparent a-btn-lg a-btn-gray a-mr-10"
              data-dismiss="modal"
              (click)="closeModalLarge('show-large-modal-organization')"
            >
              CANCEL
            </button>
            <button
              aria-label="Close"
              class="a-btn a-btn-transparent a-btn-lg"
              data-dismiss="modal"
              (click)="addOrganization('show-large-modal-organization')"
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  </ap-modal>

  <div class="overlay" *ngIf="showoverlay"></div>
  <ap-modal
    #baseModalCustom
    appModalId="show-custom-modal-delete-org"
    style="z-index: 999"
  >
    <div
      class="modal-dialog a-modal-lg modal-dialog-centered modal-sm"
      role="document"
    >
      <div class="modal-content">
        <div class="a-modal-header">
          <div class="flex-fill d-flex align-items-center">
            <div class="a-h4">Confirmation</div>
          </div>

          <button
            aria-label="Close"
            class="icon-wrapper"
            data-dismiss="modal"
            (click)="closeModalCustom('show-custom-modal-delete-org')"
          >
            <span class="appkiticon icon-close-fill a-close-btn"></span>
          </button>
        </div>
        <div class="a-modal-body pt-0">
          <div class="a-p4 a-mt-10 text-description">
            <span class="appkiticon icon-alert-outline"></span> &nbsp; Are you
            sure you want to delete the organization ({{ org }})?
          </div>
        </div>
        <div class="a-modal-footer a-border-tp">
          <div class="flex-fill text-left a-font-12 a-text-grey-medium"></div>
          <button
            aria-label="Close"
            class="a-btn a-btn-transparent a-btn-lg a-btn-gray a-mr-10"
            data-dismiss="modal"
            (click)="closeModalCustom('show-custom-modal-delete-org')"
          >
            NO
          </button>
          <button
            aria-label="Close"
            class="a-btn a-btn-transparent a-btn-lg"
            data-dismiss="modal"
            (click)="deleteOrganization('show-custom-modal-delete-org')"
          >
            YES
          </button>
        </div>
      </div>
    </div>
  </ap-modal>
</div>
