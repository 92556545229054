<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="application">
  <header *ngIf="isUserAuthenticated">
    <app-header></app-header>
    <app-toast></app-toast>
  </header>
  <div [ngClass]="{ 'application-body': isUserAuthenticated }">
    <div [ngClass]="{ mainContainer: isUserAuthenticated }">
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- <footer *ngIf="isUserAuthenticated" class="application-footer">
    <app-footer></app-footer>
  </footer> -->
</div>

<ap-modal #childModal appModalId="modal-timeout">
  <div
    class="modal-dialog a-modal-lg modal-dialog-centered modal-sm"
    role="document"
  >
    <div class="modal-content">
      <div class="a-modal-header">
        <div class="flex-fill d-flex align-items-center">
          <div class="a-h4">Warning - You Have Been Idle!</div>
        </div>
      </div>
      <div class="a-modal-body pt-0">
        <div class="a-p4 a-mt-10 text-description">
          <span class="appkiticon icon-alert-outline a-mr-10"></span>
          {{ idleState }}
        </div>
        <div class="a-font-14 a-mt-10 text-description">
          Press any key or perform some mouse action to stop the countdown.
        </div>
      </div>
    </div>
  </div>
</ap-modal>

<div class="overlay" *ngIf="showoverlay"></div>
<ap-modal #baseModalLogoutCustom appModalId="show-custom-logout-modal">
  <div
    class="modal-dialog a-modal-lg modal-dialog-centered modal-sm"
    role="document"
  >
    <div class="modal-content">
      <div class="a-modal-header">
        <div class="flex-fill d-flex align-items-center">
          <div class="a-h4">Warning</div>
        </div>
      </div>
      <div class="a-modal-body pt-0">
        <div class="a-p4 a-mt-10 text-description">
          <span class="appkiticon icon-alert-outline"></span> &nbsp; We have
          detected another active session with the same login credentials. You
          will be logged out in <b>{{ seconds }}</b> Seconds. <br /><br />
          If you believe you received this message in error, contact IT customer
          support by sending an email to
          <b>ca_vialto_aos_support@vialto.com</b>
        </div>
      </div>
    </div>
  </div>
</ap-modal>
