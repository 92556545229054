import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  name = 'My Adjustment of Status';

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {}

  onLogout(): void {
    this.authService.logout('manual');
  }

}
