import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AosHttpService {
  constructor(private authService: AuthService, private http: HttpClient) {}

  get<T>(endpointUrl, params?): Observable<any> {
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const executionEnvironment = executionEnvData.executionEnvironment;

    const fullUrl = new URL(endpointUrl, backendURL);
    const options = {};
    if (params) {
      options['params'] = params;
    }

    return this.http.get<T>(fullUrl.href, options).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(fullUrl.href);
      })
    );
  }

  post(endpointUrl, body, params?): Observable<any> {
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const executionEnvironment = executionEnvData.executionEnvironment;

    const fullUrl = new URL(endpointUrl, backendURL);
    const options: any = {};
    if (params) {
      options['params'] = params;
    }

    return this.http.post(fullUrl.href, body, {}).pipe(
      map((response: any) => {
        if (response.status === false) {
          return throwError(
            'Server Error:' + endpointUrl + ';   ' + response.message
          );
        }
      }),
      catchError((error) => {
        console.log(error);
        return throwError(fullUrl.href);
      })
    );
  }
}
