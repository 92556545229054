<div class="flex-container">
  <!-- <img class="a-mb-30" src="../assets/images/pwc-logo.png" /> -->

  <span class="font-weight-bold a-font-28 a-mb-10 a-mx-20">
    My Adjustment of Status
  </span>

  <span class="a-font-22 a-mb-20 a-mx-20">
    Oops! Something went wrong with the application.
  </span>

  <span class="a-font-18 a-mx-20">
    Please contact IT customer support by dropping an email to the below email address
  </span>
  <div class="text-center phone-block">
    <i
      class="fa fa-envelope a-p-10"
      style="display: block; font-size: 1.15rem"
    ></i>
    Email <br />
    <b>ca_vialto_aos_support@vialto.com</b>
  </div>
</div>
