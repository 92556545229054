import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { endpointUrls } from '../../environments/environment';
import { take } from 'rxjs/operators';



@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private token: string;
  private loggedInUserData: {
    userName: string;
    userRole: string;
    dueDate: string;
    emailId: string
  };
  private isAuthenticated = false;
  private isLoggingIn = false;
  private authStatusListener = new Subject<boolean>();

  private executionEnvironment = 'local';
  private backendURL: string;
  private authURL: string;
  private clientId: string;
  private scope: string;
  private redirectURL: string;
  private signoutURL: string;
  private isTokenRefreshInProgress = false;

  constructor(private http: HttpClient, private router: Router) {
    if (environment.local.authConfig.redirectURL.includes(window.location.origin)) {
      this.executionEnvironment = 'local';
    } else if (
      environment.dev.authConfig.redirectURL.includes(window.location.origin)
    ) {
      this.executionEnvironment = 'dev';
    } else if (
       environment.qa.authConfig.redirectURL.includes(window.location.origin)
    ) {
      this.executionEnvironment = 'qa';
    } else if (
     environment.stage.authConfig.redirectURL.includes(window.location.origin)
    ) {
      this.executionEnvironment = 'stage';
    } else if (
     environment.prod.authConfig.redirectURL.includes(window.location.origin)
    ) {
      this.executionEnvironment = 'prod';
    }
    this.backendURL = environment[this.executionEnvironment].backendURL;
    this.authURL = environment[this.executionEnvironment].authConfig.authURL;
    this.clientId = environment[this.executionEnvironment].authConfig.clientId;
    this.scope = environment[this.executionEnvironment].authConfig.scope;
    this.redirectURL =
      environment[this.executionEnvironment].authConfig.redirectURL;
    this.signoutURL =
      environment[this.executionEnvironment].authConfig.signoutURL;
  }

  getBackendURLAndExecutionEnv(): {
    backendURL: string;
    executionEnvironment: string;
  } {
    return {
      backendURL: this.backendURL,
      executionEnvironment: this.executionEnvironment,
    };
  }

  getToken(): string {
    return this.token;
  }

  getLoggedInUserData(): {
    userName: string;
    userRole: string;
    dueDate: string;
    emailId: string;
  } {
    return this.loggedInUserData;
  }

  getIsAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  getIsLoggingIn(): boolean {
    return this.isLoggingIn;
  }

  getAuthStatusListener(): Observable<boolean> {
    return this.authStatusListener.asObservable();
  }

  login(): void {
    //const url = `${this.authURL}?response_type=code&client_id=${this.clientId}&scope=${this.scope}&redirect_uri=${this.redirectURL}`;
    //window.location.href = url;
     //const url = `${this.authURL}?response_type=id_token&client_id=${this.clientId}&scope=${this.scope}&redirect_uri=${this.redirectURL}`;
     const url = `${this.authURL}?response_type=token id_token&client_id=${this.clientId}&scope=${this.scope}&redirect_uri=${this.redirectURL}&nonce=test`;
     //const url = `${this.authURL}?response_type=code|token&client_id=${this.clientId}&scope=${this.scope}&redirect_uri=${this.redirectURL}&state=STATE`;
     //console.log(url);
     window.location.href = url;
    
  }

  closeSession(): void {
    this.isAuthenticated = false;
    this.isLoggingIn = false;
    this.authStatusListener.next(false);
    sessionStorage.clear();
    this.clearAuthData();
    window.location.href = `${this.signoutURL}`;
  }

  logout(logoutValue): void {
    const logoutURL = endpointUrls.logoutUrl;
    const queryParams = '?reason=' + logoutValue;
    const logoutEndpoint = this.backendURL + logoutURL + queryParams;
    this.http.get<any>(logoutEndpoint).subscribe(
      (response) => {
        this.closeSession();
      },
      (error) => {
        this.closeSession();
      }
    );
  }

  exchangeCodeWithToken(code: string): Observable<any> {
    this.isLoggingIn = true;
    const exchangeCodeWithTokenURL = endpointUrls.exchangeCodeWithToken;
    const queryParams = '?code=' + code + '&url=' + this.redirectURL;
    const exchangeCodeWithTokenEndpoint = this.backendURL + exchangeCodeWithTokenURL + queryParams;
    return this.http
      .post<{
        access_token: string;
        refresh_token: string;
        scope: string;
        id_token: string;
        token_type: string;
        expires_in: Number;
      }>(exchangeCodeWithTokenEndpoint, {});
  }

  authenticate(): void {
    this.isLoggingIn = true;
    const idToken = sessionStorage.getItem('id_token');
    if (idToken) {
      const authenticateURL = endpointUrls.authenticateToken;
      const authenticateEndpoint = this.backendURL + authenticateURL;
      this.http
        .post<{
          token: string;
          userRole: string;
          userName: string;
          expiresIn: string;
          dueDate: string;
          emailID: string;
        }>(authenticateEndpoint, {
          idToken: idToken,
        })
        .pipe(take(1))
        .subscribe(
          (response) => {
            console.log(response,"response");
            const token = response.token;
            const userRole = response.userRole;
            const userName = response.userName;
            const dueDate = response.dueDate;
            const expiresAt = response.expiresIn;
            const emailId = response.emailID;
            if (token) {
              this.token = token;
              this.loggedInUserData = {
                userRole,
                userName,
                dueDate,
                emailId
               };
              this.isAuthenticated = true;
              this.isLoggingIn = false;
              this.saveAuthData(token, expiresAt);
              this.authStatusListener.next(true);
              this.router.navigate(['/']);
            }
          },
          (error) => {
            console.log('ERROR :', error);
            this.isAuthenticated = false;
            this.isLoggingIn = false;
            this.authStatusListener.next(false);
          }
        );
    } else {
      this.login();
    }
  }

  private saveAuthData(token: string, expiresAt: string): void {
    localStorage.setItem('token', token);
    localStorage.setItem('expiresAt', expiresAt);
  }

  private clearAuthData(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('expiresAt');
  }

  private getAuthData(): { token: string; expiresAt: string } {
    const token = localStorage.getItem('token');
    const expiresAt = localStorage.getItem('expiresAt');
    if (!token) {
      return;
    }
    return {
      token,
      expiresAt,
    };
  }

  refreshToken(): void {
    this.isTokenRefreshInProgress = true;
    const refreshTokenURL = endpointUrls.refreshToken;
    const refreshTokenEndpoint = this.backendURL + refreshTokenURL;
    this.http
      .get<any>(refreshTokenEndpoint)
      .pipe(take(1))
      .subscribe(
        (response) => {
          const token = response.token;
          const expiresAt = response.expiresIn;
          this.token = token;
          this.saveAuthData(token, expiresAt);
          this.isTokenRefreshInProgress = false;
        },
        (error) => {
          console.log('ERROR - Refresh token response:', error);
          this.isAuthenticated = false;
          this.isLoggingIn = false;
          this.authStatusListener.next(false);
          this.isTokenRefreshInProgress = false;
        }
      );
  }

  validSession(): Observable<any> {
    if (!this.isTokenRefreshInProgress) {
      const token = localStorage.getItem('token');
      const params = '?token=' + token;
      const executionEnvData = this.getBackendURLAndExecutionEnv();
      const backendURL = executionEnvData.backendURL;
      const validate = endpointUrls.validateSession + params;
      const validateSessionEndpoint = backendURL + validate;
      return this.http.get(validateSessionEndpoint);
    } else {
      return of(true);
    }
  }

  getDownTime(): Observable<any> {
    const executionEnvData = this.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const getDownTimeURL = endpointUrls.getDownTimeDetails;
    const getDownTimeEndpoint = backendURL + getDownTimeURL;

    return this.http.get(getDownTimeEndpoint);
  }
}
