import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  FormFieldSpec,
  FormFieldType,
} from 'src/app/services/form-service.service';

@Component({
  selector: 'field-errors',
  templateUrl: './field-errors.component.html',
  styleUrls: ['./field-errors.component.scss'],
})
export class FieldErrorsComponent implements OnInit {
  @Input() fieldSpec: FormFieldSpec;
  // This is named with an underscore to prevent errors
  // where Angular mixes it up with the built-in formControl attribute
  @Input() form_Control: FormControl;

  showErrorsEnabled: boolean;

  get hasVisibleErrors(): boolean {
    if (!this.form_Control.errors) {
      return false;
    }
    const errorCount = Object.keys(this.form_Control.errors).length;
    if (errorCount === 0) {
      return false;
    }

    return !(
      errorCount === 1 &&
      this.form_Control.errors.required &&
      this.form_Control.pristine
    );
  }

  constructor() {}

  ngOnInit(): void {
    const fieldType = this.fieldSpec.type.toLowerCase();
    this.showErrorsEnabled = fieldType !== FormFieldType.label;
  }
}
