import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

import { AppRoutingModule } from './app-routing.module';
import { AppkitModule } from './appkit.module';
import { PrimeNgModule } from './primeng.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './main/header/header.component';
import { FooterComponent } from './main/footer/footer.component';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { UserManagementComponent } from './main/user-management/user-management.component';
import { MultiStepFormComponent } from './forms/multi-step-form.component';
import { FormSubSectionComponent } from './forms/form-sub-section/form-sub-section.component';
import { CreateUserComponent } from './main/user-management/create-user/create-user.component';
import { FileUploadComponent } from './main/file-upload/file-upload.component';
import { FieldErrorsComponent } from './forms/field-errors/field-errors.component';
import { FormNavigationComponent } from './forms/form-navigation/form-navigation.component';
import { FormsContainerComponent } from './forms/forms-container/forms-container.component';
import { FieldRequiredMarkerComponent } from './forms/field-required-marker/field-required-marker.component';
import { FormFieldControlComponent } from './forms/form-field-control/form-field-control.component';
import { ToggleComponent } from './main/toggle/toggle.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { LoginComponent } from './main/auth/login/login.component';
import { AuthInterceptor } from './services/auth.interceptor';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { UnauthorizedComponent } from './shared/unauthorized/unauthorized.component';
import { ErrorInterceptor } from './services/error.interceptor';
import { GenerateTabsComponent } from './main/generate-tabs/generate-tabs.component';
import { AccordionsModule } from 'appkit-angular/accordions';
import { ToastComponent } from './shared/toast/toast.component';
import { ErrorComponent } from './shared/error/error.component';
import { OrganizationManagementComponent } from './main/organization-management/organization-management.component';
import { AttorneyManagementComponent } from './main/attorney-management/attorney-management.component';
import {FileUploadModule} from 'primeng/fileupload';
import { TooltipModule } from 'primeng/tooltip';
import {ProgressBarModule} from 'primeng/progressbar';
import { CheckStatusComponent } from './main/check-status/check-status.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    UserManagementComponent,
    MultiStepFormComponent,
    FormSubSectionComponent,
    CreateUserComponent,
    FieldErrorsComponent,
    FormNavigationComponent,
    FormsContainerComponent,
    FieldRequiredMarkerComponent,
    FormFieldControlComponent,
    ToggleComponent,
    FileUploadComponent,
    LoaderComponent,
    LoginComponent,
    UnauthorizedComponent,
    GenerateTabsComponent,
    ToastComponent,
    ErrorComponent,
    OrganizationManagementComponent,
    AttorneyManagementComponent,
    CheckStatusComponent
  ],
  imports: [
    Ng2SearchPipeModule,
    NgIdleKeepaliveModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    AppkitModule,
    PrimeNgModule,
    BrowserAnimationsModule,
    AccordionsModule,
    FileUploadModule,
    TooltipModule,
    ProgressBarModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
