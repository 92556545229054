<div class="a-header main">
  <div
    class="a-hfn-header-container d-flex align-items-center justify-content-between navigation-fourth-bar"
  >
    <div class="d-flex align-items-center">
      <!-- <div class="a-pwc-logo-grid a-md"></div> -->
      <img src="../../../assets/images/logo.png" alt="Logo" class="logo-img" />
      <span class="divider a-mx-20"></span>
      <div class="font-weight-medium a-nowrap">{{ name }}</div>
    </div>

    <!-- right logo -->
    <div class="d-flex align-items-center a-pl-10">
      <div class="a-logout" (click)="onLogout()" style="cursor: pointer">
        <i
          class="fa-arrow-circle-left a-mr-5"
          style="font-family: fontAwesome"
        ></i>
        <span>Log Out</span>
      </div>
    </div>
  </div>
</div>

<div class="flex-container">
  <!-- <img class="a-mb-30" src="../assets/images/pwc-logo.png" /> -->

  <span class="font-weight-bold a-font-28 a-mb-10 a-mx-20">
    My Adjustment of Status
  </span>

  <span class="a-font-22 a-mb-20 a-mx-20">
    You are not authorized to access this resource.
  </span>

  <span class="a-font-18 a-mx-20">
    If you believe you received this message in error, contact IT customer
    support by dropping an email to the below email address
  </span>
  <div class="text-center phone-block">
    <i
      class="fa fa-envelope a-p-10"
      style="display: block; font-size: 1.15rem"
    ></i>
    Email <br />
    <b>ca_vialto_aos_support@vialto.com</b>
  </div>
</div>
