<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>

<div class="titletop user-section" *ngIf="!isLoading">
  <h5 class="title">User Management </h5>   

  <!--<div class="row justify-content-between">
        <div class="col-4">
          <button
            class="a-btn a-btn-primary a-btn-lg"
            data-target="#exampleModalSix"
            data-toggle="modal"
            (click)="showMoreLarge('show-large-modal')"
          >
            Add User
          </button>
        </div> -->

  <!-- <div class="col-3">

           <ap-search
            [isPrimary]="false"
            [isBase]="true"
            [(ngModel)]="tableSearch"
            (onKeyUp)="tableSearchFun($event)"
            [minLength]="1"
            placeholder="Search"
          >
          <span class="appkiticon icon-close-fill a-close-btn"></span>
          </ap-search>



        </div>

      </div> -->
  <div class="row">
    <div class="col-12 gray-container table-content">
      <!-- <ap-table
        #basicTable
        [data]="tableData3"
        (onTablePageChange)="tablePageChange($event)"
        (onTablePageNumberChange)="tablePageNumberChange($event)"
        [page]="page"
        [pageSize]="pageSize"
        [step]="1"
        [pageDropdownList]="tablePageDropdownList"
        [isFrontPagination]="true"
        [total]="5"
      >
        <thead class="a-thead">
          <tr>
            <th (click)="tableSortingFun()">
              First Name
              <span
                class="appkiticon icon-sorting-ascendant-fill"
                style="margin-left: 5px"
                [ngClass]="{ 'icon-sorting-descendant-fill': tableSorting }"
              ></span>
            </th>
            <th (click)="tableSortingFun()">
              Last Name
              <span
                class="appkiticon icon-sorting-ascendant-fill"
                style="margin-left: 5px"
                [ngClass]="{ 'icon-sorting-descendant-fill': tableSorting }"
              ></span>
            </th>
            <th (click)="tableSortingFun()">
              Email
              <span
                class="appkiticon icon-sorting-ascendant-fill"
                style="margin-left: 5px"
                [ngClass]="{ 'icon-sorting-descendant-fill': tableSorting }"
              ></span>
            </th>
            <th (click)="tableSortingFun()">
              Organization
              <span
                class="appkiticon icon-sorting-ascendant-fill"
                style="margin-left: 5px"
                [ngClass]="{ 'icon-sorting-descendant-fill': tableSorting }"
              ></span>
            </th>
            <th (click)="tableSortingFun()">
              Territory
              <span
                class="appkiticon icon-sorting-ascendant-fill"
                style="margin-left: 5px"
                [ngClass]="{ 'icon-sorting-descendant-fill': tableSorting }"
              ></span>
            </th>
            <th (click)="tableSortingFun()">
              Role
              <span
                class="appkiticon icon-sorting-ascendant-fill"
                style="margin-left: 5px"
                [ngClass]="{ 'icon-sorting-descendant-fill': tableSorting }"
              ></span>
            </th>
            <th (click)="tableSortingFun()">
              Status
              <span
                class="appkiticon icon-sorting-ascendant-fill"
                style="margin-left: 5px"
                [ngClass]="{ 'icon-sorting-descendant-fill': tableSorting }"
              ></span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody class="a-tbody tbody-regular tbody-hover">
          <tr *ngFor="let item of basicTable.data">
            <td>{{ item.firstName }}</td>
            <td>{{ item.lastName }}</td>
            <td>{{ item.emailId }}</td>
            <td>{{ item.organization }}</td>
            <td>{{ item.territory }}</td>
            <td>{{ item.role }}</td>
            <td>{{ item.status }}</td>
            <td>
              <i
                class="fa fa-pencil buttonspacing"
                aria-hidden="true"
                (click)="editUser(item)"
              ></i>

              <i
                class="fa fa-trash buttonspacing"
                aria-hidden="true"
                (click)="showMoreCustom('show-custom-modal', item)"
              ></i>
            </td>
          </tr>
        </tbody>
      </ap-table> -->

      <p-table
        #OrgTable2
        [value]="tableData"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [scrollable]="true"
        scrollHeight="320px"
        loading="true"
        [showLoader]="loader"
        [resizableColumns]="true"
        [autoLayout]="true"
        [globalFilterFields]="[
          'firstName',
          'lastName',
          'emailId',
          'territory',
          'organizationName',
          'role',
          'status'
        ]"
      >
        <ng-template pTemplate="caption">
          <div class="table-header">
            <div class="row">
              <div class="col-5">
                <button
                  class="a-btn a-btn-primary a-btn-lg"
                  data-target="#exampleModalSix"
                  data-toggle="modal"
                  (click)="showMoreLarge('show-large-modal')"
                >
                  Add User
                </button>                
                <button
                  class="a-btn a-btn-primary a-btn-lg a-ml-10"                  
                  (click)="downloadTemplate()"
                >
                  Download Template
                </button>  
                <button
                  class="a-btn a-btn-primary a-btn-lg a-ml-10 upload-btn"                  
                  data-toggle="modal"
                  (click)="showBulkUpload('show-bulk-modal')" [disabled]="!bulkUploadStatus.status">
                  Bulk Upload
                </button> 
                <i *ngIf="!bulkUploadStatus.status" class="fa fa-info-circle a-ml-10 info-icon" tooltipStyleClass="bulk-upload-txt" pTooltip="Bulk Upload button will be enabled once previous upload is completed." tooltipPosition="top">
                </i>              
              </div>

              <div class="col-7 d-flex justify-content-end">
                <!-- <button class="a-btn a-btn-primary a-btn-lg a-mr-10"                  
                  (click)="downloadGUMTemplate()">
                  Download GUM Template
                </button>
                <a class="a-btn a-btn-primary a-btn-lg a-mr-10"                  
                  href="https://login.pwc.com/identity/" target="_blank">
                  GUM Portal
                </a> -->
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input
                    type="text"
                    #globalSearch
                    pInputText
                    size="25"
                    placeholder="Search"
                    (keyup)="onKeyup($event)"
                    (input)="
                      OrgTable2.filterGlobal($event.target.value, 'contains')
                    "
                  />

                  <!-- <i class="pi pi-times closeIcon"
                        ></i> -->
                  <i
                    class="fa fa-times-circle cleariconprimeng"
                    *ngIf="xVisible"
                    aria-hidden="true"
                    (click)="clearGlobalFilter($event.target.value)"
                  ></i>
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 notify-msg" *ngIf="showBulkUploadNotificationMsg">
                {{bulkUploadStatus.message}}              
                <span *ngIf="showErrorFile" class="a-ml-5" (click)="downloadErrorFile()">Download Error File</span>
                <div *ngIf="showProgressBar">
                  <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="firstName" [style]="{ width: '12%' }">
              First Name <p-sortIcon field="firstName"></p-sortIcon>
            </th>
            <th pSortableColumn="lastName" [style]="{ width: '12%' }">
              Last Name <p-sortIcon field="lastName"></p-sortIcon>
            </th>
            <th pSortableColumn="emailId" [style]="{ width: '25%' }">
              Email <p-sortIcon field="emailId"></p-sortIcon>
            </th>
            <th pSortableColumn="organizationName" [style]="{ width: '13%' }">
              Organization <p-sortIcon field="organizationName"></p-sortIcon>
            </th>
            <th pSortableColumn="territory" [style]="{ width: '10%' }">
              Territory <p-sortIcon field="territory"></p-sortIcon>
            </th>
            <th pSortableColumn="role" [style]="{ width: '10%' }">
              Role <p-sortIcon field="role"></p-sortIcon>
            </th>
            <th pSortableColumn="status" [style]="{ width: '10%' }">
              Status <p-sortIcon field="status"></p-sortIcon>
            </th>
            <th [style]="{ width: '8%' }"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-tableData>
          <tr>
            <td [style]="{ width: '12%' }">{{ tableData.firstName }}</td>
            <td [style]="{ width: '12%' }">{{ tableData.lastName }}</td>
            <td [style]="{ width: '25%' }">{{ tableData.emailId }}</td>
            <td [style]="{ width: '13%' }">{{ tableData.organizationName }}</td>

            <td [style]="{ width: '10%' }">{{ tableData.territory }}</td>
            <td [style]="{ width: '10%' }">{{ tableData.role }}</td>
            <td [style]="{ width: '10%' }">{{ tableData.status }}</td>
            <td [style]="{ width: '8%' }">
              <i
                class="pi pi-pencil buttonspacing"
                aria-hidden="true"
                (click)="editUser(tableData)"
              ></i>

              <i
                class="pi pi-trash buttonspacing"
                aria-hidden="true"
                (click)="showMoreCustom('show-custom-modal', tableData)"
              ></i>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<div class="text-center a-p-10">
  <div class="overlay" *ngIf="showoverlay"></div>
  <ap-modal
    #baseModalLarge
    (onCheckModalClose)="modalClose()"
    (onCheckModalOk)="modalDetermine()"
    appModalId="show-large-modal"
    style="z-index: 999999"
  >
    <div class="modal-dialog a-modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="a-modal-header">
          <div class="flex-fill">
            <span *ngIf="!onEdit" class="a-h4">Add User</span>
            <span *ngIf="onEdit" class="a-h4">Edit User</span>
          </div>
          <button
            aria-label="Close"
            class="icon-wrapper"
            data-dismiss="modal"
            (click)="closeModalLarge('show-large-modal')"
          >
            <span class="appkiticon icon-close-fill a-close-btn"></span>
          </button>
        </div>
        <div class="a-modal-body overflow">
          <div class="row">
            <div class="col-12">
              <div class="text-center">
                <label class="a-radio a-mr-10 a-ml-10">
                  <input
                    [disabled]="onEdit"
                    name="options"
                    type="radio"
                    (click)="externalUser('Internal')"
                    [checked]="internal"
                  />
                  <span class="a-radio-mark">
                    <span class="a-radio-inner"></span>
                  </span>
                  <span class="a-radio-text">Internal Employee</span>
                  <!-- <label class="floatBlock">Internal Employee</label> -->
                </label>

                <label class="a-radio a-mr-10 a-ml-10">
                  <input
                    [disabled]="onEdit"
                    name="options"
                    type="radio"
                    (click)="externalUser('external')"
                    [checked]="external"
                  />
                  <span class="a-radio-mark">
                    <span class="a-radio-inner"></span>
                  </span>
                  <span class="a-radio-text">Client Employee</span>
                  <!-- <label class="floatBlock">Client Employee</label> -->
                </label>
              </div>
            </div>

            <!-- <div class="col-12">
              <div *ngIf="!onEdit && internal" class="a-form-label a-mt-20">
                Search Email or Name
              </div>
              <div class="a-input-with-icon a-input-md a-lf">
                <div class="search"> -->
                  <!-- <div class="no-gutters align-items-center d-flex">
                    <div class="a-form-label" [ngClass]="{'label-error':searchErr}">Search</div>
                  </div> -->
                  <!-- <div *ngIf="!onEdit && internal">
                    <span class="p-input-icon-left">
                      <i class="pi pi-search"></i>
                      <p-autoComplete
                        [ngClass]="{ red: searchErr }"
                        tabindex="1"
                        class="searchUser"
                        (onSelect)="populateFields($event)"
                        name="searchUser"
                        [(ngModel)]="searchInput"
                        [suggestions]="NameResults"
                        field="name"
                        (completeMethod)="searchuser($event)"
                        placeholder="Search"
                      ></p-autoComplete>
                    </span>
                  </div> -->

                  <!-- <ap-search
                    [(ngModel)]="searchInput"

                    [items]="userNames"
                    [(suggestions)]="NameResults"
                    (onClear)="searchclear($event)"
                    (onSelect)="populateFields($event)"
                    (onCompleteMethod)="searchuser($event)"
                    (onFocus)="searchError()"
                    [minLength]="1"
                    placeholder="Search"
                    [multiple]="false"
                    [custom]="true"
                    [itemTemplate]="customTemplate"
                    [valueField]="'value'"
                    [textField]="'name'"
                    #auto
                  >
                    <ng-template let-option #customTemplate>
                      <label *ngIf="option && option['name']">
                        <div class="d-flex align-items-center a-py-10">
                          <div
                            class="a-thumbnail-round a-thumbnail-32 a-mr-10"
                          ></div>
                          <div>
                            <div class="a-h6">{{ option.name }}</div>
                          </div>
                        </div>
                      </label>
                    </ng-template>
                  </ap-search>-->
                  <!-- <i
                    class="fa fa-search searchicon"
                    aria-hidden="true"
                    *ngIf="!onEdit && internal"
                  ></i>
                  <i
                    class="fa fa-times-circle clearicon"
                    *ngIf="searchInput"
                    aria-hidden="true"
                    (click)="clearsearchmodal()"
                  ></i> -->
                  <!-- <div
                    *ngIf="searchErr && internal && !onEdit"
                    class="a-form-error a-mt-5 error-msg"
                  >
                    Please Enter Valid Internal Employees<span class="appkiticon icon-information-fill error-icon"></span>
                  </div>
                  <div
                    *ngIf="noMatchFound && internal && !onEdit"
                    class="a-form-error a-mt-5 error-msg"
                  >
                    No Match Found<span class="appkiticon icon-information-fill error-icon"></span>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="col-6">
              <div class="a-form-label a-mt-20">First Name</div>
              <input
                [ngStyle]="{
                  'background-color': (internal && onEdit) || onEdit ? '#F2F2F2' : 'white'
                }"
                [disabled]="(internal && onEdit) || (onEdit && external)"
                aria-label="val1"
                [(ngModel)]="firstName"
                (change)="onChangeEvent($event, 'fName')"
                class="width-310 a-text-input"
                [value]="user ? user.firstName : ''"
                [(ngModel)]="firstName"
                [ngClass]="{ red: fNameErrorMsg }"
                required
              />
              <div *ngIf="firstNameErr" class="a-form-error a-mt-5 error-msg">
                {{ fNameErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
              </div>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">Last Name</div>
              <input
                [ngStyle]="{
                  'background-color': (internal && onEdit) || onEdit ? '#F2F2F2' : 'white'
                }"
                [disabled]="(internal && onEdit) || (onEdit && external)"
                aria-label="val1"
                [(ngModel)]="lastName"
                (change)="onChangeEvent($event, 'lName')"
                class="width-310 a-text-input"
                [value]="user ? user.lastName : ''"
                [(ngModel)]="lastName"
                [ngClass]="{ red: lNameErrorMsg }"
              />
              <div *ngIf="lastNameErr" class="a-form-error a-mt-5 error-msg">
                {{ lNameErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
              </div>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">Email</div>
              <input
                [ngStyle]="{
                  'background-color': (internal && onEdit) || onEdit ? '#F2F2F2' : 'white'
                }"
                [disabled]="(internal && onEdit) || (onEdit && external)"
                aria-label="val1"
                (change)="onChangeEvent($event, 'email')"
                class="width-310 a-text-input"
                [value]="user ? user.emailId : ''"
                [(ngModel)]="emailId"
                [ngClass]="{ red: mailErrorMsg }"
              />
              <div *ngIf="emailErr" class="a-form-error a-mt-5 error-msg">
                {{ mailErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
              </div>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">Organization</div>
              <!-- <input
                [ngStyle]="{
                  'background-color': onEdit ? '#F2F2F2' : 'white'
                }"
                [disabled]="onEdit"
                aria-label="val1"
                [(ngModel)]="organization"
                (change)="onChangeEvent($event, 'organization')"
                class="width-310 a-text-input"
                [value]="user ? user.organization : ''"
                [ngClass]="{ red: organizationErrorMsg }"
              /> -->
              <p-dropdown

                [ngStyle]="{
                  'background-color': internal || onEdit ? '#F2F2F2' : 'white'
                }"
                filter="false"
                [disabled]="onEdit"
                [options]="unAllocatedOrganization"
                [(ngModel)]="organizationCode"
                placeholder="Select Organization"
                optionLabel="organizationName"
                scrollHeight="90px"
                [style]="{'width':'-webkit-fill-available','height':'35px'}"
                (onChange)="onChangeEvent($event, 'organization')"
                [ngClass]="{ red: organizationErrorMsg }"
              ></p-dropdown>

              <div *ngIf="organizationErr" class="a-form-error a-mt-5 error-msg">
                {{ organizationErrorMsg }}<span class="appkiticon icon-information-fill error-icon-dropdown"></span>
              </div>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">Territory</div>
              <p-dropdown
                *ngIf="!onEdit"
                [ngStyle]="{
                  'background-color': internal || onEdit ? '#F2F2F2' : 'white'
                }"
                filter="false"
                [disabled]="onEdit"
                [options]="countryCodes"
                [(ngModel)]="countryCode"
                placeholder="Select Country code"
                optionLabel="countryCode"
                scrollHeight="90px"
                [style]="{'width':'-webkit-fill-available','height':'35px'}"
                (onChange)="onChangeEvent($event, 'territory')"
                [ngClass]="{ red: territoryErrorMsg }"
              ></p-dropdown>

              <input
                 *ngIf="onEdit"
                [disabled]="onEdit"
                aria-label="val1"
                [(ngModel)]="countryCode"
                class="width-310 a-text-input"
                [value]="user ? user.territory : ''"
              />

              <div *ngIf="territoryErr" class="a-form-error a-mt-5 error-msg">
                {{ territoryErrorMsg }}<span class="appkiticon icon-information-fill error-icon-dropdown"></span>
              </div>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">Case ID (Work Record)</div>
              <input
                [disabled]="onEdit"
                [ngStyle]="{
                  'background-color': onEdit ? '#F2F2F2' : 'white'
                }"
                aria-label="val1"
                (change)="onChangeEvent($event, 'mimsid')"
                class="width-310 a-text-input"
                [value]="user ? user.mimsid : ''"
                [(ngModel)]="mimsid"
                [ngClass]="{ red: caseIdentifierErrorMsg }"
              />
              <div *ngIf="mimsCaseIdentifierErr" class="a-form-error a-mt-5 error-msg">
                {{ caseIdentifierErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
              </div>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">Assignee ID</div>
              <input
                [disabled]="onEdit"
                [ngStyle]="{
                  'background-color': onEdit ? '#F2F2F2' : 'white'
                }"
                aria-label="val1"
                (change)="onChangeEvent($event, 'assigneeId')"
                class="width-310 a-text-input"
                [value]="user ? user.assigneeId : ''"
                [(ngModel)]="assigneeId"
                [ngClass]="{ red: assigneErrorMsg }"
              />
              <div *ngIf="assigneErr" class="a-form-error a-mt-5 error-msg">
                {{ assigneErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
              </div>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">Client ID</div>
              <input
                [disabled]="onEdit"
                [ngStyle]="{
                  'background-color': onEdit ? '#F2F2F2' : 'white'
                }"
                aria-label="val1"
                (change)="onChangeEvent($event, 'clientId')"
                class="width-310 a-text-input"
                [value]="user ? user.clientId : ''"
                [(ngModel)]="clientId"
                [ngClass]="{ red: clientErrorMsg }"
              />
              <div *ngIf="clientErr" class="a-form-error a-mt-5 error-msg">
                {{ clientErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
              </div>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">Engagement ID</div>
              <input
                [disabled]="onEdit"
                [ngStyle]="{
                  'background-color': onEdit ? '#F2F2F2' : 'white'
                }"
                aria-label="val1"
                (change)="onChangeEvent($event, 'engagement')"
                class="width-310 a-text-input"
                [value]="user ? user.engagement : ''"
                [(ngModel)]="engagement"
                [ngClass]="{ red: engmntErrorMsg }"
              />
              <div *ngIf="engmntErr" class="a-form-error a-mt-5 error-msg">
                {{ engmntErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
              </div>
            </div>
            <div class="col-6">
            </div>
            <div class="col-6 a-mt-30">
              <span class="a-mr-10" [ngStyle]="{ 'font-size': '0.85rem' }"
                >Status</span
              >
              <!-- <label class="statusSlider a-toggle a-toggle-text" tabindex="7">
                <input
                  name="userStatus"
                  [checked]="status"
                  type="checkbox"
                  [(ngModel)]="status"
                />
                <div class="a-toggle-mark">
                  <span class="on">Active&nbsp;&nbsp; </span>
                  <span class="off">Disabled</span>
                  <span class="switch"></span>
                </div>
              </label> -->
              <label class="statusSlider a-toggle a-toggle-text a-m-5" tabindex="7">
                <input type="checkbox"
                name="userStatus"
                  [checked]="status"
                  [(ngModel)]="status"
                #statusToggle>
                <div class="a-toggle-mark">
                    <span class="on"></span>
                    <span class="off"></span>
                    <span class="switch"></span>
                </div>
                <span *ngIf="statusToggle.checked" class="a-m-10 status-active">Active</span>
                <span *ngIf="!statusToggle.checked" class="a-m-10 status-disabled">Disabled</span>
            </label>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">Role</div>
              <label class="a-radio">
                <input
                  [disabled]="external"
                  name="role"
                  type="radio"
                  (click)="roleChange('User')"
                  [checked]="userRole"
                />
                <span class="a-radio-mark">
                  <span class="a-radio-inner"></span>
                </span>
                <span class="a-radio-text">User</span>
              </label>
              <label class="a-radio a-mr-10 a-ml-10">
                <input
                  [disabled]="external"
                  name="role"
                  type="radio"
                  (click)="roleChange('Admin')"
                  [checked]="admin"
                />
                <span class="a-radio-mark">
                  <span class="a-radio-inner"></span>
                </span>
                <span class="a-radio-text">Admin</span>
              </label>
            </div>
          </div>
        </div>
        <div class="a-modal-footer">
          <div class="flex-fill text-right a-font-12 a-text-grey-medium">
            <button
              aria-label="Close"
              class="a-btn a-btn-transparent a-btn-lg a-btn-gray a-mr-10"
              data-dismiss="modal"
              (click)="closeModalLarge('show-large-modal')"
            >
              CANCEL
            </button>
            <button
              aria-label="Close"
              class="a-btn a-btn-transparent a-btn-lg"
              data-dismiss="modal"
              (click)="closeModalLargeOk('show-large-modal')"
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  </ap-modal>

  <div class="overlay" *ngIf="showoverlay"></div>
  <ap-modal
    #baseModalCustom
    (onCheckModalClose)="modalClose()"
    (onCheckModalOk)="modalDetermine()"
    appModalId="show-custom-modal"
    style="z-index: 999"
  >
    <div
      class="modal-dialog a-modal-lg modal-dialog-centered modal-sm"
      role="document"
    >
      <div class="modal-content">
        <div class="a-modal-header">
          <div class="flex-fill d-flex align-items-center">
            <div class="a-h4">Confirmation</div>
          </div>

          <button
            aria-label="Close"
            class="icon-wrapper"
            data-dismiss="modal"
            (click)="closeModalCustom('show-custom-modal')"
          >
            <span class="appkiticon icon-close-fill a-close-btn"></span>
          </button>
        </div>
        <div class="a-modal-body pt-0">
          <div class="a-p4 a-mt-10 text-description">
            <span class="appkiticon icon-alert-outline"></span> &nbsp; Are you
            sure you want to delete the user ({{ fName }} {{ lName }})?
          </div>
        </div>
        <div class="a-modal-footer a-border-tp">
          <div class="flex-fill text-left a-font-12 a-text-grey-medium"></div>
          <button
            aria-label="Close"
            class="a-btn a-btn-transparent a-btn-lg a-btn-gray a-mr-10"
            data-dismiss="modal"
            (click)="closeModalCustom('show-custom-modal')"
          >
            NO
          </button>
          <button
            aria-label="Close"
            class="a-btn a-btn-transparent a-btn-lg"
            data-dismiss="modal"
            (click)="deleteUser('show-custom-modal')"
          >
            YES
          </button>
        </div>
      </div>
    </div>
  </ap-modal>

  <ap-modal
    #bulkUploadModalLarge
    (onCheckModalClose)="modalClose()"
    (onCheckModalOk)="modalDetermine()"
    appModalId="show-bulk-modal"
    style="z-index: 999999"
  >
    <div class="modal-dialog a-modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="a-modal-header">
          <div class="flex-fill">
            <span class="a-h4">Bulk Upload</span>
          </div>
          <button
            aria-label="Close"
            class="icon-wrapper"
            data-dismiss="modal"
            (click)="closeModalLarge('show-bulk-modal')"
          >
            <span class="appkiticon icon-close-fill a-close-btn"></span>
          </button>
        </div>
        <div class="a-modal-body">
          <div class="row">            
            <div class="col-12 a-mb-20">
              <div class="a-form-label a-mt-20">
                Select a xlsx File
              </div>
              <div class="a-input-with-icon a-input-md a-lf upload-container">
                <p-fileUpload  maxFileSize="1000000" #localFileSelector accept=".xlsx"
                  [showUploadButton]="false" customUpload="true" cancelLabel="Clear">
                </p-fileUpload>
                <div *ngIf="fileError" class="a-form-error a-mt-5 error-msg">
                  Please Select Template File<span class="appkiticon icon-information-fill error-icon"></span>
                </div>
              </div>                
            </div>
            <div class="col-12 text-right">
              <button
                  class="a-btn a-btn-transparent a-btn-lg"                  
                  data-toggle="modal" (click)="uploadCSV()"
                >
                UPLOAD 
              </button>
            </div>
          </div>
        </div>        
      </div>
    </div>
  </ap-modal>
</div>
