import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Dashboardservice } from '../../services/dashboard.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FormService } from '../../services/form-service.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  isLoading = false;

  isNewApplication = false;
  isDraftApplication = false;
  isSubmittedApplication = false;

  loadingError$ = new Subject<boolean>();
  applicationStatus: any;
  showoverlay: boolean;

  @ViewChild('baseModalCustom', { static: false }) baseModalCustom: any;
  private ngSubject: Subject<any> = new Subject<any>();
  constructor(
    public dashboardservice: Dashboardservice,
    public authService: AuthService,
    private router: Router,
    private formService: FormService
  ) {}

  dueDate: string;
  ngOnInit(): void {
    this.getUserApplicationStatus();
    this.dueDate = this.authService.getLoggedInUserData().dueDate;
  }

  private getUserApplicationStatus(): void {
    this.isLoading = true;
    this.isNewApplication = false;
    this.isDraftApplication = false;
    this.isSubmittedApplication = false;
    this.dashboardservice
      .getUserApplicationStatus()
      .pipe(takeUntil(this.ngSubject))
      .subscribe(
        (data) => {
          if (data) {
            if (data.currentUserApplicationStatus === 'In Draft') {
              this.isDraftApplication = true;
            } else if (data.currentUserApplicationStatus === 'New') {
              this.isNewApplication = true;
            } else if (
              data.currentUserApplicationStatus === 'Submitted' ||
              data.currentUserApplicationStatus === 'Forms Generated' ||
              data.currentUserApplicationStatus === 'Failed' ||
              data.currentUserApplicationStatus === 'Completed' ||
              data.currentUserApplicationStatus === 'Error'
            ) {
              this.isSubmittedApplication = true;
            }
            this.applicationStatus = data.currentUserApplicationStatus;
            this.isLoading = false;
          }
        },
        (error) => {
          console.log('Error when fetching application status', error);
          this.isLoading = false;
        }
      );
  }

  onNext(): void {
    // TODO Navigate to new/edit/view questionnaire end point
    this.router.navigateByUrl('/forms');
  }

  discardDraft(id: string): void {
    this.isLoading = true;
    this.formService
      .discardDraft()
      .pipe(takeUntil(this.ngSubject))
      .subscribe((response) => {
        this.getUserApplicationStatus();
      });
    this.closeModalCustom(id);
  }

  closeModalCustom(id: string): void {
    this.showoverlay = false;
    this.baseModalCustom.closeModal(id);
  }

  showModal(id: string): void {
    this.showoverlay = true;
    this.baseModalCustom.showMore(id);
  }
  ngOnDestroy(): void {
    this.ngSubject.next();
    this.ngSubject.complete();
  }
}
