<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>

<div class="titletop" *ngIf="!isLoading">
  <h5 class="title">Attorney Management</h5>
  <div class="row">
    <div class="col-12 gray-container table-content">
      <p-table
        #OrgTable2
        [value]="tableData"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [scrollable]="true"
        scrollHeight="320px"
        [resizableColumns]="true"
        [autoLayout]="true"
        [globalFilterFields]="[
          'familyName',
          'givenName',
          'emailAddress',
          'organizationName'
        ]"
      >
        <ng-template pTemplate="caption">
          <div class="table-header">
            <div class="row">
              <div class="col-6">
                <button
                  class="a-btn a-btn-primary a-btn-lg"
                  data-target="#exampleModalSix"
                  data-toggle="modal"
                  (click)="showMoreLarge('show-large-modal-attorney')"
                >
                  Add Attorney
                </button>
              </div>

              <div class="col-6 d-flex justify-content-end">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input
                    type="text"
                    #globalSearch
                    pInputText
                    size="25"
                    placeholder="Search"
                    (keyup)="onKeyup($event)"
                    (input)="
                      OrgTable2.filterGlobal($event.target.value, 'contains')
                    "
                  />

                  <i
                    class="fa fa-times-circle cleariconprimeng"
                    *ngIf="xVisible"
                    aria-hidden="true"
                    (click)="clearGlobalFilter($event.target.value)"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="familyName" [style]="{ width: '10%' }">
              Family Name<p-sortIcon field="familyName"></p-sortIcon>
            </th>
            <th pSortableColumn="givenName" [style]="{ width: '10%' }">
              Given Name <p-sortIcon field="givenName"></p-sortIcon>
            </th>

            <th pSortableColumn="emailAddress" [style]="{ width: '10%' }">
              Email Address <p-sortIcon field="emailAddress"></p-sortIcon>
            </th>

            <th pSortableColumn="organizationName" [style]="{ width: '10%' }">
              Organization <p-sortIcon field="organizationName"></p-sortIcon>
            </th>
            <th pSortableColumn="status" [style]="{ width: '10%' }">
              Status <p-sortIcon field="status"></p-sortIcon>
            </th>
            <th [style]="{ width: '4%' }"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-tableData>
          <tr>
            <td [style]="{ width: '11%' }">{{ tableData.familyName }}</td>
            <td [style]="{ width: '11%' }">{{ tableData.givenName }}</td>
            <td [style]="{ width: '11%' }">{{ tableData.emailAddress }}</td>
            <td [style]="{ width: '11%' }">{{ tableData.organizationName }}</td>
            <td [style]="{ width: '11%' }">
              {{ tableData.status === 1 ? "Active" : "Inactive" }}
            </td>
            <td [style]="{ width: '4%' }">
              <i
                class="pi pi-pencil buttonspacing"
                aria-hidden="true"
                (click)="editAttorney('show-large-modal-attorney', tableData)"
              ></i>

              <i
                class="pi pi-trash buttonspacing"
                aria-hidden="true"
                (click)="showModalCustom('show-custom-modal-delete', tableData)"
              ></i>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<div class="text-center a-p-10">
  <div class="overlay" *ngIf="showoverlay"></div>
  <ap-modal
    #baseModalLargest
    appModalId="show-large-modal-attorney"
    style="z-index: 999999"
  >
    <div class="modal-dialog a-modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="a-modal-header">
          <div class="flex-fill">
            <span *ngIf="!onEdit" class="a-h4">Add Attorney</span>
            <span *ngIf="onEdit" class="a-h4">Edit Attorney</span>
          </div>
          <button
            aria-label="Close"
            class="icon-wrapper"
            data-dismiss="modal"
            (click)="closeModalLarge('show-large-modal-attorney')"
          >
            <span class="appkiticon icon-close-fill a-close-btn"></span>
          </button>
        </div>
        <div class="a-modal-body overflow">
          <div class="row">
            <div class="col-6">
              <div class="a-form-label a-mt-20">Family Name</div>
              <input
                [ngStyle]="{
                  'background-color': 'white'
                }"
                maxlength="38"
                aria-label="val1"
                [(ngModel)]="familyName"
                (change)="onChangeEvent($event, 'familyName')"
                class="width-310 a-text-input"
                [ngClass]="{ red: familyNameErrorMsg }"
                required
              />
              <div *ngIf="familyNameError" class="a-form-error a-mt-5 error-msg">
                {{ familyNameErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
              </div>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">Given Name</div>
              <input
                [ngStyle]="{
                  'background-color': 'white'
                }"
                maxlength="18"
                aria-label="val1"
                [(ngModel)]="givenName"
                (change)="onChangeEvent($event, 'givenName')"
                class="width-310 a-text-input"
                [ngClass]="{ red: givenNameErrorMsg }"
              />
              <div *ngIf="givenNameError" class="a-form-error a-mt-5 error-msg">
                {{ givenNameErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
              </div>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">Middle Name</div>
              <input
                [ngStyle]="{
                  'background-color': 'white'
                }"
                maxlength="28"
                aria-label="val1"
                (change)="onChangeEvent($event, 'middleName')"
                class="width-310 a-text-input"
                [(ngModel)]="middleName"
                [ngClass]="{ red: middleNameErrorMsg }"
              />
              <div *ngIf="middleNameErrorMsg" class="a-form-error a-mt-5 error-msg">
                {{ middleNameErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
              </div>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">Street Number and Name</div>
              <input
                [ngStyle]="{
                  'background-color': 'white'
                }"
                aria-label="val1"
                maxlength="25"
                (change)="onChangeEvent($event, 'streetNumberAndName')"
                class="width-310 a-text-input"
                [(ngModel)]="streetNumberAndName"
                [ngClass]="{ red: streetNumberAndNameErrorMsg }"
                required
              />
              <div *ngIf="streetNumberAndNameError" class="a-form-error a-mt-5 error-msg">
                {{ streetNumberAndNameErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
              </div>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">Suite Number</div>
              <input
                [ngStyle]="{
                  'background-color': 'white'
                }"
                aria-label="val1"
                maxlength="4"
                (change)="onChangeEvent($event, 'suiteNumber')"
                class="width-310 a-text-input"
                [(ngModel)]="suiteNumber"
                [ngClass]="{ red: suiteNumberErrorMsg }"
                required
              />
              <div *ngIf="suiteNumberError" class="a-form-error a-mt-5 error-msg">
                {{ suiteNumberErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
              </div>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">City or Town</div>
              <input
                [ngStyle]="{
                  'background-color': 'white'
                }"
                maxlength="20"
                aria-label="val1"
                [(ngModel)]="cityorTown"
                (change)="onChangeEvent($event, 'city')"
                class="width-310 a-text-input"
                [ngClass]="{ red: cityorTownErrorMsg }"
                required
              />
              <div *ngIf="cityorTownError" class="a-form-error a-mt-5 error-msg">
                {{ cityorTownErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
              </div>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">Province</div>
              <input
                [ngStyle]="{
                  'background-color': 'white'
                }"
                maxlength="20"
                aria-label="val1"
                [(ngModel)]="province"
                (change)="onChangeEvent($event, 'province')"
                class="width-310 a-text-input"
                [ngClass]="{ red: provinceErrorMsg }"
                required
              />
              <div *ngIf="provinceError" class="a-form-error a-mt-5 error-msg">
                {{ provinceErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
              </div>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">Postal Code</div>
              <input
                [ngStyle]="{
                  'background-color': 'white'
                }"
                aria-label="val1"
                [(ngModel)]="postalCode"
                (change)="onChangeEvent($event, 'postalCode')"
                class="width-310 a-text-input"
                maxlength="9"
                [ngClass]="{ red: postalCodeErrorMsg }"
                required
              />
              <div *ngIf="postalCodeError" class="a-form-error a-mt-5 error-msg">
                {{ postalCodeErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
              </div>
            </div>

            <div class="col-6">
              <div class="a-form-label a-mt-20">Country</div>
              <input
                [ngStyle]="{
                  'background-color': 'white'
                }"
                aria-label="val1"
                [(ngModel)]="country"
                (change)="onChangeEvent($event, 'country')"
                class="width-310 a-text-input"
                maxlength="29"
                [ngClass]="{ red: countryErrorMsg }"
                required
              />
              <div *ngIf="countryError" class="a-form-error a-mt-5 error-msg">
                {{ countryErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
              </div>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">Daytime Telephone Number</div>
              <input
                [ngStyle]="{
                  'background-color': 'white'
                }"
                aria-label="val1"
                (change)="onChangeEvent($event, 'daytimeTelephoneNumber')"
                class="width-310 a-text-input"
                maxlength="10"
                [(ngModel)]="daytimeTelephoneNumber"
                [ngClass]="{ red: daytimeTelephoneNumberErrorMsg }"
                required
              />
              <div
                *ngIf="daytimeTelephoneNumberError"
                class="a-form-error a-mt-5 error-msg"
              >
                {{ daytimeTelephoneNumberErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
              </div>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">Email Address</div>
              <input
                [ngStyle]="{
                  'background-color': 'white'
                }"
                aria-label="val1"
                (change)="onChangeEvent($event, 'emailAddress')"
                class="width-310 a-text-input"
                [(ngModel)]="emailAddress"
                [ngClass]="{ red: emailAddressErrorMsg }"
                required
              />
              <div *ngIf="emailAddressError" class="a-form-error a-mt-5 error-msg">
                {{ emailAddressErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
              </div>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">Organization</div>
              <p-dropdown
                [ngStyle]="{
                  'background-color': 'white'
                }"
                optionValue="organization"
                filter="false"
                [options]="unAllocatedOrganization"
                [(ngModel)]="organizationCode"
                placeholder="Select Organization"
                optionLabel="organizationName"
                scrollHeight="90px"
                [style]="{ width: '-webkit-fill-available', height: '35px' }"
                (onChange)="onChangeEvent($event, 'organization')"
                [ngClass]="{ red: organizationErrorMsg }"
              >
              </p-dropdown>
              <div *ngIf="organizationErr" class="a-form-error a-mt-5 error-msg">
                {{ organizationErrorMsg }}<span class="appkiticon icon-information-fill error-icon-dropdown"></span>
              </div>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">Fax Number</div>
              <input
                [ngStyle]="{
                  'background-color': 'white'
                }"
                maxlength="10"
                aria-label="val1"
                [(ngModel)]="faxNumber"
                (change)="onChangeEvent($event, 'faxNumber')"
                class="width-310 a-text-input"
                [ngClass]="{ red: faxNumberErrorMsg }"
                required
              />
              <div *ngIf="faxNumberError" class="a-form-error a-mt-5 error-msg">
                {{ faxNumberErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
              </div>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">Licensing Authority</div>
              <input
                [ngStyle]="{
                  'background-color': 'white'
                }"
                aria-label="val1"
                [(ngModel)]="licensingAuthority"
                (change)="onChangeEvent($event, 'licensingAuthority')"
                class="width-310 a-text-input"
                maxlength="36"
                [ngClass]="{ red: licensingAuthorityErrorMsg }"
                required
              />
              <div *ngIf="licensingAuthorityError" class="a-form-error a-mt-5 error-msg">
                {{ licensingAuthorityErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
              </div>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">Bar Number</div>
              <input
                maxlength="9"
                [ngStyle]="{
                  'background-color': 'white'
                }"
                aria-label="val1"
                [(ngModel)]="barNumber"
                (change)="onChangeEvent($event, 'barNumber')"
                class="width-310 a-text-input"
                [ngClass]="{ red: barNumberErrorMsg }"
                required
              />
              <div *ngIf="barNumberError" class="a-form-error a-mt-5 error-msg">
                {{ barNumberErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
              </div>
            </div>
            <div class="col-6">
              <div class="a-form-label a-mt-20">Name of Law Firm</div>
              <input
                [ngStyle]="{
                  'background-color': 'white'
                }"
                aria-label="val1"
                [(ngModel)]="lawFirmName"
                (change)="onChangeEvent($event, 'lawFirmName')"
                class="width-310 a-text-input"
                maxlength="30"
                [ngClass]="{ red: lawFirmNameErrorMsg }"
                required
              />
              <div *ngIf="lawFirmNameError" class="a-form-error a-mt-5 error-msg">
                {{ lawFirmNameErrorMsg }}<span class="appkiticon icon-information-fill error-icon"></span>
              </div>
            </div>

            <div class="col-6 a-mt-30">
              <span class="a-mr-10" [ngStyle]="{ 'font-size': '0.85rem' }"
                >Status</span
              >
              <!-- <label class="statusSlider a-toggle a-toggle-text" tabindex="7">
                <input
                  name="attorneyStatus"
                  [checked]="status"
                  type="checkbox"
                  [(ngModel)]="status"
                />
                <div class="a-toggle-mark">
                  <span class="on">Active&nbsp;&nbsp; </span>
                  <span class="off">Disabled</span>
                  <span class="switch"></span>
                </div>
              </label> -->
              <label class="statusSlider a-toggle a-toggle-text a-m-5" tabindex="7">
                <input type="checkbox"
                name="attorneyStatus"
                  [checked]="status"
                  [(ngModel)]="status"
                #statusToggle>
                <div class="a-toggle-mark">
                    <span class="on"></span>
                    <span class="off"></span>
                    <span class="switch"></span>
                </div>
                <span *ngIf="statusToggle.checked" class="a-m-10 status-active">Active</span>
                <span *ngIf="!statusToggle.checked" class="a-m-10 status-disabled">Disabled</span>
            </label>
            </div>
          </div>
        </div>
        <div class="a-modal-footer">
          <div class="flex-fill text-right a-font-12 a-text-grey-medium">
            <button
              aria-label="Close"
              class="a-btn a-btn-transparent a-btn-lg a-btn-gray a-mr-10"
              data-dismiss="modal"
              (click)="closeModalLarge('show-large-modal-attorney')"
            >
              CANCEL
            </button>
            <button
              aria-label="Close"
              class="a-btn a-btn-transparent a-btn-lg"
              data-dismiss="modal"
              (click)="addAttorney('show-large-modal-attorney')"
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  </ap-modal>

  <div class="overlay" *ngIf="showoverlay"></div>
  <ap-modal
    #baseModalCustom
    appModalId="show-custom-modal-delete"
    style="z-index: 999"
  >
    <div
      class="modal-dialog a-modal-lg modal-dialog-centered modal-sm"
      role="document"
    >
      <div class="modal-content">
        <div class="a-modal-header">
          <div class="flex-fill d-flex align-items-center">
            <div class="a-h4">Confirmation</div>
          </div>

          <button
            aria-label="Close"
            class="icon-wrapper"
            data-dismiss="modal"
            (click)="closeModalCustom('show-custom-modal-delete')"
          >
            <span class="appkiticon icon-close-fill a-close-btn"></span>
          </button>
        </div>
        <div class="a-modal-body pt-0">
          <div class="a-p4 a-mt-10 text-description">
            <span class="appkiticon icon-alert-outline"></span> &nbsp; Are you
            sure you want to delete the Attorney ({{ name }})?
          </div>
        </div>
        <div class="a-modal-footer a-border-tp">
          <div class="flex-fill text-left a-font-12 a-text-grey-medium"></div>
          <button
            aria-label="Close"
            class="a-btn a-btn-transparent a-btn-lg a-btn-gray a-mr-10"
            data-dismiss="modal"
            (click)="closeModalCustom('show-custom-modal-delete')"
          >
            NO
          </button>
          <button
            aria-label="Close"
            class="a-btn a-btn-transparent a-btn-lg"
            data-dismiss="modal"
            (click)="deleteAttorney('show-custom-modal-delete')"
          >
            YES
          </button>
        </div>
      </div>
    </div>
  </ap-modal>
</div>
