<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="a-pt-10">
  <div
    *ngIf="!isCompleted"
    class="info-text a-font-14 a-mb-10 a-p-10 a-shadow-sm"
  >
    <ol class="aos-instructions">
      <li>
        Only the following file formats are allowed to be attached [.jpg, jpeg,
        .png, .gif, .tiff, .doc, .docx, .pdf]
      </li>
      <li>
        For any of the document types listed below - if you have more than one
        document to provide, please merge the documents into
        <u><b>one</b></u> consolidated document prior to uploading.
      </li>
      <li>
        Select the file to be attached and click the ‘upload selected files(s)’
        button at the bottom of the screen to upload the selected file.
      </li>
    </ol>
  </div>
  <form
    *ngIf="documentsForm"
    [formGroup]="documentsForm"
    (submit)="submitDoc()"
  >
    <div *ngFor="let item of instances; index as i">
      <p-accordion>
        <p-accordionTab
          [header]="name[item].name"
          [selected]="item === 'Primary'"
        >
          <div class="a-table-wrapper">
            <div class="a-panel code-wrapper table dark tboard-table">
              <p-table
                [value]="documentsList[item]"
                styleClass="p-datatable-striped"
                scrollHeight="200px"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th [style]="{ width: '20%' }">Mandatory</th>
                    <th [style]="{ width: '30%' }">Document Type</th>
                    <!-- <th [style]="{ width: '20%' }">
                    Possible Translation Required
                  </th> -->
                    <th [style]="{ width: '20%' }">File Name</th>
                    <th [style]="{ width: '5%' }"></th>
                    <th [style]="{ width: '5%' }"></th>
                    <th [style]="{ width: '5%' }"></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-document let-index="rowIndex">
                  <tr>
                    <td
                      [style]="{ width: '20%' }"
                      [ngClass]="{
                        'font-weight-medium': document.isMandatory === 'Yes'
                      }"
                    >
                      {{ document.isMandatory }}
                    </td>
                    <td
                      [style]="{ width: '30%' }"
                      [ngClass]="{
                        'font-weight-medium': document.isMandatory === 'Yes'
                      }"
                    >
                      {{ document.documentTitle }}
                    </td>
                    <!-- <td [style]="{ width: '20%' }">
                    {{ document.translation }}
                  </td> -->
                    <td [style]="{ width: '20%' }">{{ document.fileName }}</td>
                    <td [style]="{ width: '5%' }">
                      <input
                        type="file"
                        #filePicker
                        (change)="
                          showModalForFile(
                            'show-custom-modal-fileModalCustom',
                            $event,
                            item,
                            document
                          )
                        "
                      />
                    </td>
                    <td [style]="{ width: '5%' }" class="text-center">
                      <i
                        *ngIf="!isCompleted"
                        class="pi pi-upload buttonspacing"
                        title="Upload"
                        (click)="filePicker.click()"
                      ></i>
                    </td>
                    <td [style]="{ width: '5%' }" class="text-center">
                      <i
                        *ngIf="!isCompleted"
                        class="pi pi-trash buttonspacing"
                        aria-hidden="true"
                        title="Delete"
                        (click)="showModal('show-custom-modal', item, document)"
                      ></i>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>

    <!-- <ap-accordions [isGreyBackground]="false" [isTransparentAccordion]="true">
      <ap-accordions-children-item
        *ngFor="let item of instances; let i = index"
        [isIconLeft]="true"
        [index]="i"
        (onItemChange)="itemChange($event)"
        [title]="item"
      >
        <div class="a-accordion-content">
          <div class="a-pb-12">
            <div class="a-table-wrapper">
              <div class="a-panel a-mt-20 a-p-20 code-wrapper table dark">
                <p-table
                  [value]="documentsList[item]"
                  styleClass="p-datatable-striped"
                  scrollHeight="200px"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th>Mandatory</th>
                      <th>Document Type</th>
                      <th>Possible Translation Required</th>
                      <th>File Name</th>
                      <th></th>
                      <th [style]="{ width: '5%' }"></th>
                      <th [style]="{ width: '5%' }"></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-document let-index="rowIndex">
                    <tr>
                      <td>{{ document.mandatory }}</td>
                      <td>{{ document.docTitle }}</td>
                      <td>{{ document.translation }}</td>
                      <td>{{ document.fileName }}</td>
                      <td>
                        <input type="file" #filePicker (change)="onFileSelected($event, item, document)">
                      </td>
                      <td [style]="{ width: '5%' }">
                        <i
                          class="fa fa-upload buttonspacing"
                          aria-hidden="true"
                          (click)="filePicker.click()"
                        ></i>
                      </td>
                      <td [style]="{ width: '5%' }">
                        <i
                          class="fa fa-trash buttonspacing"
                          aria-hidden="true"
                          (click)="deleteDoc($event)"
                        ></i>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>
      </ap-accordions-children-item>
    </ap-accordions> -->
    <div class="d-flex">
      <div class="p-2 mr-auto">
        <button
          class="align-left"
          type="button"
          *ngIf="isDraft"
          class="a-btn a-btn-primary a-btn-lg a-mt-20"
          (click)="formsTab()"
        >
          Back to Form
        </button>
      </div>
      <div class="p-2">
        <div class="button-align">
          <button
            *ngIf="!isCompleted"
            [disabled]="inValid"
            class="a-btn a-btn-primary a-btn-lg a-mt-20 a-mr-10"
            type="submit"
          >
            Upload selected file(s)
          </button>
          <button
            type="button"
            *ngIf="isDraft"
            [disabled]="!isSubmit"
            class="a-btn a-btn-primary a-btn-lg a-mt-20"
            (click)="showMoreCustom('show-custom-modal')"
          >
            Submit Questionnaire
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="overlay" *ngIf="showoverlay"></div>
<ap-modal
  #baseModalCustom
  appModalId="show-custom-modal"
  style="z-index: 999999"
>
  <div
    class="modal-dialog a-modal-lg modal-dialog-centered modal-sm"
    role="document"
  >
    <div class="modal-content">
      <div class="a-modal-header">
        <div class="flex-fill d-flex align-items-center">
          <div class="a-h4">Confirmation</div>
        </div>

        <button
          aria-label="Close"
          class="icon-wrapper"
          data-dismiss="modal"
          (click)="closeModalCustom('show-custom-modal')"
        >
          <span class="appkiticon icon-close-fill a-close-btn"></span>
        </button>
      </div>
      <div class="a-modal-body pt-0">
        <!-- <div *ngIf="!isDelete" class="a-p4 a-mt-10 text-description">
          <span class="appkiticon icon-alert-outline"></span> &nbsp; By
          submitting the form, you will not be able to make any further edits to
          the questionnaire, add any additional dependents or upload their
          supporting documents. Are you sure you want to submit?
        </div> -->
        <div 
          *ngIf="!isDelete" 
          class="a-p-10 a-mt-10 a-font-14 confirm-button"
          (click)="submitForm('show-custom-modal')">
          I confirm that I have completed a questionnaire for myself (and any dependent applying with me) and uploaded all relevant documents.
        </div>
        <div 
          *ngIf="!isDelete" 
          class="a-p-10 a-mt-10 a-font-14 a-border-danger cancel-button"
          (click)="closeModalCustom('show-custom-modal')">
          I need to go back and add/modify a questionnaire and upload any missing supporting documents.
        </div>
        <div *ngIf="isDelete" class="a-p4 a-mt-10 text-description">
          <span class="appkiticon icon-alert-outline"></span> &nbsp; Are you
          sure you want to delete the attachment?
        </div>
      </div>
      <div *ngIf="isDelete" class="a-modal-footer a-border-tp">
        <div class="flex-fill text-left a-font-12 a-text-grey-medium"></div>
        <button
          aria-label="Close"
          class="a-btn a-btn-transparent a-btn-lg a-btn-gray a-mr-10"
          data-dismiss="modal"
          (click)="closeModalCustom('show-custom-modal')"
        >
          NO
        </button>
        <button
          aria-label="Close"
          class="a-btn a-btn-transparent a-btn-lg"
          data-dismiss="modal"
          (click)="deleteDoc('show-custom-modal')"
        >
          YES
        </button>
      </div>
    </div>
  </div>
</ap-modal>

<div class="overlay" *ngIf="showoverlay"></div>
<ap-modal
  #fileModalCustom
  appModalId="show-custom-modal-fileModalCustom"
  style="z-index: 999999"
>
  <div
    class="modal-dialog a-modal-lg modal-dialog-centered modal-sm"
    role="document"
  >
    <div class="modal-content">
      <div class="a-modal-header">
        <div class="flex-fill d-flex align-items-center">
          <div class="a-h4">Confirmation</div>
        </div>

        <button
          aria-label="Close"
          class="icon-wrapper"
          data-dismiss="modal"
          (click)="closeFileModal('show-custom-modal-fileModalCustom')"
        >
          <span class="appkiticon icon-close-fill a-close-btn"></span>
        </button>
      </div>
      <div class="a-modal-body pt-0">
        <div *ngIf="isFileSelected" class="a-p4 a-mt-10 text-description">
          <span class="appkiticon icon-alert-outline"></span> &nbsp; The
          existing file will be replaced if you choose to upload a new one, do
          you wish to continue?
        </div>
      </div>
      <div class="a-modal-footer a-border-tp">
        <div class="flex-fill text-left a-font-12 a-text-grey-medium"></div>
        <button
          aria-label="Close"
          class="a-btn a-btn-transparent a-btn-lg a-btn-gray a-mr-10"
          data-dismiss="modal"
          (click)="closeFileModal('show-custom-modal-fileModalCustom')"
        >
          NO
        </button>
        <button
          *ngIf="isFileSelected"
          aria-label="Close"
          class="a-btn a-btn-transparent a-btn-lg"
          data-dismiss="modal"
          (click)="
            onFileSelected(
              fileEvent,
              instanceName,
              docSelected,
              'show-custom-modal-fileModalCustom'
            )
          "
        >
          YES
        </button>
      </div>
    </div>
  </div>
</ap-modal>
