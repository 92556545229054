import { Component, OnInit, Input } from '@angular/core';
import { FormFieldSpec } from 'src/app/services/form-service.service';

@Component({
  selector: 'field-required-marker',
  templateUrl: './field-required-marker.component.html',
  styleUrls: ['./field-required-marker.component.scss']
})
export class FieldRequiredMarkerComponent implements OnInit {

  constructor() { }

  @Input() field : FormFieldSpec;

  ngOnInit(): void {
  }

}
