// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const endpointUrls = {
    authenticateToken: '/api/Authorize/Login',
    exchangeCodeWithToken: '/api/Authorize/Authenticate',
    getAllUsers: '/api/v1/Admins/GetAllUser',
    addUser: '/api/v1/Users/AddUser',
    editUser: '/api/v1/Users/ModifyUser',
    directorySearchUsingEmail: '/api/v1/Directory/DirectorySearchUsingEmail',
    deleteUser: '/api/v1/Users/DeleteUser',
    getQuestionnaires: '/api/v1/Questionare/GetAllQuestions',
    refreshToken: '/api/Authorize/RefreshToken',
    submitResponse: '/api/v1/Questionare/SubmitResponse',
    saveResponse: 'api/v1/Questionare/SaveResponse',
    deleteDraftResponse: 'api/v1/Questionare/DeleteDraftResponse',
    getSubmittedResponse: 'api/v1/Questionare/GetSubmittedResponse',
    fileUpload: '/api/v1/FileManager/UploadFile',
    applicationStatus: '/api/v1/Questionare/GetCurrentApplicationStatus',
    getDocumentsTemplate: '/api/v1/FileManager/GetDocumentsTemplate',
    getUserAttachments: '/api/v1/FileManager/GetUserDocuments',
    GetGeneratedFormsDetails: '/api/v1/FileManager/GetGeneratedFormsDetails',
    generateFormupload: '/api/v1/FileManager/UploadForms',
    deleteAttachments: '/api/v1/FileManager/DeleteAttachments',
    DownloadFormspdf: '/api/v1/FileManager/DownloadForms',
    validateMiMsIdCaseIdentifier: '/api/v1/FileManager/ValidateMiMsIdCaseIdentifier',
    getCountries: '/api/v1/Users/GetCountries',
    logoutUrl: '/api/v1/Admins/Logout',
    submitToPwC: '/api/v1/Questionare/SubmitToPwC',
    validateSession: '/api/Authorize/ValidateSession',
    organization: '/api/v1/Organization',
    AddOrganization: '/api/v1/Organization/Add',
    editOrganization: '/api/v1/Organization/Update',
    getUnAllocatedOrganizations: '/api/v1/Organization/GetUnAllocatedOrganizations',
    getAttorney: '/api/v1/Attorney/GetAttorneys',
    AddAttorney: '/api/v1/Attorney/Add',
    editAttorney: '/api/v1/Attorney/Update',
    getAttorneySpecificOrganizations: '/api/v1/Organization/GetAttorneySpecificOrganizations',
    deleteAttorney: '/api/v1/Attorney/Delete/',
    deleteOrganization: '/api/v1/Organization/Delete/',
    downloadBulkUploadTemplate: '/api/v1/Users/DownloadBulkUploadTemplate',
    //downloadBulkUploadTemplate: '​/api/v1/Users/DownloadBulkUploadTemplate',
    bulkAddUser: '/api/v1/Users/BulkAddUser',
    downloadErrorFile: '/api/v1/FileManager/DownloadErrorFile',
    downloadGUMFile: '/api/v1/FileManager/DownloadGUMFile',
    getBulkUploadStatus: '/api/v1/Users/GetBulkUploadStatus',
    getDownTimeDetails: '/api/v1/Admins/GetDowntimeDetails',
    statusChange: '/api/v1/StatusChange/UpdateStatus'
};

export const timeoutSetting = {
    warnAfterIdleTime: 600,
    logoutAfterWarnTime: 300
};

export const environment = {
    production: false,
    local: {
        //backendURL: 'https://myaos-api-dev.vialto.com',
        backendURL: 'https://localhost:44354',
        oldURL : 'https://localhost:4500/',
        authConfig: {
            //   authURL: 'https://login-stg.pwc.com/openam/oauth2/authorize',
            //   clientId: 'urn:ca-il-aos-stg',
            //   scope: 'openid profile email cloudEmail preferredMail',
            //   redirectURL: 'https://localhost:4500',
            //   signoutURL: 'https://login-stg.pwc.com/openam/XUI/#logout/'
            //authURL: 'https://dev.vialto-dev.auth0app.com/authorize',
            authURL: 'https://dev.vialto-dev.auth0app.com/authorize',
            clientId: 'wRkncyNvEfUJYrg8d76ITCFoVbDfHdbJ',
            //scope: 'openid',
            scope: 'openid email',         
            //redirectURL: 'https://localhost:44354/api/Authorize/Login',
            redirectURL: 'https://localhost:4500'
            //signoutURL: 'https://fedsvc-stage.pwc.com/ofiss/public/SignOut.aspx'

        }
    },
    dev: {
         backendURL: 'https://myaos-apis-dev.vialto.com',
        //backendURL: 'https://localhost:44354',
        //backendURL: 'https://localhost:443',
        oldURL: 'https://myaos-dev.vialto.com/login/',
        authConfig: {
            // authURL: 'https://login-stg.pwc.com/openam/oauth2/authorize',
            // clientId: 'urn:ca-il-aos-stg',
            // scope: 'openid profile email cloudEmail preferredMail',
            // redirectURL: 'https://ca-tax-ilaos-ui-dev.azurewebsites.net',
            // signoutURL: 'https://login-stg.pwc.com/openam/XUI/#logout/'
            authURL: 'https://dev.vialto-dev.auth0app.com/authorize',
            clientId: 'wRkncyNvEfUJYrg8d76ITCFoVbDfHdbJ',
            scope: 'openid email',
            redirectURL: 'https://myaos-dev.vialto.com/login/',
            signoutURL: 'https://dev.vialto-dev.auth0app.com/v2/logout?federated&client_id=wRkncyNvEfUJYrg8d76ITCFoVbDfHdbJ&returnTo=https://myaos-dev.vialto.com/'
        }
    },
    qa: {
        backendURL: 'https://myaos-api-qa.ca.pwc.com',
        oldURL:'https://ca-tax-ilaos-ui-qa.azurewebsites.net/',
        authConfig: {
            // authURL: 'https://login-stg.pwc.com/openam/oauth2/authorize',
            // clientId: 'urn:ca-il-aos-stg',
            // scope: 'openid profile email cloudEmail preferredMail',
            // redirectURL: 'https://ca-tax-ilaos-ui-qa.azurewebsites.net',
            // signoutURL: 'https://login-stg.pwc.com/openam/XUI/#logout/'
            authURL: 'https://dev.vialto-dev.auth0app.com/authorize',
            clientId: 'wRkncyNvEfUJYrg8d76ITCFoVbDfHdbJ',
            scope: 'openid',
            redirectURL: 'window.location.origin',
            signoutURL: 'https://fedsvc-stage.pwc.com/ofiss/public/SignOut.aspx'
        }
    },
    stage: {
        backendURL: 'https://myaos-api-stage.vialto.com',
        oldURL:'https://myaos-stage.ca.pwc.com',
        authConfig: {
            // authURL: 'https://login-stg.pwc.com/openam/oauth2/authorize',
            // clientId: 'urn:ca-il-aos-stg',
            // scope: 'openid profile email cloudEmail preferredMail',
            // redirectURL: 'https://myaos-stage.vialto.com',
            // signoutURL: 'https://login-stg.pwc.com/openam/XUI/#logout/'
            authURL: 'https://dev.vialto-dev.auth0app.com/authorize',
            clientId: 'wRkncyNvEfUJYrg8d76ITCFoVbDfHdbJ',
            scope: 'openid',
            redirectURL: 'window.location.origin',
            signoutURL: 'https://fedsvc-stage.pwc.com/ofiss/public/SignOut.aspx'
        }
    },
    prod: {
           backendURL: 'https://myaos-api-dev.vialto.com',
        //backendURL: 'https://myaos-api.vialto.com',
        oldURL:'https://myaos.ca.pwc.com',
        authConfig: {
            // authURL: 'https://login.pwc.com/openam/oauth2/authorize',
            // clientId: 'urn:ca-il-aos-prod',
            // scope: 'openid profile email cloudEmail preferredMail',
            // redirectURL: 'https://myaos.vialto.com',
            // signoutURL: 'https://login.pwc.com/openam/XUI/#logout/'
            authURL: 'https://dev.vialto-dev.auth0app.com/authorize',
            clientId: 'wRkncyNvEfUJYrg8d76ITCFoVbDfHdbJ',
            scope: 'openid',
            redirectURL: 'window.location.origin',
            signoutURL: 'https://fedsvc.pwc.com/ofis/public/SignOut.aspx'
        }
    }
    
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  //Included with Angular CLI.
