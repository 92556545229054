<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>

<div class="a-progress-fun d-flex a-mt-10" role="progressbar">
  <div class="a-mr-20">
  </div>
  <div
    class="a-progress-text a-vertical-progress-text mt-0 justify-content-start"
  >
    <div
      class="a-indicator"
      *ngFor="let instance of formInstances; let i = index"
    >
      <p-panel
        [toggleable]="!(!this.isSubmitted && this.currentFormGroup.invalid)"
        (onBeforeToggle)="collapseInstance(instance, $event)"
        [collapsed]="instance != currentInstance"
      >
        <p-header>
          <button
            aria-expanded="true"
            class="a-parent-indicator"
            data-target="#collapseExample1"
            data-toggle="collapse"
            (click)="instanceClicked(instance, $event)"
          >
            <h6>
              {{
                instance.isDependent
                  ? "Dependent " + formInstances.indexOf(instance)
                  : "Primary"
              }}
            </h6>

            <button
              class="a-btn a-btn-primary a-btn-lg a-btn-negative a-ml-20 clear"
              *ngIf="!isSubmitted && instance.isDependent"
              (click)="confirmInstanceDelete(instance)"
            >
              X
            </button>
          </button>
        </p-header>
        <div
          class="a-child-indicator collapse"
          [class.show]="instance == currentInstance"
          id="collapseExample1"
        >
          <div
            class="a-child-indicator-item aos-link"
            [ngClass]="getSectionCss(instance, section)"
            *ngFor="let section of instance.formSections; let i = index"
            (click)="setSection(section)"
          >
            {{ section.sectionId }} - {{ section.sectionName }}
          </div>
        </div>
      </p-panel>
    </div>
  </div>
</div>

<div class="a-p-10 text-center">
  <ap-modal #modalConfirmDelete appModalId="modal-confirm-delete">
    <div id="modalConfirmDelete_content">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="a-modal-header" *ngIf="instanceForDeletion">
            <div class="flex-fill">
              <h6>Are you sure?</h6>
            </div>
          </div>
          <!-- Only dependent instances can be deleted -->
          <div class="a-modal-body" *ngIf="instanceForDeletion">
            Do you want to delete the form instance "Dependent
            {{ formInstances.indexOf(instanceForDeletion) }}"?
          </div>
          <div class="a-modal-footer justify-content-end">
            <button
              aria-label="Close"
              class="a-btn a-btn-transparent a-btn-lg a-btn-gray a-mr-10"
              data-dismiss="modal"
              (click)="instanceDeleteClose(false)"
            >
              No
            </button>
            <!--(click)="closeModalCustom('show-custom-modal')"-->
            <button
              aria-label="Close"
              class="a-btn a-btn-transparent a-btn-lg"
              data-dismiss="modal"
              (click)="instanceDeleteClose(true)"
            >
              Yes
            </button>
            <!--(click)="closeModalCustomOk('show-custom-modal')"-->
          </div>
        </div>
      </div>
    </div>
  </ap-modal>
</div>
