<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div *ngIf="!isLoading">
  <div *ngIf="loadingError$ | async">Failure Message</div>

  <div class="d-flex a-mt-20">
    <div class="col-8 cardcenter">
      <div class="a-panel a-p-20">
        <div class="a-text-heading a-font-13">
          <p class="header default-font-size">
            Main Menu
            <span
              class="duedate"
              *ngIf="(isNewApplication || isDraftApplication) && dueDate"
            >
              Due Date : &nbsp;
              <div class="a-badge a-badge-info">
                {{ dueDate | date: "MM/dd/yyyy" }}
              </div>
            </span>
          </p>
        </div>

        <div class="a-text-caption font-weight-small a-mt-10 a-text-616">
          <li class="a-list-item a-p-10 d-flex align-items-center">
            <span class="appkiticon a-h3 icon-news-outline a-mr-10"></span>
            <div>
              <div *ngIf="isNewApplication" class="fontweight">
                Start New Application to Register Permanent Residence or Adjust
                Status
              </div>
              <div *ngIf="isDraftApplication" class="fontweight">
                Continue Editing Draft Application.
              </div>
              <div *ngIf="isSubmittedApplication" class="fontweight">
                View Completed/Submitted Application.
                <span class="applicationStatus a-mr-10">
                  Questionnaire Status : &nbsp;
                  <div class="a-badge a-badge-info">
                    {{ applicationStatus }}
                  </div>
                </span>
              </div>
            </div>
          </li>
        </div>

        <div class="d-flex a-mt-20">
          <button
            class="a-btn a-btn-secondary a-mr-5 a-btn-lg"
            *ngIf="isDraftApplication"
            (click)="showModal('show-custom-modal-draft')"
          >
            Discard Draft
          </button>
          <button class="a-btn a-btn-primary a-btn-lg" (click)="onNext()">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="overlay" *ngIf="showoverlay"></div>
<ap-modal
  #baseModalCustom
  appModalId="show-custom-modal-draft"
  style="z-index: 999999"
>
  <div
    class="modal-dialog a-modal-lg modal-dialog-centered modal-sm"
    role="document"
  >
    <div class="modal-content">
      <div class="a-modal-header">
        <div class="flex-fill d-flex align-items-center">
          <div class="a-h4">Confirmation</div>
        </div>

        <button
          aria-label="Close"
          class="icon-wrapper"
          data-dismiss="modal"
          (click)="closeModalCustom('show-custom-modal-draft')"
        >
          <span class="appkiticon icon-close-fill a-close-btn"></span>
        </button>
      </div>
      <div class="a-modal-body pt-0">
        <div class="a-p4 a-mt-10 text-description">
          <span class="appkiticon icon-alert-outline"></span> &nbsp; Are you
          sure you want to discard the draft ?
        </div>
      </div>
      <div class="a-modal-footer a-border-tp">
        <div class="flex-fill text-left a-font-12 a-text-grey-medium"></div>
        <button
          aria-label="Close"
          class="a-btn a-btn-transparent a-btn-lg a-btn-gray a-mr-10"
          data-dismiss="modal"
          (click)="closeModalCustom('show-custom-modal-draft')"
        >
          NO
        </button>
        <button
          aria-label="Close"
          class="a-btn a-btn-transparent a-btn-lg"
          data-dismiss="modal"
          (click)="discardDraft('show-custom-modal-draft')"
        >
          YES
        </button>
      </div>
    </div>
  </div>
</ap-modal>
