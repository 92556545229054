import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  isLoggingIn = false;
  showDownTime = false;
  downTimeMessage = '';
  executionEnvironment ='local';
  oldURL='';
  constructor(private authService: AuthService, private router: Router) {
    if (environment.local.authConfig.redirectURL.includes(window.location.origin) ||environment.local.oldURL.includes(window.location.origin)) {
      this.executionEnvironment = 'local';
    } else if (
      environment.dev.authConfig.redirectURL.includes(window.location.origin)||environment.dev.oldURL.includes(window.location.origin)
    ) {
      this.executionEnvironment = 'dev';
    } else if (
       environment.qa.authConfig.redirectURL.includes(window.location.origin)||environment.qa.oldURL.includes(window.location.origin)
    ) {
      this.executionEnvironment = 'qa';
    } else if (
     environment.stage.authConfig.redirectURL.includes(window.location.origin)||environment.stage.oldURL.includes(window.location.origin)
    ) {
      this.executionEnvironment = 'stage';
    } else if (
     environment.prod.authConfig.redirectURL.includes(window.location.origin)|| environment.prod.oldURL.includes(window.location.origin)
    ) {
      this.executionEnvironment = 'prod';
    }
    this.oldURL=environment[this.executionEnvironment].oldURL;
   }

  ngOnInit(): void {
    console.log('Login Component INIT...');
    this.isLoggingIn = this.authService.getIsLoggingIn();
    console.log('isLoggingIn', this.isLoggingIn);
    if(window.location.origin.includes(this.oldURL)){
      this.isLoggingIn=true;
    }

    this.getDownTimeDetails();
  }

  ngOnDestroy(): void {
    console.log('Login Component DESTROY...');
  }

  login(): void {
    if (this.showDownTime) {
      return;
    } else {
      this.authService.login();
    }
  }

  getDownTimeDetails() {
    this.showDownTime = false;
    this.authService.getDownTime().subscribe((response) => {
      if (response.status) {
        this.showDownTime = true;
        this.downTimeMessage = response.message;
      }
    },
    (error) => {
      console.log('ERROR when retrieving Downtime details', error);
    });
  }

}
