import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject, forkJoin } from 'rxjs';
import { AuthService } from './auth.service';
import { endpointUrls } from '../../environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  private documentsList: {
    documentName: string;
    documentTitle: string;
    isMandatory: string;
    translation: string;
    documentTypeID: number;
    isOnServer: boolean;
  }[];
  private documentsFormGroupListener = new Subject<FormGroup>();
  uploadSuccess = new Subject<any>();
  uploadedAttachments: {
    dependentType: string;
    getDocumentsLists: { documentTypeId: number; fileName: string }[];
  }[];
  tabChanges = new Subject<FormGroup>();
  validForms = new Subject<FormGroup>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) {}

  getDocumentsFormGroupListener(): Observable<FormGroup> {
    return this.documentsFormGroupListener.asObservable();
  }

  getUploadSuccess(): Observable<any> {
    return this.uploadSuccess.asObservable();
  }

  getDocumentsList(
    instances: any[]
  ): {
    [key: string]: {
      documentName: string;
      documentTitle: string;
      isMandatory: string;
      translation: string;
      documentTypeID: string;
      fileName: string;
      isOnServer: boolean;
    }[];
  } {
    const documentList = {};
    for (const instance of instances) {
      documentList[instance] = [...this.documentsList].map((obj) => {
        let attachedFileName = null;
        if (this.uploadedAttachments && this.uploadedAttachments.length > 0) {
          const instanceAttachmentsObj = this.uploadedAttachments.filter(
            (instanceDocs) => instanceDocs.dependentType === instance
          );
          if (instanceAttachmentsObj && instanceAttachmentsObj.length > 0) {
            const instanceAttachments =
              instanceAttachmentsObj[0].getDocumentsLists;
            if (instanceAttachments && instanceAttachments.length > 0) {
              const previouslyAttachedDoc = instanceAttachments.filter(
                (attachedDocument) =>
                  attachedDocument.documentTypeId === obj.documentTypeID
              );
              if (previouslyAttachedDoc && previouslyAttachedDoc.length > 0) {
                attachedFileName = previouslyAttachedDoc[0].fileName;
              }
            }
          }
        }
        return {
          ...obj,
          fileName: attachedFileName,
        };
      });
    }
    return documentList;
  }

  fileUpload(instances: any[], documentsForm: FormGroup): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
      const backendURL = executionEnvData.backendURL;
      let params = '';
      let fileUploadURL = '';
      let fileUploadEndpoint = '';

      const uploadCalls = [];
      for (const instance of instances) {
        const fileUploadData = new FormData();
        params = '?familydependentName=' + instance;
        fileUploadURL = endpointUrls.fileUpload + params;
        fileUploadEndpoint = backendURL + fileUploadURL;
        for (const document of this.documentsList) {
          const docFormControl = documentsForm.get(instance + '.' + document.documentName);
          if (docFormControl) {
            const file = docFormControl.value as File;
            console.log(
              'Value for ' + instance + '.' + document.documentName,
              file,
              typeof file
            );
            if (file && typeof file === 'object') {
              console.log(
                '**Uploading ' + instance + '.' + document.documentName,
                file
              );
              fileUploadData.append(
                document.documentTypeID.toString(),
                file,
                file.name
              );
            }
          }
        }
        uploadCalls.push(this.http.post(fileUploadEndpoint, fileUploadData));
      }

      console.log('Starting upload....');
      forkJoin(uploadCalls)
        .pipe(take(1))
        .subscribe(
          (response) => {
            console.log('All files uploaded successfully');
            // this.router.navigate(['/']);
            this.uploadSuccess.next();
            resolve();
          },
          (error) => {
            console.log('Upload failed', error);
            reject();
          }
        );
    });
    return promise;
  }

  toFormGroup(instances: any[]): void {
    console.log('Constructing form group', instances);
    forkJoin([this.fetchDocumentList(), this.getUserAttachments()]).pipe(take(1)).subscribe(
      (results) => {
        this.documentsList = [...results[0]];
        this.uploadedAttachments = results[1];

        const group: any = {};
        for (const instance of instances) {
          const nestedControl: any = {};
          for (const document of this.documentsList) {
            // START: pre-populate form
            let attachedFileName = '';
            if (
              this.uploadedAttachments &&
              this.uploadedAttachments.length > 0
            ) {
              const instanceAttachmentsObj = this.uploadedAttachments.filter(
                (instanceDocs) => instanceDocs.dependentType === instance
              );
              if (instanceAttachmentsObj && instanceAttachmentsObj.length > 0) {
                const instanceAttachments =
                  instanceAttachmentsObj[0].getDocumentsLists;
                if (instanceAttachments && instanceAttachments.length > 0) {
                  const previouslyAttachedDoc = instanceAttachments.filter(
                    (attachedDocument) =>
                      attachedDocument.documentTypeId ===
                      document.documentTypeID
                  );
                  if (
                    previouslyAttachedDoc &&
                    previouslyAttachedDoc.length > 0
                  ) {
                    attachedFileName = previouslyAttachedDoc[0].fileName;
                    document.isOnServer = true;
                    console.log('PRE_POPULATING :' + attachedFileName);
                  }
                }
              }
            }
            // END: pre-populate form

            nestedControl[document.documentName] =
              document.isMandatory === 'Yes'
                ? new FormControl(attachedFileName, Validators.required)
                : new FormControl(attachedFileName);
          }
          group[instance] = new FormGroup(nestedControl);
        }
        const constructedFormGroup = new FormGroup(group);
        console.log('Returning group', constructedFormGroup);
        this.documentsFormGroupListener.next(constructedFormGroup);
      }
    );
  }

  fetchDocumentList(): Observable<any> {
    // TODO: Replace this with a server call
    return this.getDocuments();
  }

  getDocuments(): Observable<any> {
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const getDocumentURL = endpointUrls.getDocumentsTemplate;
    const getDocumentEndpoint = backendURL + getDocumentURL;

    return this.http.get(getDocumentEndpoint);
  }

  getUserAttachments(): Observable<any> {
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const userAttachmentsURL = endpointUrls.getUserAttachments;
    const userAttachmentsEndpoint = backendURL + userAttachmentsURL;
    console.log('Fetch user attachment list...');
    return this.http.get<any[]>(userAttachmentsEndpoint);
  }

  deleteAttachments(data): Observable<any> {
    console.log('Creating user', data);
    const executionEnvData = this.authService.getBackendURLAndExecutionEnv();
    const backendURL = executionEnvData.backendURL;
    const deleteURL = endpointUrls.deleteAttachments;
    const deleteEndpoint = backendURL + deleteURL;
    return this.http.post(deleteEndpoint, data);
  }

  tabChange(): void {
    console.log('Back to form');
    this.tabChanges.next();
  }

  getTabChange(): Observable<any> {
    return this.tabChanges.asObservable();
  }

  validForm(status): void {
    this.validForms.next(status);
  }

  getFormValid(): Observable<any> {
    return this.validForms.asObservable();
  }
}
